import $ from 'jquery';
import _ from 'lodash';
import CityModel from '../model/cityModel'
import AreaModel from '../model/areaModel'
import { HttpClientImmidIot } from './request/HttpClientImmidIot';
import { customCookie } from './request/Util';
import { message } from 'antd';
import CryptoJS from 'crypto-js';
import { callback } from './model';


//全局公用方法定义
export const TimeUtils = (function () {
    //获取当前时间 时间戳
    function getCurrentTimestamp() {
        return new Date().getTime();
    }

    function convertDateStringToTimestamp(dateString: string | number | Date) {
        return Date.parse(new Date(dateString).toString());
    }

    function convertTimestampToStringWithDate(timestamp: any) {
        //获得时间
        const tempTime = getDateMap(timestamp);
        return tempTime.year + "-" + tempTime.month + "-" + tempTime.day;
    }

    /**
     * 获取最近N天日期
     * @param {int} N 整实数,负数获取今天之前 require=false
     * @param {int} M 整实数,负数获取今月之前 require=false
     * @param {Boolean} isDay 是否获取到天 require=false
     * */
    function getNearDay(N = 0, M = 0, isDay = true) {
        const today = new Date();
        const targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * N;
        today.setTime(targetday_milliseconds); //关键代码
        const tYear = today.getFullYear();
        let tMonth: string | number = today.getMonth();
        let tDate: string | number = today.getDate();
        tMonth = handleMonth(tMonth + 1 + M);
        tDate = isDay ? '-' + handleMonth(tDate) : '';
        return `${tYear}-${tMonth}${tDate}`;
    }

    //private
    function handleMonth(month: string | number) {
        let m = month;
        if (month.toString().length === 1) {
            m = "0" + month;
        }
        return m;
    }

    //yyyy-MM-dd HH:mm:ss
    function convertTimestampToStringExactMinAndSecAndSplitByLine(timestamp: any) {
        //获得时间
        const tempTime = getDateMap(timestamp);
        return tempTime.year + "-" + tempTime.month + "-" + tempTime.day + "  " + tempTime.hours + ":" + tempTime.min + ":" + tempTime.sec;
    }

    //private
    function getDateMap(timestamp: string | number | Date) {
        const date = new Date(timestamp);
        const tempTime = {
            year: date.getFullYear(),
            month: (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
            day: date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
            hours: date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
            min: date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(),
            sec: date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        };
        return tempTime;
    }

    return {
        getCurrentTimestamp: getCurrentTimestamp,
        convertDateStringToTimestamp: convertDateStringToTimestamp,
        convertTimestampToStringWithDate: convertTimestampToStringWithDate,
        convertTimestampToStringExactMinAndSecAndSplitByLine: convertTimestampToStringExactMinAndSecAndSplitByLine,
        getNearDay: getNearDay,
    }
}());

export const Global = (function () {
    function deepCopy(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    //获取详情id
    function getUrlId(hashPath: string) {
        let pathArr = hashPath.split("/");//取array
        let idIndex = pathArr.length - 1;
        let id = pathArr[idIndex];
        return id
    }

    function getSider() {
        let sider = $(".ant-layout-sider")[0];
        return sider.offsetWidth;
    }

    //权限点match

    return {
        deepCopy: deepCopy,
        getUrlId: getUrlId,
        getSider: getSider,
    }

}());

export const StringUtil = (function () {
    // 获取不同编码字符串长度
    function getStrFullLength(str = '') {
        return str.split('').reduce((pre, cur) => {
            const charCode = cur.charCodeAt(0);
            if (charCode >= 0 && charCode <= 128) {
                return pre + 1
            }
            return pre + 2
        }, 0);
    }

    // 截取字符串
    function cutStrByFullLength(str = '', maxLength: number) {
        let showLength = 0;
        return str.split('').reduce((pre, cur) => {
            const charCode = cur.charCodeAt(0);
            if (charCode >= 0 && charCode <= 128) {
                showLength += 1
            } else {
                showLength += 2
            }
            if (showLength <= maxLength) {
                return pre + cur
            }
            return pre
        }, '')
    }

    return {
        getStrFullLength: getStrFullLength,
        cutStrByFullLength: cutStrByFullLength,
    }
}());

export const getQueryString = (search: string, name: string) => {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    const r = search.match(reg);
    if (r != null) return _.unescape(r[2]);
    return null;
};

// 获取用户本地ip
export function getUserIP(onNewIP: (arg0: any) => void) {
    //onNewIp - your listener function for new IPs
    //compatibility for firefox and chrome
    const myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    const pc = new myPeerConnection({
        iceServers: []
    }),
        noop = function () {
        },
        localIPs:any = {},
        ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g

    function iterateIP(ip: string | number) {
        if (!localIPs[ip]) onNewIP(ip)
        localIPs[ip] = true
    }

    //create a bogus data channel
    pc.createDataChannel("")
    // create offer and set local description
    try {
        pc.createOffer(function (sdp: { sdp: string; }) {
            sdp.sdp.split('\n').forEach(function (line: string) {
                if (line.indexOf('candidate') < 0) return
                line.match(ipRegex).forEach(iterateIP)
            })

            pc.setLocalDescription(sdp, noop, noop)
        }, function (sdp: any) {
            console.log('fail')
        })
    } catch (err) {
        console.log(err)
    }
    //listen for candidate events
    pc.onicecandidate = function (ice: { candidate: { candidate: string; }; }) {
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return
        ice.candidate.candidate.match(ipRegex).forEach(iterateIP)
    };
}

export const MapModel = {
    top: CityModel,
    second: AreaModel
}
export const MapModelParm = {
    top: 'cityId' as 'cityId',
    second: 'areaId' as 'areaId'
}

export function login(username:string,password:string,varifyCode?:string, callback?: callback){
    // 自动登录获取验证信息
    // username = 'report', password='123456'
    const encrypt = CryptoJS.SHA1(password).toString();
    let ipAddress:any = ''
    getUserIP((ip: any) => {
        ipAddress = ip
    });
    let data = {
        grant_type: 'password',
        password: encrypt,
        username: _.trim(username),
        operatorId: 1,
        ip: ipAddress,
        varifyCode: varifyCode || ''
    };
    CityModel.login( JSON.stringify(data) , (d, type)=>{
        if (type === HttpClientImmidIot.requestSuccess) {
            //成功-------在这里做你的数据处理，需要提示的自己加
            if (d.success) {
                const data = d.data;
                customCookie.set('access_token', data.access_token);
                if (true) {
                    customCookie.set('access_token', data.access_token, 7);
                }
                localStorage.setItem('autoLogin', 'true');
                // message.success('获取身份验证信息成功');
            } else {
                console.error(d.msg);
            }
        } else {
            console.error('获取身份验证信息失败!')
        }
        callback&&callback(d, type)
    })
} 