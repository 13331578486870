export type callback = (arg0 ? : any, arg1 ? : number) => void;

//parkingInfos列表中每个实体数据如下
export const parking_infos = [{
    "parkingId": 5,
    "parkingRecordNo": "P0000000005",
    "parkingName": "宝龙城市广场地下停车场",
    "address": "阜阳市颍州区淮河路767号",
    "parkingLongitude": "115.814502",
    "parkingLatitude": "32.862",
    "parkingSpaceTotal": 200,
    "parkingSpaceUse": 200,
    "parkingSpaceResidual": 0,
    "classify": 2,
    "priceDescription": "",
    "spaceUsageRate": 10
},
{
    "parkingId": 5,
    "parkingRecordNo": "P0000000005",
    "parkingName": "ddd广场地下停车场",
    "address": "eee州区淮河路767号",
    "parkingLongitude": "115.83",
    "parkingLatitude": "32.869958",
    "parkingSpaceTotal": 300,
    "parkingSpaceUse": 300,
    "parkingSpaceResidual": 0,
    "classify": 2,
    "priceDescription": "",
    "spaceUsageRate": 88
},
{
    "parkingId": 5,
    "parkingRecordNo": "P0000000005",
    "parkingName": "ddfea地下停车场",
    "address": "efead路767号",
    "parkingLongitude": "115.84",
    "parkingLatitude": "32.861",
    "parkingSpaceTotal": 20,
    "parkingSpaceUse": 20,
    "parkingSpaceResidual": 0,
    "classify": 2,
    "priceDescription": "",
    "spaceUsageRate": 55
}]
export const classify_list={1:"商业", 2:"住宅", 3:"医院", 4:"园区", 5:"交通枢纽", 6:"行政机关", 7:"学校", 8:"其它",}
//地图+地图下 区域停车场列表统计信息
export const parking_summary = {
    "statisticsTime": "2021-01-05T17:29:18.229+0800",
    "parkingCount": 0,
    "parkingSpaceTotal": 0,
    "parkingSpaceUseTotal": 0,
    "parkingSpaceResidualTotal": 0,
    "spaceUsageRate": 0,
    "parkingInfos": parking_infos
}

//地图上   区域今日收入/停车次数
export const times_amount = {
    "statisticsTime": "2021-01-05T18:14:07.674+0800",
    "inTimes": 2,
    "outTimes": 2,
    "payAmount": 0
}

// 左1 *街道/乡镇互联互通车场统计
export const area_rate = {
    "statisticsTime": "2021-01-04T17:50:23.694+0800",
    "rate": 100,
    "areaId": 341203,
    "areaName": "清河街道"
}

//左2 车场类型统计
export const classify_rate = [{
        "statisticsTime": "2021-01-04T18:26:52.246+0800",
        "classify": 2,
        "rate": 10
    },
    {
        "statisticsTime": "2021-01-04T18:26:52.246+0800",
        "classify": 3,
        "rate": 90
    }
]

//左3 *车辆类型统计
export const plate_number_rate = [{
    "statisticsTime": "2020-01-04T20:06:34.000+0800",
    "type": 2,
    "times": 3,
    "rate": 100
}]

//左4 各街道充电桩车场占比统计
export const charging_rate = [{
    "statisticsTime": "2021-01-04T20:43:45.824+0800",
    "areaId": 341203,
    "areaName": "清河街道",
    "chargingRate": 100,
    "nochargingRate": 0
}]

//中底1 热点停车区域  中底2 周转能力分析
export const area_hot_points = [{
    "statisticsTime": "2021-01-05T09:00:00.000+0800",
    "turnover": 0,
    "spaceUsageRate": 100,
    "areaId": 341203,
    "areaName": "清河街道"
}]
export const area_hot_points_parkings = [{
    "statisticsTime": "2021-01-05T09:00:00.000+0800",
    "turnover": 100,
    "spaceUsageRate": 100,
    "parkingId": 341203,
    "parkingName": "阜阳市人民医院地下停车场"
},{
    "statisticsTime": "2021-01-05T09:00:00.000+0800",
    "turnover": 20,
    "spaceUsageRate": 40,
    "parkingId": 341203,
    "parkingName": "阜阳市中央人民广场地下停车"
},{
    "statisticsTime": "2021-01-05T09:00:00.000+0800",
    "turnover": 100,
    "spaceUsageRate": 100,
    "parkingId": 341203,
    "parkingName": "阜阳市中央人民广场地下停车场"
},]
export const area_turnover = [{
    "statisticsTime": "2021-01-05T09:00:00.000+0800",
    "turnover": 10,
    "spaceUsageRate": 100,
    "areaId": 341203,
    "areaName": "清河街道"
},{
    "statisticsTime": "2021-01-05T09:00:00.000+0800",
    "turnover": 20,
    "spaceUsageRate": 10,
    "areaId": 341203,
    "areaName": "BB街道"
},{
    "statisticsTime": "2021-01-05T09:00:00.000+0800",
    "turnover": 50,
    "spaceUsageRate": 20,
    "areaId": 341203,
    "areaName": "dd街道"
},]

//右4  支付方式统计
export const pay_type_rate = [
    {
        "statisticsTime": "2021-01-05T10:00:00.000+0800",
        "payType": 2,
        "rate":0
    },
    {
        "statisticsTime": "2021-01-05T10:00:00.000+0800",
        "payType": 3,
        "rate": 0
    }
]

//右3   营收统计
export const income_comparison = {
    "statisticsTime": "2021-01-05T14:56:24.518+0800",
    "thisMonth": [{
            "statisticsTime": "2021-01-01T00:00:00.000+0800",
            "payAmount": 1330.00
        },
        {
            "statisticsTime": "2021-01-02T00:00:00.000+0800",
            "payAmount": 4778.00
        },
        {
            "statisticsTime": "2021-01-03T00:00:00.000+0800",
            "payAmount": 900.00
        },
        {
            "statisticsTime": "2021-01-04T00:00:00.000+0800",
            "payAmount": 200.00
        }
    ],
    "lastMonth": [{
            "statisticsTime": "2020-12-01T00:00:00.000+0800",
            "payAmount": 907.00
        },
        {
            "statisticsTime": "2020-12-02T00:00:00.000+0800",
            "payAmount": 78.00
        },
        {
            "statisticsTime": "2020-12-03T00:00:00.000+0800",
            "payAmount": 4578.00
        },
        {
            "statisticsTime": "2020-12-04T00:00:00.000+0800",
            "payAmount": 6868.00
        }
    ]
}

//thisMonth和lastMonth数据中list集合每个实体 如下
export const month_data = [{
        "statisticsTime": "2021-01-01T00:00:00.000+0800",
        "payAmount": 1330.00
    },
    {
        "statisticsTime": "2021-01-02T00:00:00.000+0800",
        "payAmount": 4778.00
    },
    {
        "statisticsTime": "2021-01-03T00:00:00.000+0800",
        "payAmount": 900.00
    },
    {
        "statisticsTime": "2021-01-04T00:00:00.000+0800",
        "payAmount": 200.00
    }
]

//右2   车流统计
export const in_out_times = [{
        "statisticsTime": "2021-01-05T00:00:00.000+0800",
        "outTimes": 0,
        "inTimes": 0
    },
    {
        "statisticsTime": "2021-01-05T01:00:00.000+0800",
        "outTimes": 0,
        "inTimes": 0
    },
    {
        "statisticsTime": "2021-01-05T02:00:00.000+0800",
        "outTimes": 0,
        "inTimes": 0
    },
    {
        "statisticsTime": "2021-01-05T03:00:00.000+0800",
        "outTimes": 0,
        "inTimes": 0
    },
    {
        "statisticsTime": "2021-01-05T04:00:00.000+0800",
        "outTimes": 0,
        "inTimes": 0
    },
    {
        "statisticsTime": "2021-01-05T05:00:00.000+0800",
        "outTimes": 0,
        "inTimes": 0
    },
    {
        "statisticsTime": "2021-01-05T06:00:00.000+0800",
        "outTimes": 0,
        "inTimes": 0
    },
    {
        "statisticsTime": "2021-01-05T07:00:00.000+0800",
        "outTimes": 0,
        "inTimes": 0
    },
    {
        "statisticsTime": "2021-01-05T08:00:00.000+0800",
        "outTimes": 110,
        "inTimes": 40
    },
    {
        "statisticsTime": "2021-01-05T09:00:00.000+0800",
        "outTimes": 70,
        "inTimes": 50
    }
]

//右1   实时车辆通行信息
export const area_top_record = [{
        "parkId": 6,
        "parkName": "阜阳市人民医院地下停车场",
        "plateNumber": "粤B88888",
        "inOutTime": "2021-01-01T15:15:15.000+0800",
        "recordType": 2
    },
    {
        "parkId": 6,
        "parkName": "阜阳市人民医院地下停车场",
        "plateNumber": "粤B88888",
        "inOutTime": "2020-12-30T15:15:15.000+0800",
        "recordType": 1
    },
    {
        "parkId": 6,
        "parkName": "阜阳市人民医院地下停车场",
        "plateNumber": "粤B88888",
        "inOutTime": "2020-12-30T15:15:15.000+0800",
        "recordType": 1
    },
    {
        "parkId": 6,
        "parkName": "阜阳市人民医院地下停车场",
        "plateNumber": "粤B88888",
        "inOutTime": "2021-01-01T15:15:15.000+0800",
        "recordType": 2
    },
    {
        "parkId": 6,
        "parkName": "阜阳市人民医院地下停车场",
        "plateNumber": "粤B88888",
        "inOutTime": "2020-12-30T15:15:15.000+0800",
        "recordType": 1
    },
    {
        "parkId": 5,
        "parkName": "宝龙城市广场地下停车场",
        "plateNumber": "粤B88888",
        "inOutTime": "2020-12-30T15:15:15.000+0800",
        "recordType": 1
    },
    {
        "parkId": 5,
        "parkName": "宝龙城市广场地下停车场",
        "plateNumber": "粤B88888",
        "inOutTime": "2020-12-30T15:15:15.000+0800",
        "recordType": 1
    },
    {
        "parkId": 5,
        "parkName": "宝龙城市广场地下停车场",
        "plateNumber": "粤A12345",
        "inOutTime": "2020-12-30T19:15:15.000+0800",
        "recordType": 2
    },
    {
        "parkId": 5,
        "parkName": "宝龙城市广场地下停车场",
        "plateNumber": "粤A12345",
        "inOutTime": "2020-12-30T15:15:15.000+0800",
        "recordType": 1
    }
]


