import classNames from 'classnames'
import PubSub from 'pubsub-js'
import React, { PureComponent, useEffect, useState } from 'react'
import { TimesAmount,ParkingInfos,ParkingSummary } from './../../model/outRoadModelInterface'
import { times_amount,parking_summary } from '../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../model/outRoadModelRequest'
import { HttpClientImmidIot } from '../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../model/SystemFunction'
import centerBottomIcon1 from '../../static/images/outRoadParking/车场数.png'
import centerBottomIcon2 from './../../static/images/outRoadParking/泊位数.png'
import centerBottomIcon3 from '../../static/images/outRoadParking/空位数.png'
import centerBottomIcon4 from '../../static/images/outRoadParking/利用率.png'
import OutRoadMap from '../statistics/map/outRoadMap'
import './outRoadParking.css'
import '../statistics/statistics.css'
import PaymentMethod from '../statistics/charts/paymentMethod'
import { ChartCard } from '../statistics/statistics'
import HotSpotParkingArea from '../statistics/charts/hotSpotParkingArea'
import RevenueAnalysis from '../statistics/charts/revenueAnalysis'
import TrafficFlow from '../statistics/charts/trafficFlow'
import ParkingTime from '../statistics/charts/parkingTime'
import RevenueCapability from '../statistics/charts/revenueCapability'
import ChargingRateStatistics from './charts/ChargingRateStatistics'
import CommonTable from './table/CommonTable'
import CountUp from "react-countup";
import { getQueryString } from '../../model/request/Util'

export class OutRoadParking extends PureComponent<any,any> {

    constructor(props:any){
        super(props)
    }

    componentWillMount = () => {
        const str = new URLSearchParams('?' + window.location.href.split('?')[1]);
        const token = str.get('access_token')
        if(token&&window.customCookie){
            window.customCookie.set('access_token',token)
        }
    }

    componentDidMount = () => {
       
    }

    //各街道互联互通车场统计
    left1 = () => {
        const element = <div className='payment_method_data'>
           <PaymentMethod type='areaRate'/>
        </div>
        return <ChartCard chartName='各街道互联互通车场统计' style='out_road_parking_left_chart_card' children={element}/>
    }

    left2 = () => {
        const element = <div className='payment_method_data'>
            <ParkingTime type='classfyRate' seriesName='车场类型统计'/>
        </div>
        return <ChartCard chartName='车场类型统计' style='out_road_parking_left_chart_card' children={element}/>
    }
    left3 = () => {
        const element = <div className='payment_method_data'>
           <PaymentMethod type='plateNumberRate'/>
        </div>
        return <ChartCard chartName='新能源车停放占比' style='out_road_parking_left_chart_card' children={element}/>
    }
    left4 = () => {
        const element = <div className='payment_method_data'>
            <ChargingRateStatistics />
        </div>
        return <ChartCard chartName='各街道充电桩车场占比统计' style='out_road_parking_left_chart_card' children={element}/>
    }

    center1 = () => {
        const element = <div className='payment_method_data'>
             <HotSpotParkingArea type='areaHotPointsParkings'/>
        </div>
        return <ChartCard chartName='热点停车区域' style='out_road_parking_left_center_chart_card' children={element}/>
    }
    center2 = () => {
        const element = <div className='payment_method_data'>
                <RevenueCapability type='areaTurnover'/>
          </div>
        return <ChartCard chartName='周转能力分析' style='out_road_parking_left_center_chart_card' children={element}/>
    }

    right1 = () => {
        const element = <div className='payment_method_data'>
        <CommonTable />
        </div>
        return <ChartCard chartName='实时车辆通行信息' style='out_road_parking_right_chart_card1' children={element}/>
    }
    right2 = () => {
        const element = <div className='payment_method_data'>
         <TrafficFlow type="inOutTimes"/>
        </div>
        return <ChartCard chartName='车流统计' style='out_road_parking_right_chart_card_others' children={element}/>
    }
    right3 = () => {
        const element = <div className='payment_method_data'>
          <RevenueAnalysis type="incomeComparison"/>
        </div>
        return <ChartCard chartName='营收分析' style='out_road_parking_right_chart_card_others' children={element}/>
    }

    /* 支付方式分布  */
    right4 = () => {
        const element = <div className='payment_method_data'>
            <PaymentMethod type='payTypeRate'/>
        </div>
        return <ChartCard chartName='支付方式统计' style='out_road_parking_right_chart_card_others' children={element}/>
    }
    render() {
        return (
            <div className='display_main'>
                {/* 左侧四张图表 */}
                <div className='display_charts_left'>
                  {this.left1()}
                  {this.left2()}
                  {this.left3()}
                  {this.left4()}
                </div>
                {/* 中间地图及底部图表 */ }
                <div className='display_charts_middle'>
                    <MapTopLine />
                    <div className='map_bg_img'>
                        <OutRoadMap />
                    </div>
                    <MapBottomLine />
                    <div className='out_road_display_charts_bottom'>
                      {this.center1()}
                      {this.center2()}
                    </div>
                </div>
                {/* 右侧四张图表 */}
                <div className='display_charts_right'>
                    {this.right1()}
                    {this.right2()}
                    {this.right3()}
                    {this.right4()}
                </div>
            </div>
        )
    }
}

export default OutRoadParking

function MapTopLine(){
    const [datasource,setDatasource] = useState<TimesAmount|null>(parking_summary)
    const [duration,setDuration] = useState(1)
    let timer:NodeJS.Timeout | number
    useEffect(() => {
        requestDatasource()
        timer = setInterval(()=>{
            requestDatasource()
        }, 5*1000 )
        return () => {
            clearInterval(Number(timer))
        }
    },[])
    let countUps=[];
    // 获取地图上方 区域今日收入/停车次数
    const requestDatasource = () => {
        OutRoadModelRequest.timesAmount({},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setDatasource(d.data)
                   //有数字变化时会自然滚动, 如果想看到每次调用接口就滚动数字, 打开以下代码
                    // setDuration(duration+Math.random())
                    countUps.map((item,index) =>{
                        if(item){
                            item.update()
                        }
                    })
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取地图上方区域今日收入/停车次数失败')
            }
        })
    }

    const {inTimes,outTimes,payAmount} = datasource || {}

    return (<div className='out_road_parking_center_top_line'>
            {[
                {field:'今日入场车辆',value:inTimes,unit:'辆'},
                {field:'停车次数',value:outTimes,unit:'次'},
                {field:'收费金额',value:payAmount,unit:'元'},
            ].map((item,index) => <div key={index} className="out_road_parking_center_top_line_line_item">
                    <div className='out_road_parking_center_top_line_line_item_title'>{item.field}</div>
                    <div className='out_road_parking_center_top_line_line_item_value'>
                        <CountUp onEnd={(countUp)=>{
                                    countUps[index] = countUp
                                }}
                                duration={duration}
                                redraw={true}
                                start={0}
                                end={item.value||0}
                                formattingFn={
                                    (value: number) => value&&value.toLocaleString()
                                }
                            />{item.unit}</div>
            </div>)}
        </div>)

}

function MapBottomLine(){
    const [datasource,setDatasource] = useState<ParkingSummary|null>(parking_summary)
    const [duration,setDuration] = useState(1)
    let timer:NodeJS.Timeout | number
    useEffect(() => {
        requestDatasource()
        timer = setInterval(()=>{
            requestDatasource()
        }, 5*1000 )
        return () => {
            clearInterval(Number(timer))
        }
    },[])

     let countUps=[];
    // 地图+地图下 区域停车场列表统计信息
    const requestDatasource = () => {
        OutRoadModelRequest.parkingSummary({},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setDatasource(d.data)
                     //有数字变化时会自然滚动, 如果想看到每次调用接口就滚动数字, 打开以下代码
                    // setDuration(duration+Math.random())
                    countUps.map((item,index) =>{
                        if(item){
                            item.update()
                        }
                    })
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取地图上方区域今日收入/停车次数失败')
            }
        })
    }

    const {parkingCount,parkingSpaceTotal,parkingSpaceResidualTotal,spaceUsageRate } = datasource || {}
     return (<div className='display_key_indicators out_road_parking_center_bottom_line'>
             {[
                 {field:'车场数',value:parkingCount,icon:centerBottomIcon1},
                 {field:'泊位数',value:parkingSpaceTotal,icon:centerBottomIcon2},
                 {field:'空位数',value:parkingSpaceResidualTotal,icon:centerBottomIcon3},
                 {field:'利用率',value:spaceUsageRate,icon:centerBottomIcon4,unit:"%"}
             ].map((item,index) => <div key={index}>
                 <img src={item.icon} alt=""/>
                 <div>
                     <div className='keyIndicators_amount' style={{color:['#DFB025','#D177FF','#56CFA7','#957DFF'][index]}}>
                         <CountUp onEnd={(countUp)=>{
                                    countUps[index] = countUp
                                }}
                                duration={duration}
                                redraw={true}
                                start={0}
                                end={item.value||0}
                                formattingFn={
                                    (value: number) => value&&value.toLocaleString()
                                }
                            />{item.unit}
                            </div>
                     <div className='keyIndicators_field'>{item.field}</div>
                 </div>
             </div>)}
         </div>)
}
