import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import CityModel from '../../../model/cityModel'
import { EquipmentType, MonthlyParkingTimes, PatrolEventTypes, PaymentMethodsData } from '../../../model/model'
import ParkingModel from '../../../model/parkingModel'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../../model/SystemFunction'
import { MapParking } from '../statistics'
import {  AreaRate,PlateNumberRate,PayTypeRate } from '../../../model/outRoadModelInterface'
import { area_rate,plate_number_rate ,pay_type_rate} from '../../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../../model/outRoadModelRequest'
import outRoadParkingDetailModelRequest from "../../../model/outRoadParkingDetailModelRequest";

interface Props{
    type?: 'paymentMethodsData'|'patrolEventType'|'monthlyParkingTimes'|'equipmentType'|'areaRate'|'plateNumberRate'|'payTypeRate'|'parkingDetailPaymentType',
}
interface State{
    showLegend: boolean,
    paymentMethodsData?: Array<PaymentMethodsData>,
    patrolEventTypes?: Array<PatrolEventTypes>,
    monthlyParkingTimes?: Array<MonthlyParkingTimes>,
    equipmentType?: Array<EquipmentType>,
    areaRate?: Array<AreaRate>,
    plateNumberRate?: Array<PlateNumberRate>,
    payTypeRate?: Array<PayTypeRate>,
    detailPayTypeRate?: Array<PayTypeRate>,
    colorList?: Array<string>,
}
export class PaymentMethod extends PureComponent<Props,State> {
    timer: NodeJS.Timeout | number

    constructor(props:Props){
        super(props)
        this.state = {
            showLegend: true,
            paymentMethodsData: [
                {cityId: '',cityName: '',createTime: 0,payType: 0,id: 0,statisticsTime: 0,payTimes: 0,updateTime: 0 }
            ],
            patrolEventTypes: [
                {parkWarnType: 3, rate:0},
            ],
            monthlyParkingTimes: [
                {type: 1}
            ],
            equipmentType: [],
            areaRate:[],
            plateNumberRate:[],
            payTypeRate:[],
            detailPayTypeRate:[],
            colorList: ['#D8C900','#02BDC3','#32C5FF','#E45374','#966BFF'],
        }
    }

    componentDidMount(){
        switch (this.props.type) {
            case 'paymentMethodsData':
                this.getPaymentMethods({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                this.timer = setInterval(()=>{
                    this.getPaymentMethods({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getPaymentMethods(info)
                    this.timer = setInterval(()=>{
                        this.getPaymentMethods(info)
                    }, 60*1000 )
                })
                break;
            case 'patrolEventType':
                this.setState({showLegend: false})
                this.getPatrolEventTypes({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                this.timer = setInterval(()=>{
                    this.getPatrolEventTypes({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getPatrolEventTypes(info)
                    this.timer = setInterval(()=>{
                        this.getPatrolEventTypes(info)
                    }, 60*1000 )
                })
                break;
            case 'monthlyParkingTimes':
                this.setState({showLegend: false})
                this.getMonthlyParkingTimes()
                this.timer = setInterval(()=>{
                    this.getMonthlyParkingTimes()
                }, 60*1000 )
                break;
            case 'equipmentType':
                this.setState({showLegend: false})
                this.getEquipmentType()
                this.timer = setInterval(()=>{
                    this.getEquipmentType()
                }, 60*1000)
                break;
            //各街道互联互通车场统计
            case 'areaRate':
                this.setState({showLegend: false})
                this.getAreaRate()
                this.timer = setInterval(()=>{
                    this.getAreaRate()
                }, 60*1000)
                break;
            //左3 *车辆类型统计
            case 'plateNumberRate':
                this.setState({showLegend: false})
                this.getPlateNumberRate()
                this.timer = setInterval(()=>{
                    this.getPlateNumberRate()
                }, 60*1000)
                break;
            //右4  支付方式统计
            case 'payTypeRate':
                this.setState({showLegend: false})
                this.getPayTypeRate()
                this.timer = setInterval(()=>{
                    this.getPayTypeRate()
                }, 60*1000)
                break;
            case 'parkingDetailPaymentType':
                this.setState({showLegend: false})
                this.parkingDetailPaymentType()
                this.timer = setInterval(()=>{
                    this.parkingDetailPaymentType()
                }, 60*1000)
                break;
            default:
                break;
        }

    }

    componentWillUnmount(){
        clearInterval(Number(this.timer))
        PubSub.unsubscribe('mapParking');
    }

    //获取同车牌月停车次数数据
    getMonthlyParkingTimes = () => {
        const parkingId = localStorage.getItem('parkingId')
        ParkingModel.monthlyParkingTimes({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({monthlyParkingTimes: d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取同车牌月停车次数数据失败')
            }
        })
    }

    // 获取今日支付方式分布数据
    getPaymentMethods = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.paymentMethods({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({paymentMethodsData:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日支付方式分布数据失败')
            }
        })
    }

    // 巡检事件类型占比
    getPatrolEventTypes = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.patrolEventTypes({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({patrolEventTypes:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日支付方式分布数据失败')
            }
        })
    }

    // 设备类型占比统计
    getEquipmentType = () => {
        CityModel.equipmentType({timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    this.setState({equipmentType:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取设备类型占比统计失败')
            }
        })
    }
    // 各街道互联互通车场统计
    getAreaRate = () => {
        OutRoadModelRequest.areaRate(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
               if (d.success&&d.data.length>0) {
                    this.setState({areaRate:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取各街道互联互通车场统计统计失败')
            }
        })
    }
   //左3 *车辆类型统计
    getPlateNumberRate = () => {
        OutRoadModelRequest.plateNumberRate(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
               if (d.success&&d.data.length>0) {
                    this.setState({plateNumberRate:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取车辆类型统计失败')
            }
        })
    }
    //右4  支付方式统计
    getPayTypeRate = () => {
        OutRoadModelRequest.payTypeRate(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
               if (d.success&&d.data.length>0) {
                    this.setState({payTypeRate:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取支付方式统计失败')
            }
        })
    }


    //右4  --路外停车-单个停车场---支付类型
    parkingDetailPaymentType = () =>{
        const parkingId = localStorage.getItem('DETAIL_PARKING_ID');
        outRoadParkingDetailModelRequest.detailParkingPayType({parkingId:parseInt(parkingId)},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({detailPayTypeRate:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取支付方式统计失败')
            }
        })
    };



    getEChartsData = () => {
        let echartsData: any[] = []
        const colorList =this.state.colorList;
        const payTypeList= {1:"微信支付", 2:"支付宝",3:"ETC支付"}
        switch (this.props.type) {
            case 'paymentMethodsData':
                const { paymentMethodsData } = this.state
                // const payType= ["钱包","微信公众号","人工支付宝","人工微信","ETC支付"]
                const payType= ["钱包","微信支付","支付宝支付","ETC支付"]
                // echartsData = paymentMethodsData.map((item,index)=>{
                //     return {
                //         value: item.payTimes,
                //         name: payType[item.payType],
                //         itemStyle: {
                //             color: colorList[item.payType],
                //         }
                //     }
                // })
                echartsData = payType.map((item,index)=>{
                    const data =  paymentMethodsData.find(data => data.payType==index)
                    let value = data&&data.payTimes || 0
                    if(index==1){
                        const data_1 = paymentMethodsData.find(data => data.payType==1)
                        const data_3 = paymentMethodsData.find(data => data.payType==3)
                        value = (data_1&&data_1.payTimes || 0) + (data_3&&data_3.payTimes || 0)
                    }
                    if(index==3){
                        const data_4 = paymentMethodsData.find(data => data.payType==4)
                        value = data_4&&data_4.payTimes || 0
                    }
                    return {
                        value: value,
                        name: item,
                        itemStyle: {
                            color: colorList[index],
                        }
                    }
                })
                break;
            case 'patrolEventType':
                const { patrolEventTypes } = this.state
                const parkWarnType= {3:'违停处理',4:'未交费拍照',6:'设备维保',15:'辅助入场',16:'辅助离场'}
                echartsData = patrolEventTypes.map((item,index)=>{
                    return {
                        value: item.rate,
                        name: parkWarnType[item.parkWarnType],
                        itemStyle: {
                            color: colorList[index],
                        }
                    }
                })
                break;
            case 'monthlyParkingTimes':
                const {monthlyParkingTimes} = this.state
                if(monthlyParkingTimes){
                    echartsData = monthlyParkingTimes.map((item,index)=>{
                        return {
                            value:  item.type,
                            name: item.type + '次' ,
                            itemStyle: {
                                color: colorList[index],
                            }
                        }
                    });
                }
                break;
            case 'equipmentType':
                const {equipmentType} = this.state
                if(equipmentType){
                    echartsData = equipmentType.map((item,index)=>{
                        return {
                            value: item.quantity,
                            name: item.sub_type_name,
                            itemStyle: {
                                color: colorList[index],
                            }
                        }
                    });
                }
                break;
             case 'areaRate':
                const {areaRate} = this.state
                if(areaRate){
                    echartsData = areaRate.map((item,index)=>{
                        return {
                            value: item.rate,
                            name: item.areaName,
                            itemStyle: {
                                color: colorList[index],
                            }
                        }
                    });
                }
                break;
             case 'plateNumberRate':
                const {plateNumberRate} = this.state
                const plateNumberType= {1:"新能源", 2:"非新能源"}
                if(plateNumberRate){
                    echartsData = plateNumberRate.map((item,index)=>{
                        return {
                            rate: item.rate,
                            value: item.times,
                            name: plateNumberType[item.type],
                            itemStyle: {
                                color: colorList[index],
                            }
                        }
                    });
                }
                break;
            case 'payTypeRate':
                const { payTypeRate } = this.state
                echartsData = payTypeRate.map((item, index) => {
                    return {
                        value: item.rate,
                        name: payTypeList[item.payType],
                        itemStyle: {
                            color: colorList[index],
                        }
                    }
                });
                break;
            case 'parkingDetailPaymentType':
                const {detailPayTypeRate} = this.state;
                echartsData = detailPayTypeRate.map((item,index)=>{
                    return {
                        value:  item.rate,
                        name: payTypeList[item.payType],
                        itemStyle: {
                            color: colorList[index],
                        }
                    }
                });
                break;
            default:
                break;
        }
        return echartsData
    };

    getOptions = () => {
        let echartsData = this.getEChartsData()
        return {
            tooltip: {
                trigger: 'item' as 'item',
                borderColor: 'rgba(255,255,255,.3)',
                backgroundColor: 'rgba(13,5,30,.6)',
                borderWidth: 1,
                padding: 5,
                formatter: (parms:any) => {
                     switch (this.props.type) {
                         case 'plateNumberRate':
                             return `${parms.marker} ${parms.data.name}</br>数量：${parms.data.value}</br> 占比：${parms.data.rate}%`;
                             break;
                         case 'payTypeRate':
                             return `${parms.marker} ${parms.data.name}</br> 占比：${parms.data.value}%`;
                             break;
                         case 'areaRate':
                             return `${parms.marker} ${parms.data.name}</br> 占比：${parms.data.value}%`;
                             break;
                         default:
                             return `${parms.marker} ${parms.data.name}</br>数量：${parms.data.value}</br> 占比：${parms.percent}%`;
                             break;
                     }
                }
            },
            legend: {
                show: this.state.showLegend,
                icon: "circle",
                itemHeight: 7,
                itemWidth: 5,
                bottom: 0,
                left: 'center',
                textStyle: {
                    color: '#fff'
                },
                data: echartsData.map(item => item.name)
            },
            series: [{
                type: 'pie',
                center: this.state.showLegend?['50%', '40%']:['50%','50%'],
                radius: this.state.showLegend?['35%', '60%']:['40%', '80%'],
                clockwise: true,
                avoidLabelOverlap: true,
                hoverOffset: 15,
                itemStyle: {
                    normal: {
                        color: function(params:any) {
                            return echartsData.map(item=>item.itemStyle.color)[params.dataIndex]
                        }
                    }
                },
                label: {
                    show: true,
                    position: 'outside',
                    formatter: '{icon|●}{a|{b}}',
                    padding: [25, -40, 30, -10],
                    rich: {
                        icon: {
                            padding: [-3, 3, 0, -12]
                        },
                        a: {
                            padding: [-20, 5, -20, 0],
                            fontSize: 12,
                            color: '#FFFFFF'
                        },
                    }
                },
                labelLine: {
                    normal: {
                        length: 5,
                        length2: 30,
                        lineStyle: {
                            width: 1
                        }
                    }
                },
                data: echartsData
            }]
        }
    }
    getReturnContent = () => {
        const colorList =this.state.colorList;
        switch (this.props.type) {
            case "plateNumberRate":
                const plateNumberRate = this.state.plateNumberRate || []
                const newPlateCarIndex=plateNumberRate.findIndex((e) => e.type==1)
                console.log('newPlateCarIndex',newPlateCarIndex)
                const normalPlateCarIndex=plateNumberRate.findIndex((e) => e.type==2)
                return (<div className='plate_number_rate_special_legend'>
                         <div className="special_legend_chart"><ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/></div>
                        <div className="special_legend_detail">
                        {[{name:"在场车辆数",value:plateNumberRate.length>0?eval(plateNumberRate.map(data => data.times).join("+"))+'辆':'--' ,color:'transparent',},
                                                    {name:"新能源车辆数",value:newPlateCarIndex>-1?plateNumberRate[newPlateCarIndex].times+'辆':'--',color:newPlateCarIndex>-1?colorList[newPlateCarIndex]:'transparent'},
                                                    {name:"非新能源车辆数",value:normalPlateCarIndex>-1?plateNumberRate[normalPlateCarIndex].times+'辆':'--',color:normalPlateCarIndex>-1?colorList[normalPlateCarIndex]:'transparent'}].map((item,index) =>{
                            return <div key={index} className="special_legend_box">
                                <div className="special_legend_box_title"> <div className={`special_legend_box_dot`} style={{backgroundColor:item.color}}/>{item.name}</div>
                                <div className="special_legend_box_value">{item.value}</div>
                            </div>
                        })}

                        </div>
                        </div>)
                break;
            default:
                return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
                break;
        }
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default PaymentMethod
