import PubSub from 'pubsub-js';
import "@amap/amap-jsapi-types";
import React, { PureComponent } from 'react';
import { Map, Markers, Polygon } from 'react-amap';
import Heatmap from 'react-amap-plugin-heatmap';
import { withRouter } from 'react-router';
import CityModel from '../../../model/cityModel';
import { AreaParkingInf, CityParkingInf } from '../../../model/model';
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot';
import parkingIcon1 from '../../../static/images/statistics/停车场红色@2x.png';
// import parkIcon from '../../../static/images/statistics/圆环@2x.png'
import localIcon from '../../../static/images/statistics/定位icon@2x.png';
import { location, mapProps, showCityMap } from './amap';
import './parkingMap.css';
import { MapParking } from '../statistics';

export class ParkingMap extends PureComponent<any, State>{
    infoWindow: any;
    geocoder: any;

    constructor(props: any) {
        super(props)
        this.state = {
            cityParkingInf: [],
            areaParkingInf: [],
            mapDivision: 'top',
            marker: null,
            scale: 1
        }
    }

    componentDidMount = () => {
        this.getMapParkingInf()
        PubSub.subscribe('mapParking',(msg: string, info:any)=>{
            this.getMapParkingInf(info.adcode)
        })
        // this.getAreaParkingInf()
    }

    //获取地图-市区级停车场信息
    getMapParkingInf = (code?:string) => {
        const cityId = code || localStorage.getItem('cityCode') || '440300'
        CityModel.cityParkingInf({ cityId }, (d, type) => {
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    const cityParkingInf = d.data.filter(e => e.parkingLongitude&&e.parkingLatitude)
                    this.setState({ cityParkingInf })
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取区域营收分析数据失败')
            }
        })
    }

    //获取地图-区级停车场信息
    getAreaParkingInf = (areaId?:string) => {
        CityModel.areaParkingInf({areaId}, (d, type) => {
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({ areaParkingInf: d.data })
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取区域营收分析数据失败')
            }
        })
    }

    getMarkers = () => {
        const {cityParkingInf,areaParkingInf,mapDivision} = this.state
        switch (mapDivision) {
            case 'top':
                return cityParkingInf.map(item => {
                    const {parkingLongitude,parkingLatitude} = item
                    const lnglat = {
                        longitude: parkingLongitude,
                        latitude: parkingLatitude,
                    }
                    return {
                        position:lnglat,
                        ...item,
                        level: 'top'
                    }
                })
            case 'second':
                return areaParkingInf.map(item => {
                    return {
                        position: {
                            longitude: item.parkingLongitude,
                            latitude: item.parkingLatitude,
                        },
                        ...item,
                        level: 'second'
                    }
                })
            default:
                return []
        }
        
    }

    renderMarkerLayout = (extData:CityParkingInf|AreaParkingInf) => {
        const {mapDivision,scale} = this.state
        switch (mapDivision) {
            case 'top':
                if(this.props.type&&this.props.type=='berthMonitoring'){
                    return null
                }
                return <div className='parkingMap_float_box' style={{transform:`scale(${scale},${scale})`}}>
                    <div className='float_box_num'>{(extData as CityParkingInf).parkingTotalCount}</div>
                    <div>{extData.areaName}</div>
                </div>
            case 'second':
                return <img className='parkingMap_parkingIcon' src={parkingIcon1} alt="" />
            default:
                return null
        }
        
    }

    markerEvents = () => {
        return {
            created: (markers) => {
                
            },
            click: (e, marker) => {
                const map = marker.getMap(), position = marker.getPosition( ), extData = marker.getExtData()
                // 发布区级相关信息
                PubSub.publish('mapParking',{level:'second',adcode: extData.areaId,areaName:extData.areaName})
                localStorage.setItem('level','second')
                localStorage.setItem('areaName',extData.areaName)
                switch (extData.level) {
                    case 'top':
                        // 获取区级停车场坐标
                        this.getAreaParkingInf(extData.areaId)
                        // 地图放大至区级
                        map.setZoomAndCenter(map.getZoom()+4.5,position)
                        this.setState({mapDivision:'second'})
                        break;
                    case 'second':
                        // 保存停车场id
                        localStorage.setItem('parkingId',extData.parkingId)
                        // 跳转至停车场页面
                        this.props.history.push({ pathname: '/parkingStatistics', query: {parkingId: extData.parkingId} })
                        break;
                    default:
                        break;
                }
            },
            mouseover: (e, marker) => {
                const extData = marker.getExtData(), map = marker.getMap(), position = marker.getPosition()
                this.openInfoWindow(extData,map,position)
            },
            mouseout: (e, marker) => {
                this.infoWindow.close()
            }
        }
    }

    openInfoWindow = (data,map,position) => {
        let extData = null
        const {mapDivision,cityParkingInf,areaParkingInf} = this.state
        if(mapDivision == 'top'){
            extData = cityParkingInf.find(e => e.areaId==data.areaId)
        }
        if(mapDivision == 'second'){
            extData = areaParkingInf.find(e => e.parkingName==data.parkingName)
        }
        if(extData){
            this.infoWindow.setContent(this.getInfoWindowContent(extData))
            this.infoWindow.open(map,position)
        }
    }

    mapOptions = () => ({
        mapStyle: 'amap://styles/fb153177faab9a59cbf2f6b7f78ca63e',
        useAMapUI: true,
        // zoom:10,
        events: {
            created: (mapInstance) => {
                const zoomInit = mapInstance.getZoom()
                mapInstance.on('zoomend',()=>{
                    const scale = mapInstance.getZoom()/zoomInit
                    this.setState({scale})
                    if(this.infoWindow){
                        this.infoWindow.close()
                    }
                })
                // 自动定位
                // window.AMap.plugin('AMap.CitySearch', () => {
                //     const citySearch = new window.AMap.CitySearch()
                //     citySearch.getLocalCity((status, result) => {
                //         if (status === 'complete' && result.info === 'OK') {
                //             // 查询成功，result即为当前所在城市信息
                //             localStorage.setItem('cityName',result.city)
                //             localStorage.setItem('cityCode',result.adcode)
                //             if(this.state.mapDivision == 'top'){
                //                 PubSub.publish('mapParking',{level:'top',adcode: result.adcode, areaName:result.city})
                //             }
                //         }
                //     })
                // })
                
                // 指定颍州区
                location(mapInstance)
                showCityMap(mapInstance)

                this.infoWindow = new window.AMap.InfoWindow({
                    anchor: 'bottom-center',
                    isCustom: true,
                    content: '',
                });
                window.AMap.plugin(['AMap.Geocoder'],()=>{
                    this.geocoder = new window.AMap.Geocoder({});
                })
            }
        }
    })

    getInfoWindowContent = (extData) => {
        const {mapDivision} = this.state
        let infoWindowContent = ''
        switch (mapDivision) {
            case 'top':
                infoWindowContent = `<div class='infoWindow_area'>
                    <p class='infoWindow_title'>
                        <img src=${localIcon} alt=""/>${extData&&extData.areaName}
                    </p>
                    <div><div>停车场</div><div>${extData&&extData.parkingTotalCount + '个'}</div></div>
                    <div><div>泊位数</div><div>${extData&&extData.parkingSpaceTotal + '个'}</div></div>
                    <div><div>平均占用率</div><div>${extData&&extData.spaceUsageRate + '%'}</div></div>
                    <div><div>最高占用率</div><div>${extData&&extData.topSpaceUsageRate + '%'}</div></div>
                </div>`
                break
            case 'second':
                const {parkingName,totalSpaceCount,freeSpaceCount,spaceUsageRate} = extData || {}
                infoWindowContent = `<div class='infoWindow_area'>
                    <p class='infoWindow_title'>
                        <img src=${localIcon} alt=""/>${parkingName}
                    </p>
                    <div><div>泊位数</div><div>${totalSpaceCount + '个'}</div></div>
                    <div><div>空位数</div><div>${freeSpaceCount + '个'}</div></div>
                    <div><div>停车饱和度</div><div>${spaceUsageRate}</div></div>
                </div>`
                break
            default:
                break
        }
        return infoWindowContent
    }

    polygonOptions = () => ({
        events: {
            created: (ins) => {
                const map = ins.w.map
               
                
            }
        },
        path: []
    })

    render() {
        const {mapDivision,marker,cityParkingInf} = this.state

        const pluginProps = {
            visible: true,
            radius: 30,
            gradient: {
                '0.4':'rgb(0, 255, 255)',
                '0.65':'rgb(0, 110, 255)',
                '0.85':'rgb(100, 0, 255)',
                '1.0':'rgb(100, 0, 255)'
            },
            zooms: [3, 18],
            dataSet: {
                data: cityParkingInf.map(item => {
                    return {"lng": item.parkingLongitude,"lat": item.parkingLatitude,"count": item.parkingTotalCount || 10}
                }),
                max: Math.max(...cityParkingInf.map(e => e.parkingTotalCount))
            }
        }
        return <Map {...mapProps}  {...this.mapOptions()}>
            {/* {this.props.type&&this.props.type=='berthMonitoring' &&  */}
                {/* <Heatmap {...pluginProps} /> */}
            {/* } */}
            <Markers 
                events={this.markerEvents()} 
                markers={this.getMarkers()} 
                render={this.renderMarkerLayout} />
            <Polygon {...this.polygonOptions()}/>
            <UIMarker {...this.props} 
                openInfoWindow={this.openInfoWindow} 
                getAreaParkingInf={(areaId)=>{this.getAreaParkingInf(areaId)}}
                mapDivision={mapDivision} 
                setmapDivision={(show: 'top' | 'second') => { this.setState({ mapDivision: show }); } } />
        </Map>
    }
}

interface State {
    cityParkingInf?: Array<CityParkingInf>,
    areaParkingInf?: Array<AreaParkingInf>,
    mapDivision?: 'top' | 'second',
    zoom?: {
        area: number,
        parking: number
    },
    marker?: any,
    scale?: number,
}

class UIMarker extends PureComponent<any, State> {
    polygons: any;
    DistrictExplorer: any;
    districtExplorer: any;
    constructor(props: any) {
        super(props)
        this.polygons = []
        this.state = {
            mapDivision: this.props.mapDivision,
            cityParkingInf: [],
            areaParkingInf: [],
            zoom: {
                area: this.props.__map__.getZoom(),
                parking: this.props.__map__.getZoom()+3
            }
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.mapDivision != this.state.mapDivision){
            this.setState({mapDivision: nextProps.mapDivision})
        }
    }

    componentDidMount = () => {
        this.setState({zoom:{area: this.props.__map__.getZoom(),parking: this.props.__map__.getZoom()+3}},()=>{
            this.props.__map__.on('zoomend', ()=>{
                const current = this.props.__map__.getZoom()
                const {area,parking} = this.state.zoom
                const aver = (area+parking)/2
                if(current<aver){
                    if(this.state.mapDivision != 'top'){
                        this.mouseouver()
                        this.props.setmapDivision('top')
                        PubSub.publish('mapParking',{level:'top',adcode: localStorage.getItem('cityCode') || '440300'})
                    }
                }else{
                    if(this.state.mapDivision != 'second'){
                        this.props.__map__.remove(this.polygons)
                        this.props.setmapDivision('second')
                        this.props.getAreaParkingInf()
                    }
                }
            });
        })
        try {
            window.AMapUI.loadUI(['geo/DistrictExplorer', 'geo/DistrictCluster'], (DistrictExplorer, DistrictCluster) => {
                //启动页面
                this.DistrictExplorer = DistrictExplorer
                this.initPage(DistrictExplorer);
            });
        } catch (error) {
            console.error(error)
        }
        PubSub.subscribe('mapParking',(msg: string, info: MapParking)=>{
            if(info.level=='second'){
                this.props.__map__.remove(this.polygons)
            }
        })
    }

    initPage = (DistrictExplorer) => {
        const map = this.props.__map__
        try {
            this.districtExplorer = new DistrictExplorer({
                map, //所属的地图实例
                eventSupport: true, 
            });
            // const districtSearch = new AMap.DistrictSearch({
            //     subdistrict: 0,   //获取边界不需要返回下级行政区
            //     extensions: 'all',  //返回行政区边界坐标组等具体信息
            //     level: 'district'  //查询行政级别为 市
            // });
            this.renderBounds(localStorage.getItem('cityCode') || '440300')
            this.mouseouver()
        } catch (error) {
            console.error(error)
        }
    }

    mouseouver = () => {
        const map = this.props.__map__
        //鼠标滑过feature
        this.districtExplorer.on('featureMouseover', (e, feature) => {
            map.remove(this.polygons)//清除上次结果
            this.polygons = [];
            const bounds = feature.geometry.coordinates;
            if (bounds) {
                for (let i = 0, l = bounds.length; i < l; i++) {
                    //生成行政区划polygon
                    const polygon = new AMap.Polygon({
                        strokeWeight: 1,
                        path: bounds[i],
                        fillOpacity: 0.4,
                        fillColor: '#80d8ff',
                        strokeColor: '#0091ea'
                    });
                    polygon.on('dblclick', (e) => {
                        map.setZoomAndCenter(map.getZoom()+3,feature.properties.center)
                    });
                    this.polygons.push(polygon);
                }
            }
            map.add(this.polygons)
            // map.setFitView(this.polygons);//视口自适应
        })
    }

    renderBounds = (id) => {
        this.districtExplorer.loadAreaNode(id, (error, areaNode) => {
            if (error) {
                console.error(error);
                return;
            }
            //绘制载入的区划节点
            this.renderAreaNode(this.districtExplorer, areaNode);
        });
    }

    renderAreaNode = (districtExplorer, areaNode) => {
        //清除已有的绘制内容
        districtExplorer.clearFeaturePolygons();
        //just some colors
        const colors = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00"];
        //绘制子级区划
        districtExplorer.renderSubFeatures(areaNode, function (feature, i) {
            const fillColor = colors[i % colors.length];
            const strokeColor = colors[colors.length - 1 - i % colors.length];
            return {
                cursor: 'default',
                bubble: true,
                strokeColor:  'default' || strokeColor, //线颜色
                strokeOpacity: 0, //线透明度
                strokeWeight: 0, //线宽
                fillColor:  'default' || fillColor, //填充色
                fillOpacity: 0, //填充透明度
            };
        });

        //绘制父级区划，仅用黑色描边
        districtExplorer.renderParentFeature(areaNode, {
            cursor: 'default',
            bubble: true,
            strokeColor:  'default' || 'black', //线颜色
            fillColor:  null,
            strokeWeight: 0, //线宽
            strokeOpacity: 0, //线透明度
        });

        //更新地图视野以适合区划面
        // this.props.__map__.setFitView(districtExplorer.getAllFeaturePolygons());
        // this.props.__map__.setBounds(areaNode.getBounds(), null, null, true);
    }

    render() {
        return null
    }
}

export default withRouter(ParkingMap)
