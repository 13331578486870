import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import CityModel from '../../../model/cityModel'
import { AttendanceTimes, InspectionSection } from '../../../model/model'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import floor1Base64 from '../../../static/images/inspectionMonitoring/01@2x.png'
import floor2Base64 from '../../../static/images/inspectionMonitoring/02@2x.png'
import floor3Base64 from '../../../static/images/inspectionMonitoring/03@2x.png'
import PubSub from 'pubsub-js'

interface Props{
    type?: 'attendanceTimes'
}
interface State{
    inspectionSectionTop10?: Array<InspectionSection>
}
export class AttendanceTimesTop10 extends PureComponent<Props,State> {

    constructor(props:Props){
        super(props)
        this.state = {
            inspectionSectionTop10: [ 
                // {"total": 3,"userId": 9,"userName": "a"},
            ]
        }
    }

    componentDidMount(){
        switch (this.props.type) {
            case 'attendanceTimes':
                this.getAttendanceTimesTop10()
                PubSub.subscribe('mapParking',(msg: string, info:any)=>{
                    this.getAttendanceTimesTop10(info.adcode)
                })
                break;
            default:
                break;
        }
        
    }

    // 本月巡检员出勤次数Top10
    getAttendanceTimesTop10 = (code?:string) => {
        const cityId = code || localStorage.getItem('cityCode') || '440300'
        CityModel.inspectionSectionTop10({cityId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({inspectionSectionTop10:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日支付方式分布数据失败')
            }
        })
    }

    getEChartsData = () => {
        let echartsData: Array<{name:string,value:number,maxValue:number}> = [{name:'',value:0,maxValue:0}]
        switch (this.props.type) {
            case 'attendanceTimes':
                const { inspectionSectionTop10 } = this.state
                const maxValue = Math.max(...(inspectionSectionTop10.map(item=>item.times)))
                echartsData = inspectionSectionTop10.map((item,index) => {
                    return {
                        name: item.parkingName,
                        value: item.times,
                        maxValue
                    }
                })
                break;
            default:
                break;
        }
        return echartsData
    }

    getOptions = () => {
        let echartsData = this.getEChartsData()
        const icons = [`image://${floor1Base64}`,`image://${floor2Base64}`,`image://${floor3Base64}`]
        const myColor = ['#F7B500','#FF719C','#32C5FF','#3BC551','#997CFF','#3266FF','#BCD708','#D81BFF','#F04C4C','#5D59F6']
        return {
            // backgroundColor:"#003366",
            grid: {
                left: '30',
                right: '2%',
                bottom: '2%',
                top: '2%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis' as 'axis',
                axisPointer: {
                    type: 'none' as 'none'
                },
                formatter: function(params: any) {
                    return params[0].name  + ' : ' + params[0].value
                }
            },
            xAxis: {
                show: false,
                type: 'value' as 'value'
            },
            yAxis: [{
                type: 'category' as 'category',
                inverse: true,
                axisLabel: {
                    show: true,
                    align: 'right',
                    textStyle: {
                        color: '#fff'
                    },
                    formatter:  (value, index) => {
                        // 格式化成月/日，只在第一个刻度显示年份
                        return value.length<=5?value:value.slice(0,4)+'...';
                    }
                },
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                data: echartsData.map(item => item.name)
            }, {
                type: 'category' as 'category',
                inverse: true,
                axisTick: 'none' as 'none',
                axisLine: {
                    show: false
                },
                show: true,
                axisLabel: {
                    interval: 0,
                    color: '#ffffff',
                    align: 'left',
                    margin: 20,
                    fontSize: 12,
                    formatter: function (value: any, index: number) {
                        return '第' + (index+1) + '名 ' + value
                    },
                },
                data: echartsData.map(item => item.value),
            }],
            series: [
                {
                    name: '值',
                    type: 'bar' as 'bar',
                    zlevel: 1,
                    itemStyle: {
                        normal: {
                            barBorderRadius: 8,
                            color: (params) => {
                                const num = myColor.length;
                                return myColor[params.dataIndex % num]
                            },
                        },
                    },
                    barWidth: 4,
                    data: echartsData.map(item => item.value)
                },
                {
                    name: '背景',
                    type: 'bar' as 'bar',
                    barWidth: 4,
                    barGap: '-100%',
                    data: echartsData.map(item => item.maxValue),
                    itemStyle: {
                        normal: {
                            color: 'rgba(0, 119, 226, 1)',
                            barBorderRadius: 8,
                        }
                    },
                },
                {
                    type: 'pictorialBar',
                    // symbol: floor1Base64,
                    symbolPosition: 'start',
                    symbolSize: [16, 22],
                    symbolRotate: 0,
                    symbolOffset: [-90, 0],
                    data: echartsData.slice(0,3).map((item,index)=>{
                        return {
                            value: item.value,
                            symbol: icons[index]
                        }
                    })
                }
            ]
        };
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default AttendanceTimesTop10
