import echarts from "echarts"
import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import CityModel from '../../../model/cityModel'
import { EquipmentReportTimes } from '../../../model/model'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'

interface State{
    equipmentReport: Array<EquipmentReportTimes>
}
export class EquipmentReport extends PureComponent<any,State> {
    timer: NodeJS.Timeout | number

    constructor(props:any){
        super(props)
        this.state = {
            equipmentReport: null
        }
    }

    componentDidMount = () => {
        this.getEquipmentReport()
        this.timer = setInterval(()=>{
            this.getEquipmentReport()
        }, 60*1000 )
    }

    componentWillUnmount = () => {
        clearInterval(Number(this.timer))
    }

    getEquipmentReport = () => {
        CityModel.equipmentReportDay({timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    this.setState({equipmentReport:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取厂商设备占比统计数据失败')
            }
        })
    }

    getEChartsData = () => {
        const {equipmentReport} = this.state
        const xData = (equipmentReport||[]).map(item => {
            for(let key in item){
                return key.slice(-2)
            }
        })
        const yData = (equipmentReport||[]).map(item => {
            for(let key in item){
                return Number(item[key].heartbeat)+Number(item[key].enter)+Number(item[key].exit)
            }
        })
        return {
            xData,
            yData
        }
    }

    getOptions(){
        const {xData,yData} = this.getEChartsData()
        return {
            color: ['rgba(48,149,250,0.85)'],
            grid: {
                left: '0%',
                right: '2%',
                top: '8%',
                bottom: '0%',
                containLabel: true
            },
            tooltip: {
                show: true,
                trigger: 'axis' as 'axis',
                axisPointer: {
                    type: 'shadow' as 'shadow'
                },
                formatter: function(params: any) {
                    return params[0].name  + '<br>' + params[0].seriesName + '：' + params[0].value
                }
            },
            yAxis: {
                type: 'value' as 'value',
                min: 0,
                // splitNumber: 4,
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#35A1F0',
                    }
                },
                axisLabel: {
                    show: true,
                    color: "#35A1F0",
                    fontSize: 12
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#35A1F0',
                        opacity:0.23
                    }
                },
            },
            xAxis: [{
                axisTick: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(53, 161, 240, 0.45)',
                    }
                },
                type: 'category' as 'category',
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: true,
                    color: '#35A1F0',
                    // interval: 2,  
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        type: 'dashed' as 'dashed'
                    }
                },
                boundaryGap: false,
                data: xData,
            }],
            series: [
                {
                    name: '今日上报数据统计',
                    type: 'bar' as 'bar',
                    barWidth: 8,
                    label: {
                        normal: {
                            show: false,
                            position: 'right' as 'right',
                            textStyle: {
                                color: "#35A1F0",
                                fontSize: 14
                            }
                        }
                    },
                    itemStyle: {
                        normal: {
                            // barBorderRadius: [0, 0, 5, 0],
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#71D2FF'
                            }, {
                                offset: 1,
                                color: '#4372FF'
                            }])
                        }
                    },
                    data: yData
                },
                {
                    name: '',
                    type: 'line',
                    symbol: 'circle',
                    symbolSize: 10,
                    lineStyle: {
                        normal: {
                            color: "#32C5FF", // 线条颜色
                        },
                        borderColor: '#32C5FF'
                    },
                    itemStyle: {
                        normal: {
                            color: "#32C5FF",
                        }
                    },
                    data: yData
                }
            ]
        };
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default EquipmentReport
