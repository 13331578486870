import { Button, Card, Checkbox, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react'
import './loginContainer.css'
import userNameIcon from '../../static/images/login/用户名@2x.png'
import passwordIcon from '../../static/images/login/密码@2x.png'
import { HttpClientImmidIot } from '../../model/request/HttpClientImmidIot';
import LoginModel from '../../model/loginModel';
import CryptoJS from 'crypto-js'
import _ from 'lodash';
import {getUserIP,login} from '../../model/SystemFunction'

const FormItem = Form.Item;

export function LoginContainer(props){
    const [form] = Form.useForm();
    const [autoLogin, setautoLogin] = useState(false)
    const [isNeedVarifyCode, setisNeedVarifyCode] = useState(false)
    const [varifyImage, setvarifyImage] = useState('')
    const [loadLogin, setloadLogin] = useState(false)

    const handleSubmit = (values) => {
        setloadLogin(true)
        const {account,password,varifyCode} = values
        login(account,password,varifyCode,(d, type)=>{
            setloadLogin(false)
            if (type === HttpClientImmidIot.requestSuccess) {
                if (d.success) {
                    if (autoLogin&&window.customCookie) {
                        window.customCookie.set('access_token', d.data.access_token, 7);
                    }
                    localStorage.setItem('autoLogin', String(autoLogin));
                    props.history.push({ pathname: '/outRoadParking' })
                }
            }else{
                getVerifyCode();
                //失败----做除了报错之外的操作
                if (d.code === 10018) { //需要验证码
                    setisNeedVarifyCode(true)
                }
            }
        })
    }

    // 获取验证码
    const getVerifyCode = () => {
        const params = {
            userName: form.getFieldValue("account"),
        };
        LoginModel.getVerifyCode(params, (d, type) => {
            if (type === HttpClientImmidIot.requestSuccess) {
                setisNeedVarifyCode(true)
                setvarifyImage(d.data)
            }
        })
    }

    const changeAutoLogin = () => {
        setautoLogin(!autoLogin)
    };

    return (
        <div className='login_container'>
            <Card className='login_form'>
                <div className='login_main'>
                    <div className='system_name'>
                        <p className='system_name_title'>{window.location.href.includes('fy')?'颍州区':'锦沃'}智慧停车大数据平台</p>
                        <p className='system_name_vision'>{window.location.href.includes('fy')?'':'锦沃'}让停车更智慧，让生活更美好</p>
                    </div>
                    <Form className="login-form" onFinish={handleSubmit} form={form}>
                        <FormItem label={<img className='label_icon' src={userNameIcon} alt=""/>} colon={false}
                            name="account"
                            rules={[{ 
                                validator: (rule, value, callback)=>{
                                    if (!value) {
                                        callback('请输入账号！');
                                    }else {
                                        callback();
                                    }
                                }
                            }]}>
                            <Input className='login-form-input' maxLength={328}
                                //    prefix={<Icon type="user" style={{ fontSize: 13 }}/>} 
                                placeholder="账户"/>
                        </FormItem>
                        <FormItem label={<img className='label_icon' src={passwordIcon} alt=""/>} colon={false}
                            name="password"
                            rules={[{ 
                                validator: (rule, value, callback)=>{
                                    if (!value) {
                                        callback('请输入密码！');
                                    }else {
                                        callback();
                                    }
                                }
                            }]}>
                            <Input className='login-form-input'
                                //    prefix={<Icon type="lock" style={{ fontSize: 13 }}/>} 
                                type="password"
                                placeholder="密码" />
                        </FormItem>
                        {
                            isNeedVarifyCode && (
                                <FormItem className='login_varify_code' style={{paddingLeft: '3.3vh'}}>
                                    <FormItem name="varifyCode" rules={[{ required: true, message: '请输入验证码!' }]} style={{ width: '65%' }}>
                                        <Input className='login-form-input'
                                            placeholder="验证码" 
                                        />
                                    </FormItem>
                                    <img style={{ width: '25%', float:'right', marginBottom:'24px' }} alt=""
                                        src={`data:image/png;base64,${varifyImage}`}
                                        onClick={getVerifyCode} />
                                </FormItem>
                            )
                        }
                        <FormItem>
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 0 2.9vh 0' }}>
                                <Checkbox defaultChecked={autoLogin} onChange={changeAutoLogin}>
                                    下次自动登录
                                </Checkbox>
                                <a style={{ fontSize: '14px', lineHeight: '22px', color: '#36A2F0' }} 
                                onClick={(e) => {
                                    props.history.push({ pathname: '/ResetPassword' })
                                }}>忘记密码？</a>
                            </div>
                            <Button type="primary" htmlType="submit" loading={loadLogin}
                                className="login-form-button">
                                登录
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </Card>
        </div>
    )
}

export default LoginContainer

