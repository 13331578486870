import { Popover } from 'antd'
import moment from 'moment'
import React, { PureComponent, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay  } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
import 'swiper/swiper-bundle.css';
import { parking_profile } from '../../model/modelData'
import { CityProfile, ParkingMapList, ParkingProfile, RealTimeTraffic, ViolationProfile } from '../../model/model'
import ParkingModel from '../../model/parkingModel'
import { HttpClientImmidIot } from '../../model/request/HttpClientImmidIot'
import parkIcon_b from '../../static/images/parkingStatistics/P@2x(1).png'
import parkIcon_t from '../../static/images/parkingStatistics/P@2x.png'
import dataIcon1 from '../../static/images/parkingStatistics/停车场@2x.png'
import addressIcon from '../../static/images/parkingStatistics/地址icon@2x.png'
import locationIcon from '../../static/images/parkingStatistics/定位@2x.png'
import areaIcon from '../../static/images/parkingStatistics/所属区域icon@2x.png'
import stopIcon_b from '../../static/images/parkingStatistics/故障icon@2x(1).png'
import stopIcon_t from '../../static/images/parkingStatistics/故障icon@2x.png'
import dataIcon3 from '../../static/images/parkingStatistics/欠费金额icon@2x.png'
import redCar_b from '../../static/images/parkingStatistics/红色车@2x(1).png'
import redCar_t from '../../static/images/parkingStatistics/红色车@2x.png'
import greenCar_b from '../../static/images/parkingStatistics/绿色车@2x(1).png'
import greenCar_t from '../../static/images/parkingStatistics/绿色车@2x.png'
import dataIcon2 from '../../static/images/parkingStatistics/缴费金额icon@2x.png'
import blueCar_b from '../../static/images/parkingStatistics/蓝色车@2x(1).png'
import blueCar_t from '../../static/images/parkingStatistics/蓝色车@2x.png'
import administrativeRegionIcon from '../../static/images/parkingStatistics/行政区域icon@2x.png'
import yellowCar_b from '../../static/images/parkingStatistics/黄色车@2x(1).png'
import yellowCar_t from '../../static/images/parkingStatistics/黄色车@2x.png'
import BerthSurvey from '../statistics/charts/berthSurvey'
import NumberOfStop from '../statistics/charts/numberOfStop'
import ParkingTime from '../statistics/charts/parkingTime'
import PaymentMethod from '../statistics/charts/paymentMethod'
import RevenueAnalysis from '../statistics/charts/revenueAnalysis'
import { ChartCard } from '../statistics/statistics'
import './parkingStatistics.css'

export class ParkingStatistics extends PureComponent {

    /* 泊位概况 */
    berthSurvey = () => {
        const element = <div className='berth_survey_data'>
            <BerthSurvey level='park' type='berthSurvey'/>
        </div>
        return <ChartCard chartName='泊位概况' style='berth_survey' children={element}/>
    }

    /* 停车时长分布  */
    parkingTimeDistribution = () => {
        const element = <div className='time_distribution_data'>
            <ParkingTime type='parkingTimeDistributionOfPark' seriesName='停车时长分布'/>
        </div>
        return <ChartCard chartName='停车时长分布' style='time_distribution' children={element}/>
    }

    // 同车牌月停车次数
    monthlyParkingTimes = () => {
        const element = <div className='monthly_parking_times_data'>
            <PaymentMethod type='monthlyParkingTimes'/>
        </div>
        return <ChartCard chartName='同车牌月停车次数' style='monthly_parking_times' children={element}/>
    }

    // 每小时占用率
    hourlyOccupancyRate = () => {
        const element = <div className='hourly_occupancy_rate_data'>
            <NumberOfStop type='hourlyOccupancyRate'/>
        </div>
        return <ChartCard chartName='每小时占用率' style='hourly_occupancy_rate' children={element}/>
    }

    // 周转率分析
    turnoverAnalysis = () => {
        const element = <div className='turnover_analysis_data'>
            <NumberOfStop type='turnoverAnalysis'/>
        </div>
        return <ChartCard chartName='周转率分析' style='turnover_analysis' children={element}/>
    }

    // 营收分析
    revenueAnalysis = () => {
        const element = <div className='revenueAnalysis_data'>
            <RevenueAnalysis type='parking'/>
        </div>
        return <ChartCard chartName='营收分析' style='revenueAnalysis' children={element}/>
    }

    render() {
        return (
            <div className='parking_statistics_main'>
                {/* 左侧四张图表 */}
                <div className='parking_statistics_left'>
                    <ParkingLotSituation {...this.props}/>
                    {this.berthSurvey()}
                    <StopViolation {...this.props}/>
                    {this.parkingTimeDistribution()}
                </div>
                {/* 中间车场状况图及底部图表 */}
                <div className='parking_statistics_middle'>
                    <DepotAddress {...this.props}/>
                    <ParkingMap {...this.props}/>
                    <DepotAddress {...this.props} type='indicators'/>
                    {/* <ParkingLotIndicators {...this.props}/> */}
                    {/* 底部图表 */}
                    <div className='parking_statistics_bottom'>
                        {this.turnoverAnalysis()}
                        {this.revenueAnalysis()}
                    </div>
                </div>
                {/* 右侧三张图表 */}
                <div className='parking_statistics_right'>
                    {this.monthlyParkingTimes()}
                    {this.hourlyOccupancyRate()}
                    <RealtimeTrafficFlow />
                </div>
            </div>
        )
    }
}

export default ParkingStatistics

// 车场停车概况图
function ParkingMap(props:any){
    const [parkingMap, setParkingMap] = useState<ParkingMapList | null>(null)
    let timer:NodeJS.Timeout | number

    useEffect(()=>{
        getParkingMapList()
        timer = setInterval(()=>{
            getParkingMapList()
        }, 60*1000 )
        return ()=>{
            clearInterval(Number(timer))
        }
    },[])

    // 获取泊位监控数据
    const getParkingMapList = () => {
        const parkingId = props.location.query&&props.location.query.parkingId || localStorage.getItem('parkingId') || 6
        ParkingModel.parkingMap({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setParkingMap(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取泊位监控数据失败')
            }
        })
    }

    const {parkingSpaceListUp,parkingSpaceListDown,parkingDirectLeft,parkingDirectRight} = parkingMap || {}
    const params = {
        className: 'parking_space_top',
        slidesPerView: 10,
        spaceBetween: 0,
        // pagination: { clickable: true }
    }
    return <div className='parking_map_bg'>
        {/* 停车位-上 */}
        <Swiper {...params} >
            {(parkingSpaceListUp || []).map((item,index) => {
                const {parkingSpaceStatus,plateNumber,orderType,parkingSpaceNo,inTime,parkOrderStatus} = item
                const icon = (parkOrderStatus) => {
                    switch (orderType) {
                        case 1:
                        case 2:
                            return greenCar_b
                        case 3: 
                            return redCar_b
                        case 0:
                            if(parkOrderStatus==10){
                                return redCar_b
                            }else{
                                return yellowCar_b
                            }
                        default:
                            return redCar_b
                    }
                }
                const icons = [ parkIcon_t, icon(parkOrderStatus), stopIcon_t, icon(parkOrderStatus) ]
                const iconClass = ['park_img','car_img','park_img','car_img']
                return <SwiperSlide key={index} className='parking_space' >
                    <div className='parking_icon'>
                        {(parkingSpaceStatus == 1 || parkingSpaceStatus == 3) && 
                            <Popover placement="top" 
                                overlayClassName='parking_space_popover'
                                content={<div className='parking_popover_content'>
                                <img src="" alt=""/>
                                <div>{plateNumber}</div>
                                <div>{parkingMap.parkingName}</div>
                                <div>{['入场','预付费','后付费','PDA订单'][orderType]}</div>
                                <div>{inTime?moment(inTime).format('YYYY-MM-DD HH:mm:ss'):'未知'}</div>
                            </div>}>
                                <img className={iconClass[parkingSpaceStatus]} src={icons[parkingSpaceStatus]} alt=""/>
                            </Popover>}
                        {(parkingSpaceStatus == 0 || parkingSpaceStatus == 2) && <img className={iconClass[parkingSpaceStatus]} src={icons[parkingSpaceStatus]} alt=""/>}
                    </div>
                    <div className='parking_id'>{parkingSpaceNo}</div>
                    {(parkingSpaceListUp || []).length!=index+1 && <div className='parking_border' />}
                </SwiperSlide>
            })}
        </Swiper>
        {/* 中间车道 */}
        <div className='parking_map_lane'>
            <div>{parkingDirectLeft}</div>
            <div>{parkingDirectRight}</div>
        </div>
        {/* 停车位-下 */}
        <Swiper {...params}>
            {(parkingSpaceListDown || []).map((item,index) => {
                const {parkingSpaceStatus,plateNumber,orderType,parkingSpaceNo,inTime,parkOrderStatus} = item
                const icon = (parkOrderStatus) => {
                    switch (orderType) {
                        case 1:
                        case 2:
                            return greenCar_b
                        case 3: 
                            return redCar_b
                        case 0:
                            if(parkOrderStatus==10){
                                return redCar_b
                            }else{
                                return yellowCar_b
                            }
                        default:
                            return redCar_b
                    }
                }
                const icons = [ parkIcon_b, icon(parkOrderStatus), stopIcon_b, icon(parkOrderStatus) ]
                const iconClass = ['park_img','car_img','park_img','car_img']
                return <SwiperSlide key={index} className='parking_space' style={{padding:'0.1rem 0 0.28rem 0'}}>
                    <div className='parking_id'>{parkingSpaceNo}</div>
                    <div className='parking_icon'>
                        {(parkingSpaceStatus == 1 || parkingSpaceStatus == 3) && 
                            <Popover placement="top" 
                                overlayClassName='parking_space_popover'
                                content={<div className='parking_popover_content'>
                                    <img src="" alt=""/>
                                    <div>{plateNumber}</div>
                                    <div>{parkingMap.parkingName}</div>
                                    <div>{['入场','预付费','后付费','PDA订单'][orderType]}</div>
                                    <div>{inTime?moment(inTime).format('YYYY-MM-DD HH:mm:ss'):'未知'}</div>
                                </div>}>
                                <img className={iconClass[parkingSpaceStatus]} src={icons[parkingSpaceStatus]} alt=""/>
                            </Popover>}
                        {(parkingSpaceStatus == 0 || parkingSpaceStatus == 2) && <img className={iconClass[parkingSpaceStatus]} src={icons[parkingSpaceStatus]} alt=""/>}
                    </div>
                    {(parkingSpaceListDown || []).length!=index+1 && <div className='parking_border_b' />}
                </SwiperSlide>
            })}
        </Swiper>
    </div>
}

// 车场概况
function ParkingLotSituation(props:any){
    const [parkingProfile, setParkingProfile] = useState<ParkingProfile | null>(parking_profile)
    let timer:NodeJS.Timeout | number

    useEffect(()=>{
        getParkingProfile()
        timer = setInterval(()=>{
            getParkingProfile()
        }, 5*1000 )
        return ()=>{
            clearInterval(Number(timer))
        }
    },[])

    const getParkingProfile = () => {
        const parkingId = props.location.query&&props.location.query.parkingId || localStorage.getItem('parkingId')
        ParkingModel.parkingProfile({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setParkingProfile(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取车场概况失败')
            }
        })
    }

    const data = [
        {field:'泊位数',value: parkingProfile?parkingProfile.totalSpaceCount:'1500'},
        {field:'在停车辆',value: parkingProfile?parkingProfile.usedSpaceCount:'120,128'},
    ]
    const element = <div className='parking_lot_situation_data'>
        {data.map((item,index)=><div key={index}>
            <div className='parking_data_field'>{item.value}</div>
            <div className='parking_data_value'>{item.field}</div>
        </div>)}
    </div>
    return <ChartCard chartName='车场概况' style='parking_lot_situation' children={element}/>
}


// 今日违停概况
function StopViolation(props:any){
    const [violationProfile, setViolationProfile] = useState<Array<ViolationProfile> | null>(null)
    let timer:NodeJS.Timeout | number

    useEffect(()=>{
        getViolationProfile()
        timer = setInterval(()=>{
            getViolationProfile()
        }, 5*1000 )
        return ()=>{
            clearInterval(Number(timer))
        }
    },[])

    const getViolationProfile = () => {
        const parkingId = props.location.query&&props.location.query.parkingId || localStorage.getItem('parkingId')
        ParkingModel.violationProfile({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setViolationProfile(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日违停概况失败')
            }
        })
    }

    const nopay = violationProfile&&violationProfile.find(e=>e.parkWarnType==4)
    const nopark = violationProfile&&violationProfile.find(e=>e.parkWarnType==3)
    const blacklist = violationProfile&&violationProfile.find(e=>e.parkWarnType==6)
    const berth = violationProfile&&violationProfile.find(e=>e.parkWarnType==16)
    const reverse = violationProfile&&violationProfile.find(e=>e.parkWarnType==15)
    const data = [
        {field:'泊位违停',value: ((nopay&&nopay.rate)+(nopark&&nopark.rate)+(blacklist&&blacklist.rate)+(berth&&berth.rate)+(reverse&&reverse.rate)) || '0'},
        {field:'未缴费停车',value: nopay&&nopay.rate || '0'},
        {field:'禁停时间停车',value: nopark&&nopark.rate || '0'},
        {field:'黑名单禁停区停车',value: blacklist&&blacklist.rate || '0'},
        {field:'跨泊位停车',value: berth&&berth.rate || '0'},
        {field:'逆向停车',value: reverse&&reverse.rate || '0'},
    ]
    const element = <div className='stop_violation_data'>
        {data.map((item,index)=><div key={index}>
            <div className='stop_violation_field'>{item.value}{(index==3?<span className='stop_violation_value'>(分钟)</span>:'')}</div>
            <div className='stop_violation_value'>{item.field}</div>
        </div>)}
    </div>
    return <ChartCard chartName='今日违停概况' style='stop_violation' children={element}/>
}


// 车场地址
function DepotAddress (props:any){
    const [cityProfile,setCityProfile] = useState<CityProfile|null>(null)
    let timer:NodeJS.Timeout | number

    useEffect(()=>{
        getUrbanResources()
        timer = setInterval(()=>{
            getUrbanResources()
        }, 5*1000 )
        return ()=>{
            clearInterval(Number(timer))
        }
    },[])

    // 获取今日城市概况
    const getUrbanResources = () => {
        const parkingId = props.location.query&&props.location.query.parkingId || localStorage.getItem('parkingId')
        ParkingModel.cityProfile({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setCityProfile(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日城市概况失败')
            }
        })
    }

    if(props.type == 'indicators'){
        const data = [
            {field:'今日停车次数',value: cityProfile&&cityProfile.todayParkingTimesTotal || '0',icon:dataIcon1},
            {field:'今日缴费金额',value: cityProfile&&cityProfile.todayParkingIncome ||'0',icon:dataIcon2},
            {field:'今日欠费金额',value: cityProfile&&cityProfile.todayArrearageAmount ||'0',icon:dataIcon3},
        ]
        return <div className='parkingLot_indicators'>
            {data.map((item,index)=><div key={index}>
                <img src={item.icon} alt=""/>
                <div>
                    <div className='parkingLotIndicators_amount' style={{color:['#966BFF','#36CFC9','#FF7F5B'][index]}}>{item.value}</div>
                    <div className='parkingLotIndicators_field'>{item.field}</div>
                </div>
            </div>)}
        </div>
    }
    return <div className='depot_address'>
        <div className='parking_lot'><img src={locationIcon} alt=""/>{cityProfile&&cityProfile.streetName||'中心六路'}</div>
        <div className='parkinglot_detailed_address'>
            <div><img src={administrativeRegionIcon} alt=""/>行政区域：{cityProfile&&cityProfile.areaName || '南山区'}</div>
            <div><img src={areaIcon} alt=""/>所属片区：{cityProfile&&cityProfile.parkingName||'高新园片区'}</div>
            <div><img src={addressIcon} alt=""/>地址：{cityProfile&&cityProfile.parkingDesc||'学府路中信海阔天空小区旁中铁二局高新区'}</div>
        </div>
    </div>
}

// 车流：实时
function RealtimeTrafficFlow () {
    const [realTimeTraffic,setRealTimeTraffic] = useState<Array<RealTimeTraffic>>(null)
    let timer:NodeJS.Timeout | number
    useEffect(()=>{
        getRealtimeTrafficFlow()
        timer = setInterval(()=>{
            getRealtimeTrafficFlow()
        }, 60*1000 )
        return ()=>{
            clearInterval(Number(timer))
        }
    },[])
    const getRealtimeTrafficFlow = () => {
        const parkingId = localStorage.getItem('parkingId')
        ParkingModel.realtimeTrafficFlow({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    setRealTimeTraffic(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取车流：实时信息失败')
            }
        })
    }
    const params = {
        direction : 'vertical' as 'vertical',
        slidesPerView : 10,
        autoplay: { delay: 1000, disableOnInteraction: false },
        loop: true,
    }
    const element = <Swiper {...params} className='realtime_traffic_flow_data'>
        {(realTimeTraffic||[]).map((item,index) => <SwiperSlide className='realtime_traffic_flow_item' key={index}>
            <div>{item.parkingName}</div>
            <div>{item.parkingSpaceNo}</div>
            <div>{['出场','入场','','入场'][item.parkingSpaceStatus] || ''}</div>
            <div>{moment(item.updateTime).format('YYYY-MM-DD HH:mm')}</div>
        </SwiperSlide>)}
    </Swiper>
    return <ChartCard chartName='车流：实时' style='realtime_traffic_flow' children={element}/>
}
