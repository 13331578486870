import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import { RevenueData } from '../../../model/model'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../../model/SystemFunction'
import { MapParking } from '../statistics'
import { AreaHotPoints,AreaTurnover } from '../../../model/outRoadModelInterface'
import { area_hot_points,area_turnover} from '../../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../../model/outRoadModelRequest'

interface State{
  revenue?: Array<RevenueData>,
  areaHotPoints?: Array<AreaHotPoints>,
  areaTurnover?: Array<AreaTurnover>,
}
interface Props{
  type?: 'revenue' | 'turnover' | 'areaHotPoints' | 'areaTurnover'
}
export class RevenueCapability extends PureComponent<Props,State> {
    timer: NodeJS.Timeout | number

    constructor(props:Props){
      super(props)
      this.state = {
        revenue: null,
        areaHotPoints:[],
        areaTurnover:[],

      }
    }

    componentDidMount = () => {
        switch (this.props.type) {
            case 'areaHotPoints':
                this.getAreaHotPoints()
                this.timer = setInterval(() => {
                    this.getAreaHotPoints()
                }, 60*1000)
                break;            
            case 'areaTurnover':
                this.getAreaTurnover()
                this.timer = setInterval(() => {
                    this.getAreaTurnover()
                }, 60*1000)
                break;
            default:
                this.getRevenueData({ level: 'top', adcode: localStorage.getItem('cityCode') || '440300' })
                this.timer = setInterval(() => {
                    this.getRevenueData({ level: 'top', adcode: localStorage.getItem('cityCode') || '440300' })
                }, 60*1000)
                PubSub.subscribe('mapParking', (msg: string, info: MapParking) => {
                    if (this.timer) {
                        clearInterval(Number(this.timer))
                    }
                    this.getRevenueData(info)
                    this.timer = setInterval(() => {
                        this.getRevenueData(info)
                    }, 60*1000)
                })
                break;
        }
    }

    componentWillUnmount(){
        clearInterval(Number(this.timer))
        PubSub.unsubscribe('mapParking');
    }

    // 获取区域营收分析数据
    getRevenueData = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.revenueCapability({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    let data = d.data && d.data.length == 0 ? null : d.data
                    if(info.level=='second'&&data){
                        data = data.map(e => {
                            e.areaName = e.parkingName
                            return e
                        })
                    }
                    this.setState({revenue:data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取区域营收分析数据失败')
            }
        })
    }

    //中底1/2 热点停车区域 周转能力分析
    getAreaHotPoints = () => {
        OutRoadModelRequest.areaHotPoints(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
               if (d.success) {
                    this.setState({revenue:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取周转能力分析失败')
            }
        })
    }     
    //2.8 周转能力分析
    getAreaTurnover = () => {
        OutRoadModelRequest.areaTurnover(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
               if (d.success) {
                    this.setState({revenue:d.data&&d.data.length==0?null:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取周转能力分析失败')
            }
        })
    } 

    getOptions = () => {
        const {revenue} = this.state
        let areaData=[
          {name: "颍州区",max: 0,num: 0},
        //   {name: "坪山区",max: 60,num: 30},
        //   {name: "大鹏区",max: 60,num: 40},
        //   {name: "龙岗区",max: 60,num: 40},
        //   {name: "光明区",max: 60,num: 50},
        //   {name: "龙华区",max: 60,num: 30},
        //   {name: "罗湖区",max: 60,num: 40},
        //   {name: "南山区",max: 60,num: 40},
        //   {name: "宝安区",max: 60,num: 50}
        ]
        let title = ''
        if(revenue){
          const field:{[key:string]:('inCome'|'turnover')} = { revenue:'inCome', turnover:'turnover', areaHotPoints:'turnover',areaTurnover:'turnover' }
          const type = field[this.props.type]
          const max = Math.max(...(revenue.map(item=>item[type])))
          areaData = revenue.map(item=>{return {name:item.areaName,max:max,num:item[type]}})
          switch (this.props.type || '') {
          // switch (type || this.props.type) {
            case 'revenue':
              title = '收入';break;
            case 'turnover':
              title = '周转能力';break;            
            case 'areaTurnover':
              title = '周转能力';break;
            default: 
              break;
          }
        }
        const areaValueData = areaData.map(item=>item.num)
        return {
          title: {
              text: ''
          },
          tooltip: {
              trigger: 'item' as 'item',
              backgroundColor: 'rgba(0,0,250,0.2)'
          },
          legend: {
              show: false
          },
          radar: {
            // shape: 'circle',
            center: ['50%', '50%'],
            radius: '70%',
            triggerEvent: true,
            name: {
              color: '#FFFFFF',
              fontSize: 12,
              formatter: '{value}',
            },
            nameGap: '3',
            indicator: areaData,
            splitArea: {
              show:false,
              areaStyle: {
                // color: [
                //   'rgba(112, 111, 255, 0.1)', 'rgba(112, 111, 255, 0.2)',
                //   'rgba(112, 111, 255, 0.4)', 'rgba(112, 111, 255, 0.6)',
                //   'rgba(112, 111, 255, 0.8)', 'rgba(112, 111, 255, 1)'
                // ].reverse()
              }
            },
            // axisLabel:{//展示刻度
            //     show: true
            // },
            axisLine: { //
              lineStyle: {
                color: '#D1D1D1'
              }
            },
            splitLine: {
              lineStyle: {
                width: 1,
                color: '#D1D1D1'
              }
            },
  
          },
          series: [{
            name: '区域分布',
            type: 'radar',
            areaStyle: {
              normal: {
                  color: 'rgba(51, 102, 255, .45)'
              }
            },
            symbolSize: 0,
            lineStyle: {
              normal: {
                color: 'rgba(51, 102, 255, 1)',
                width: 2
              }
            },
            data: [{
              value: areaValueData,
              name: title
            }]
          }]
      };
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default RevenueCapability
