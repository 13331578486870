import echarts from "echarts"
import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import { ParkingArea } from '../../../model/model'
import { parking_area } from '../../../model/modelData'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../../model/SystemFunction'
import { MapParking } from '../statistics'
import { AreaHotPoints,AreaHotPointsParkings } from '../../../model/outRoadModelInterface'
import { area_hot_points, area_hot_points_parkings} from '../../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../../model/outRoadModelRequest'

interface Props{
    type?: 'statistics'|'areaHotPoints' | 'areaHotPointsParkings',
}
interface State{
    parkingArea?: Array<ParkingArea>,
    areaHotPoints?: Array<AreaHotPoints>,
    areaHotPointsParkings?: Array<AreaHotPointsParkings>,
}
export class HotSpotParkingArea extends PureComponent<any,State> {
    timer: NodeJS.Timeout | number
    constructor(props:any){
        super(props)
        this.state = {
            parkingArea: parking_area,
            areaHotPoints:[],
            areaHotPointsParkings:[],
        }
    }

    componentDidMount = () => {
        switch (this.props.type) {
            case 'statistics':
                this.getParkingArea({ level: 'top', adcode: localStorage.getItem('cityCode') || '440300' })
                this.timer = setInterval(() => {
                    this.getParkingArea({ level: 'top', adcode: localStorage.getItem('cityCode') || '440300' })
                }, 60 * 1000)
                PubSub.subscribe('mapParking', (msg: string, info: MapParking) => {
                    if (this.timer) {
                        clearInterval(Number(this.timer))
                    }
                    this.getParkingArea(info)
                    this.timer = setInterval(() => {
                        this.getParkingArea(info)
                    }, 60 * 1000)
                })
                break;
            case 'areaHotPoints':
                this.getAreaHotPoints()
                this.timer = setInterval(()=>{
                    this.getAreaHotPoints()
                }, 60*1000)
                break;              
            case 'areaHotPointsParkings':
                this.getAreaHotPointsParkings()
                this.timer = setInterval(()=>{
                    this.getAreaHotPointsParkings()
                }, 60*1000)
                break;   

            default:
                break;
        }

    }

    componentWillUnmount = () => {
        clearInterval(Number(this.timer))
        PubSub.unsubscribe('mapParking');
    }

    // 热点停车区域
    getParkingArea = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.parkingArea({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({parkingArea:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取热点停车区域概况失败')
            }
        })
    }
   //中底1 热点停车区域
    getAreaHotPoints = () => {
        OutRoadModelRequest.areaHotPoints(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
               if (d.success) {
                    this.setState({areaHotPoints:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取热点停车区域失败')
            }
        })
    }   
     //2.7 热点停车区域
    getAreaHotPointsParkings = () => {
        OutRoadModelRequest.areaHotPointsParkings(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
               if (d.success) {
                    this.setState({areaHotPointsParkings:d.data&&d.data.length>0?d.data:[]})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取热点停车区域失败')
            }
        })
    }  

    getEChartsData = () => {
        switch (this.props.type) {
            case 'statistics':
                const parkingArea = this.state.parkingArea ? this.state.parkingArea.reverse() : []
                return {
                    yData: parkingArea.map(item => item.parkingName),
                    xLeft: parkingArea.map(item => item.spaceUsageRate),
                    xRight: parkingArea.map(item => item.turnover),
                }
                break;            
            case 'areaHotPoints':
                const areaHotPoints = this.state.areaHotPoints ? this.state.areaHotPoints.reverse() : []
                return {
                    yData: areaHotPoints.map(item => item.areaName),
                    xLeft: areaHotPoints.map(item => item.spaceUsageRate),
                    xRight: areaHotPoints.map(item => item.turnover),
                }
                break;            
            case 'areaHotPointsParkings':
                const areaHotPointsParkings = this.state.areaHotPointsParkings ? this.state.areaHotPointsParkings.reverse() : []
                return {
                    yData: areaHotPointsParkings.map(item => item.parkingName),
                    xLeft: areaHotPointsParkings.map(item => item.spaceUsageRate),
                    xRight: areaHotPointsParkings.map(item => item.turnover),
                }
                break;
            default:
                break;
        }

    }
    getEChartsGrid= () =>{
        //后面优化, 可以根据y轴名称最长字符长度来控制width百分比
        switch (this.props.type) {
            case "areaHotPointsParkings":
                return  [{
                    top: 20,
                    bottom: 20,
                    left: "30%",
                    width: "35%",
                    containLabel: true
                },{
                    top: 20,
                    bottom: 18,
                    left: "30%",
                    // width: 0
                },
                {
                    top: 20,
                    bottom: 20,
                    right: 0,
                    width: "35%",
                    containLabel: true
                }];
                break;
            
            default:
                 return [{
                    top: 20,
                    bottom: 20,
                    left: "18%",
                    width: "40%",
                    containLabel: true
                },
                {
                    top: 20,
                    bottom: 18,
                    left: 70,
                    // width: 0
                },
                {
                    top: 20,
                    bottom: 20,
                    right: "2%",
                    width: "40%",
                    containLabel: true
                }];
                break;
        }
    }
    getOptions = () => {
        let echartsData = this.getEChartsData()
        return {
            tooltip: {
                trigger: "axis" as "axis",
                axisPointer: {
                    type: "shadow" as "shadow"
                },
                formatter: '{a}{c}%'
            },
            grid:this.getEChartsGrid(),
            
            xAxis: [{
                    position: "bottom" as "bottom",
                    type: "value" as "value",
                    inverse: true,
                    axisLabel: {
                        show: false,
                        color: "#fff",
                        margin: 8
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    splitNumber: 2
                },
                {
                    gridIndex: 1,
                    show: false
                },
                {
                    position: "bottom" as "bottom",
                    gridIndex: 2,
                    type: "value" as "value",
                    axisLabel: {
                        show: false,
                        color: "#fff",
                        margin: 8
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    splitNumber: 2
                }
            ],
            yAxis: [{
                type: 'category' as 'category',
                inverse: false,
                position: 'right' as 'right',
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                },
                data: echartsData.yData
            }, {
                gridIndex: 1,
                type: 'category' as 'category',
                inverse: false,
                position: 'left' as 'left',
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: true,
                    formatter: function(value, index) {
                        return value.length > 12 ? `${value.slice(0,12)}...` : value
                    },
                    textStyle: {
                        color: '#fff',
                        fontSize: 10,
                    },
                },
                data: echartsData.yData
            }, {
                gridIndex: 2,
                type: 'category' as 'category',
                inverse: false,
                position: 'left' as 'left',
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                    textStyle: {
                        color: '#000',
                        fontSize: 10,
                    },
    
                },
                data: echartsData.yData
            }],
            series: [{
                    type: "bar" as "bar",
                    name: "占用率",
                    label: {
                        fontSize: 10,
                        color: "#fff",
                        lineHeight: 20,
                        show: true,
                        fontFamily: "Rubik-Medium",
                        distance: 10,
                        normal: {
                            show: true,
                            position: 'inside',
                            formatter: (params) => {
                                if(params.value>0){
                                    return '占用率' + params.value+'%'
                                }else{
                                    return ''
                                }
                            },
                            textStyle: {
                                align: 'center',
                                baseline: 'middle',
                                fontSize: 10,
                                fontWeight: '400',
                                color:'#fff'
                            }
                        },
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                            offset: 0,
                            color: '#568AFB' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#544CF5' // 100% 处的颜色
                        }], false),
                        barBorderRadius: [8, 0, 0, 8]
                    },
                    data: echartsData.xLeft,
                    barWidth: 17
                },
                {
                    type: "bar" as "bar",
                    name: "周转率",
                    label: {
                        fontSize: 10,
                        color: "#fff",
                        lineHeight: 20,
                        show: true,
                        fontFamily: "Rubik-Medium",
                        distance: 10,
                        normal: {
                            show: true,
                            position: 'inside',
                            formatter: (params) => {
                                if(params.value>0){
                                    return '周转率' + params.value+'%'
                                }else{
                                    return ''
                                }
                            },
                            textStyle: {
                                align: 'center',
                                baseline: 'middle',
                                fontSize: 10,
                                fontWeight: '400',
                                color:'#fff'
                            }
                        },
                    },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                            offset: 0,
                            color: '#1163F0' // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: '#52C9FF' // 100% 处的颜色
                        }], false),
                        barBorderRadius: [0, 8, 8, 0]
                    },
                    data: echartsData.xRight,
                    barWidth: 17,
                    xAxisIndex: 2,
                    yAxisIndex: 2
                }
            ]
        };
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default HotSpotParkingArea
