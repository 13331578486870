import { callback, device_status } from './model'
import { HttpClientImmidIot } from "./request/HttpClientImmidIot"

// 市级
class LoginModel {

    // 获取验证码
    static getVerifyCode(param: any, callback: callback){
        const url = '/parking-authority/admin/verifiy/code/image'
        HttpClientImmidIot.query(url, 'GET', param, callback, false, 'application/x-www-form-urlencoded')
    }
    
}

export default LoginModel