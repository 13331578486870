import React, { PureComponent, useEffect, useState } from 'react'
import CityModel from '../../model/cityModel'
import dataIcon1 from '../../static/images/inspectionMonitoring/停车icon@2x.png'
import dataIcon2 from '../../static/images/inspectionMonitoring/完成派单icon@2x.png'
import dataIcon3 from '../../static/images/inspectionMonitoring/巡检派单icon@2x.png'
import ParkingTimeTop5 from '../berthMonitoring/charts/parkingTimeTop5'
import NumberOfStop from '../statistics/charts/numberOfStop'
import ParkingTime from '../statistics/charts/parkingTime'
import PaymentMethod from '../statistics/charts/paymentMethod'
import InspectionMap from '../statistics/map/inspectionMap'
import { ChartCard } from '../statistics/statistics'
import { InspectionEvents, ResourceProfile } from './../../model/model'
import { HttpClientImmidIot } from './../../model/request/HttpClientImmidIot'
import './../statistics/Statistics.css'
import AttendanceTimesTop10 from './charts/attendanceTimesTop10'
import './inspectionMonitoring.css'
import PubSub from 'pubsub-js'
import { ViolationTimes } from '../berthMonitoring/berthMonitoring'
import { Carousel } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment'

interface State{
    resourceProfile: ResourceProfile
}
export class InspectionMonitoring extends PureComponent<any,State> {

    constructor(props:any){
        super(props)
        this.state = {
            resourceProfile: null
        }
    }

    componentDidMount = () => {
        this.getResourceProfile()
        PubSub.subscribe('mapParking',(msg: string, info:any)=>{
            this.getResourceProfile(info.adcode)
        })
    }

    // 资源概况
    resourceProfile = () => {
        const {resourceProfile} = this.state
        const data = [
            {field:'片区数量', value: resourceProfile&&resourceProfile.subAreaTotal||0},
            {field:'中队数量', value: resourceProfile&&resourceProfile.inspectionGroupTotal||0},
            {field:'巡检员数量', value: resourceProfile&&resourceProfile.inspectionGroupMemberTotal||0},
            {field:'PDA设备数量', value: resourceProfile&&resourceProfile.pdaTotal||0} 
        ]
        const element = (
            <div className='resource_profile_data'>
                {data.map( (item,index) => <div className='chart_data_item' key={index}>
                    <div className='item_value'>{item.value}</div>
                    <div className='item_field'>{item.field}</div>
                </div> ) }
            </div>
        )
        return <ChartCard chartName='资源概况' style='resource_profile' children={element}/>
    }
    // 获取资源概况数据
    getResourceProfile = (code?:string) => {
        const cityId = code || localStorage.getItem('cityCode') || '440300'
        CityModel.parkingTime({cityId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success && d.data.length>0) {
                    this.setState({resourceProfile:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取资源概况数据失败')
            }
        })
    }

    // 片区巡检员占比
    inspectorsProportion = () => {
        const element = <div className='inspectors_proportion_data'>
            <ParkingTime type='inspectorsProportion' seriesName='片区巡检员占比'/>
        </div>
        return <ChartCard chartName='片区巡检员占比' style='inspectors_proportion' children={element}/>
    }

    // 片区巡检事件占比
    incidentsProportion = () => {
        const element = <div className='incidents_proportion_data'>
            <ParkingTime type='incidentsProportion' seriesName='片区巡检事件占比'/>
        </div>
        return <ChartCard chartName='片区巡检事件占比' style='incidents_proportion' children={element}/>
    }

    // 今日完成派单Top5巡检员
    completedInspectorsTop5 = () => {
        const element = <div className='completed_inspectors_data'>
            <ParkingTimeTop5 type='completedInspectorsTop5'/>
        </div>
        return <ChartCard chartName='今日完成派单Top5巡检员' style='completed_inspectors' children={element}/>
    }

    // 巡检事件类型占比
    patrolEventTypes = () => {
        const element = <div className='patrol_event_types_data'>
            <PaymentMethod type='patrolEventType'/>
        </div>
        return <ChartCard chartName='巡检事件类型占比' style='patrol_event_types' children={element}/>
    }

    // 本月巡检员出勤次数Top10
    attendanceTimesTop10 = () => {
        const element = <div className='attendance_times_data'>
            <AttendanceTimesTop10 type='attendanceTimes'/>
        </div>
        return <ChartCard chartName='本月巡检事件Top10路段' style='attendance_times' children={element}/>
    }

    // 本月派单完成率
    orderCompletionRate = () => {
        const element = <div className='order_completion_rate_data'>
            <NumberOfStop type='orderCompletionRate'/>
        </div>
        return <ChartCard chartName='本月派单完成率' style='order_completion_rate' children={element}/>
    }
    
    render() {
        return (
            <div className='inspection_monitoring'>
                {/* 左侧四张图表 */}
                <div className='inspection_monitoring_left'>
                    {/* {this.resourceProfile()} */}
                    <InspectionIndicators type='resourceProfile' />
                    {this.inspectorsProportion()}
                    {this.incidentsProportion()}
                    {/* {this.completedInspectorsTop5()} */}
                    <ViolationTimes type='violation'/>
                </div>
                {/* 中间地图及底部图表 */}
                <div className='inspection_monitoring_middle'>
                    {/* 地图 */}
                    <div className='inspection_monitoring_map'>
                        <InspectionMap />
                    </div>
                    <div>
                        <InspectionIndicators type='indicator'/>
                        {/* 底部图表 */}
                        <div>
                            {this.orderCompletionRate()}
                        </div>
                    </div>
                </div>
                {/* 右侧三张图表 */}
                <div className='inspection_monitoring_right'>
                    {this.patrolEventTypes()}
                    {this.attendanceTimesTop10()}
                    <InspectionEvent />
                </div>
            </div>
        )
    }
}

export default InspectionMonitoring

// 巡检事件
function InspectionEvent(){

    const [inspectionEvents,setInspectionEvents] = useState<Array<InspectionEvents>>(null)
    let timer:NodeJS.Timeout | number

    useEffect(() => {
        getInspectionEvents()
        PubSub.subscribe('mapParking',(msg: string, info:any)=>{
            getInspectionEvents(info.adcode)
        })
        timer = setInterval(()=>{
            getInspectionEvents()
        },60*1000 )
        return () => {
            clearInterval(Number(timer))
        }
    }, [])

    // 获取巡检事件数据
    const getInspectionEvents = (code?:string) => {
        const cityId = code || localStorage.getItem('cityCode') || '440300'
        CityModel.inspectionEvents({cityId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setInspectionEvents(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取巡检事件数据失败')
            }
        })
    }
    const events = {3:'违停处理',4:'未交费拍照',6:'设备维保',15:'辅助入场',16:'辅助离场'}
    // case 1: color='#F7B500';break
    // case 2: color='#FF719C';break
    // case 3: color='#966BFF';break
    const params = {
        direction : 'vertical' as 'vertical',
        slidesPerView : 8,
        autoplay: { delay: 1000, disableOnInteraction: false },
        loop: true,
    }
    const element = <div className='inspection_events_data'>
        <div className={"common_table_header_box"}>
            <div className={"common_table_header_title"} style={{width:'30%'}}>巡检员</div>
            <div className={"common_table_header_title"} style={{width:'20%'}}>泊位号</div>
            <div className={"common_table_header_title"} style={{width:'20%'}}>事件</div>
            <div className={"common_table_header_title"} style={{width:'30%'}}>时间</div>
        </div> 
        <Swiper {...params} className='inspection_envents'>
            {(inspectionEvents||[]).map((item,index)=>{
                return <SwiperSlide key={index} className={index % 2 === 0  ? "common_table_body_item_box box_color_light" : "common_table_body_item_box box_color_dark"}>
                    <div className={"common_table_body_item"} style={{width:'30%'}}>{item.adminUserName}</div>
                    <div className={"common_table_body_item"} style={{width:'20%'}}>{item.parkingSpaceNo}</div>
                    <div className={"common_table_body_item"} style={{width:'20%'}}>{events[item.warningType] || '--'}</div>
                    <div className={"common_table_body_item"} style={{width:'30%'}}>{moment(item.disposeTime).format('YYYY-MM-DD HH:mm:ss')||'--'}</div>
                </SwiperSlide>
            })}
        </Swiper>     
        {/* { data&&data.map((item,index)=><div key={index} className='inspection_event'>
            <div className='inspection_event_number' style={{backgroundColor:item.numberColor}}>{index+1}</div>
            <div>{item.event}</div>
        </div>) } */}
    </div>
    return <ChartCard chartName='巡检事件' style='inspection_events' children={element}/>
}

// 数据指标
function InspectionIndicators(props:{type:'indicator'|'resourceProfile'}){
    const [resourceProfile,setResourceProfile] = useState<ResourceProfile|null>(null)
    let timer:NodeJS.Timeout | number
    useEffect(() => {
        getResourceProfile()
        PubSub.subscribe('mapParking',(msg: string, info:any)=>{
            getResourceProfile(info.adcode)
        })
        timer = setInterval(()=>{
            getResourceProfile()
        }, 5*1000 )
        return () => {
            clearInterval(Number(timer))
        }
    },[])
    const getResourceProfile = (code?:string) => {
        const cityId = code || localStorage.getItem('cityCode') || '440300'
        CityModel.resourceProfile({cityId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setResourceProfile(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日城市概况失败')
            }
        })
    }
    const {inspectionMemberActiveTotal,parkWarnTotal,parkWarnDisposedTotal} = resourceProfile || {}
    if(props.type=='indicator'){
        const data = [
            {field:'违停次数',value:parkWarnTotal,icon:dataIcon3},
            {field:'在线巡检人数',value:inspectionMemberActiveTotal||'0',icon:dataIcon1},
            {field:'巡检派单数',value:parkWarnDisposedTotal||'0',icon:dataIcon2},
        ]
        return <div className='inspection_indicators'>
            {data.map((item,index)=><div key={index}>
                <img src={item.icon} alt=""/>
                <div>
                    <div className='inspectionIndicators_amount' style={{color:['#32C5FF','#966BFF','#36CFC9',][index]}}>{item.value}</div>
                    <div className='inspectionIndicators_field'>{item.field}</div>
                </div>
            </div>)}
        </div>
    }
    const data = [
        {field:'片区数量', value: resourceProfile&&resourceProfile.subAreaTotal||0},
        {field:'中队数量', value: resourceProfile&&resourceProfile.inspectionGroupTotal||0},
        {field:'巡检员数量', value: resourceProfile&&resourceProfile.inspectionGroupMemberTotal||0},
        {field:'PDA设备数量', value: resourceProfile&&resourceProfile.pdaTotal||0} 
    ]
    const element = (
        <div className='resource_profile_data'>
            {data.map( (item,index) => <div className='chart_data_item' key={index}>
                <div className='item_value'>{item.value}</div>
                <div className='item_field'>{item.field}</div>
            </div> ) }
        </div>
    )
    return <ChartCard chartName='资源概况' style='resource_profile' children={element}/>
}
