import { HttpClientImmidIot } from "./request/HttpClientImmidIot"
import { callback } from './model'

// 区级
class AreaModel {

    // 今日区域概况
    static cityProfile(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/overview'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
    
    // 今日停车概况
    static parkingProfile(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/space/overview'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 停车时长分布
    static parkingTime(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/duration'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 支付方式分布
    static paymentMethods(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/payRource'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 营收分析
    static revenueAnalysis(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/incomeAnalysis'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 停车场营收分析
    static revenueCapability(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/common'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 实时车流
    static trafficFlow(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/intOutTimes'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日用户概况
    static userInfo(param: {}, callback: callback){
        const url = '/parking-report/member/report/getTodayInfo'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 各区泊位占比
    static berthsProportion(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/space/parkingSpaceRateByArea'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月停车时长top5泊位
    static parkingTimeTop5ThisMonth(param: {areaId?: string, limit?:number, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/space/durationMonthTopParking'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日停车时长top5路段
    static parkingTimeTop5Today(param: {areaId?: string, limit?:number, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/space/durationTodayTopParking'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月停车时长top5路段（每天）
    static parkingTimeTop5ThisMonthRoad(param: {areaId?: string, limit?:number, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/space/durationMonthTopParking/date'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 违停次数统计|今日泊位利用率
    static violationTimes(param: {areaId?: string, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/common/today'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月泊位利用率
    static berthThisMonth(param: {areaId?: string, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/common/month'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 巡检监控-资源概况
    static resourceProfile(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/overview'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 片区巡检员占比
    static inspectorsProportion(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/subarea/inspectionMember/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 片区巡检事件占比
    static incidentsProportion(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/subarea/parkWarn/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日完成派单Top5巡检员
    static completedInspectorsTop5(param: {areaId?: string, startTime: string, endTime: string}, callback: callback){
        const url = '/parking-report/data/parkWarn/parkwarnTopDisposer'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月派单完成率
    static orderCompletionRate(param: {areaId?: string, statisticsTime?: Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/parkWarningDispose/date'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 巡检事件类型占比
    static patrolEventTypes(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/parkWarnType/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月巡检员出勤次数Top10
    static attendanceTimesTop10(param: {areaId?: string, statisticsTime?: Date, limit?: number}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/inspectionMember/sign/top'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 热点停车区域
    static parkingArea(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/parkingHotPointWarning'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

}

export default AreaModel