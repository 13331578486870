import { HttpClientImmidIot } from "./request/HttpClientImmidIot"
import { callback } from './model'

// 路外停车
class outRoadParkingDetailModelRequest {
    //单个停车场-停车场信息
    static parkingDetailInfo(param: {parkingId?:number}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/parking/summary';
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //单个停车场---停车次数
    static parkingCount(param: {parkingId?:number}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/parking/times/amount';
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }


    //单个停车场-车流统计
    static parkingFlow(param: {parkingId?:number}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/parking/inOut/times';
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }


    //单个停车场-营收分析
    static detailIncomeComparison(param: {parkingId?:number}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/parking/income/comparison';
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }


    //单个停车场-车位利用率
    static detailParkingUseRate(param: {parkingId?:number}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/parking/use/rate';
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //单个停车场-支付方式
    static detailParkingPayType(param: {parkingId?:number}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/parking/payType/rate';
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //单个停车场-实时停车
    static detailParkingTopRecord(param: {parkingId?:number}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/parking/top/record';
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
}

export default outRoadParkingDetailModelRequest
