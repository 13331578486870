import echarts from "echarts"
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import React, { PureComponent } from 'react'
import CityModel from '../../../model/cityModel'
import { CompletedInspectors, ParkingTimeTop5ThisMonth } from '../../../model/model'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import PubSub from 'pubsub-js'

interface Props{
    type: 'parkingTimeTop5ThisMonth' | 'parkingTimeTop5Today' | 'completedInspectorsTop5'
}
interface State{
    parkingTimeTop5ThisMonth?:Array<ParkingTimeTop5ThisMonth>,
    parkingTimeTop5Today?:Array<ParkingTimeTop5ThisMonth>,
    completedInspectors?:Array<CompletedInspectors>
}
export class ParkingTimeTop5 extends PureComponent<Props,State> {

    constructor(props:Props){
        super(props)
        this.state = {
            parkingTimeTop5ThisMonth: null,
            parkingTimeTop5Today: null,
            completedInspectors: [
                {"times": 0,"userId": 0,"userName": ""}
            ]
        }
    }

    componentDidMount(){
        switch (this.props.type) {
            case 'parkingTimeTop5ThisMonth':
                this.getParkingTimeTop5ThisMonth()
                PubSub.subscribe('mapParking',(msg: string, info:any)=>{
                    this.getParkingTimeTop5ThisMonth(info.adcode)
                })
                break;
            case 'parkingTimeTop5Today':
                this.getParkingTimeTop5Today()
                PubSub.subscribe('mapParking',(msg: string, info:any)=>{
                    this.getParkingTimeTop5Today(info.adcode)
                })
                break;
            case 'completedInspectorsTop5':
                this.getCompletedInspectorsTop5()
                PubSub.subscribe('mapParking',(msg: string, info:any)=>{
                    this.getCompletedInspectorsTop5(info.adcode)
                })
                break;
            default:
                break;
        }
    }

    componentWillUnmount = () => {
        clearInterval()
        PubSub.unsubscribe('mapParking');
    }


    // 获取本月停车时长top5泊位数据
    getParkingTimeTop5ThisMonth = (cityId?:string) => {
        CityModel.parkingTimeTop5ThisMonth({cityId: cityId || localStorage.getItem('cityCode') || '440300',limit:5},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({parkingTimeTop5ThisMonth:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取本月停车时长top5泊位数据失败')
            }
        })
    }

    // 获取今日停车时长top5路段数据
    getParkingTimeTop5Today = (cityId?:string) => {
        CityModel.parkingTimeTop5Today({cityId: cityId || localStorage.getItem('cityCode') || '440300',limit:5},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({parkingTimeTop5Today:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日停车时长top5路段数据失败')
            }
        })
    }

    // 获取今日完成派单Top5巡检员数据
    getCompletedInspectorsTop5 = (cityId?:string) => {
        CityModel.completedInspectorsTop5({cityId: cityId || localStorage.getItem('cityCode') || '440300', startTime: moment().format('YYYY-MM-DD'), endTime:  moment().format('YYYY-MM-DD')},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({completedInspectors:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日完成派单Top5巡检员数据失败')
            }
        })
    }

    getEChartsData = () => {
        let echartsData: { value: number; name: string }[] = []
        switch (this.props.type) {
            case 'parkingTimeTop5ThisMonth':
                const {parkingTimeTop5ThisMonth} = this.state
                echartsData = (parkingTimeTop5ThisMonth || []).map((item,index)=>{
                    return {
                        value: item.parkDuration || 1,
                        name: item.parkingName,
                        // itemStyle: {
                        //     color: colors[index],
                        // }
                    }
                })
                break;
            case 'parkingTimeTop5Today':
                const {parkingTimeTop5Today} = this.state
                echartsData = (parkingTimeTop5Today || []).map((item,index)=>{
                    return {
                        value: item.parkDuration || 1,
                        name: item.parkingName,
                    }
                })
            case 'completedInspectorsTop5':
                const {completedInspectors} = this.state
                echartsData = (completedInspectors || []).map((item,index)=>{
                    return {
                        value: item.times || 1,
                        name: item.userName,
                    }
                })
                break;
            default:
                break;
        }
        return echartsData
    }

    getOptions = () => {
        const echartsData = this.getEChartsData()
        return {
            tooltip: {
                show: true,
                formatter: '{b} : {c}'
            },
            legend: {
                show: false
            },
            grid: {
                left: 80,
                // right: 10,
                top: 10,
                bottom: 10
            },
            
            xAxis: {
                type: 'value' as 'value',
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                }
        
            },
            yAxis: {
                type: 'category' as 'category',
                inverse: true,
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#fff',
                    }
                },
                axisTick: {
                    show: false
                },
                axisPointer: {
                    label: {
                        show: true,
                        margin: 30
                    }
                },
                data: echartsData.map(e=>e.name),
            },
            series: [{
                    z: 2,
                    name: 'value',
                    type: 'bar' as 'bar',
                    barWidth: '15',
                    data: echartsData.map(e=>e.value).map((item, i) => {
                        return {
                            value: item,
                            itemStyle: {
                                barBorderRadius: 8,
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: '#52C9FF'
                                }, {
                                    offset: 1,
                                    color: '#1163F0'
                                }]),
                            }
                        };
                    }),
                    label: {
                        show: true,
                        position: 'right',
                        color: '#fff',
                        fontSize: 12,
                        offset: [0, 0]
                    }
                }
        
            ]
        }
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default ParkingTimeTop5
