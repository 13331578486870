import classNames from 'classnames'
import PubSub from 'pubsub-js'
import React, { PureComponent, useEffect, useState } from 'react'
import { HttpClientImmidIot } from '../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../model/SystemFunction'
import { ViolationTimes } from '../berthMonitoring/berthMonitoring'
import { CityProfile, UserInfo } from './../../model/model'
import numberOfStopIcon from './../../static/images/statistics/停车次数icon@2x.png'
import newUsers from './../../static/images/statistics/新增用户icon@2x.png'
import chartTitleIcon from './../../static/images/statistics/标题icon@2x.png'
import oweAmountIcon from './../../static/images/statistics/欠费金额icon@2x.png'
import paymentAmountIcon from './../../static/images/statistics/缴费金额icon@2x.png'
import BerthSurvey from './charts/berthSurvey'
import HotSpotParkingArea from './charts/hotSpotParkingArea'
import NumberOfStop from './charts/numberOfStop'
import ParkingTime from './charts/parkingTime'
import PaymentMethod from './charts/paymentMethod'
import RevenueAnalysis from './charts/revenueAnalysis'
import RevenueCapability from './charts/revenueCapability'
import TrafficFlow from './charts/trafficFlow'
import ParkingMap from './map/parkingMap'
import './statistics.css'

export class Statistics extends PureComponent<any,any> {

    constructor(props:any){
        super(props)
    }

    componentDidMount = () => {
        // PubSub.publish('mapParking',{level:'top',adcode: localStorage.getItem('cityCode') || '440300'})
    }

    /* 泊位概况 */
    berthSurvey = () => {
        const element = <div className='berth_survey_data'>
            <BerthSurvey type='berthSurvey'/>
        </div>
        return <ChartCard chartName='泊位概况' style='berth_survey' children={element}/>
    }
    

    /* 停车时长分布  */
    parkingTime = () => {
        const element = <div className='parking_time_data'>
            <ParkingTime type='parkingTimeDistribution' seriesName='停车时长分布'/>
        </div>
        return <ChartCard chartName='停车时长分布' style='parking_time' children={element}/>
    }

    /* 支付方式分布  */
    paymentMethod = () => {
        const element = <div className='payment_method_data'>
            <PaymentMethod type='paymentMethodsData'/>
        </div>
        return <ChartCard chartName='支付方式分布' style='payment_method' children={element}/>
    }

    // 营收能力分析
    revenueCapability = () => {
        const element = <div className='revenue_capability_data'>
            <RevenueCapability type='revenue'/>
        </div>
        return <ChartCard chartName='营收能力分析' style='revenue_capability' children={element}/>
    }

    // 周转能力分析
    turnoverCapacity = () => {
        const element = <div className='revenue_capability_data'>
            <RevenueCapability type='turnover'/>
        </div>
        return <ChartCard chartName='周转能力分析' style='revenue_capability' children={element}/>
    }

    // 时段停车次数
    numberOfStop = () => {
        const element = <div className='number_of_stop_data'>
            <NumberOfStop type='trafficFlow'/>
        </div>
        return <ChartCard chartName='高峰停车时段统计' style='number_of_stop' children={element}/>
    } 

    // 车流：统计
    trafficFlow = () => {
        const element = <div className='traffic_flow_data'>
            <TrafficFlow />
        </div>
        return <ChartCard chartName='今日车流量统计' style='traffic_flow' children={element}/>
    }

    // 热点停车区域
    parkingArea = () => {
        const element = <div className='parking_area_data'>
            <HotSpotParkingArea type='statistics'/>
        </div>
        return <ChartCard chartName='热点停车区域' style='' children={element}/>
    }

    // 营收分析
    revenueAnalysis = () => {
        const element = <div className='revenue_analysis_data'>
            <RevenueAnalysis />
        </div>
        return <ChartCard chartName='营收分析' style='revenue_analysis' children={element}/>
    }

    render() {
        return ( 
            <div className='display_main'>
                {/* 左侧四张图表 */}
                <div className='display_charts_left'>
                    <UrbanResources type='top'/>
                    {this.berthSurvey()}
                    {this.parkingTime()}
                    {this.paymentMethod()}
                </div>
                {/* 中间地图及底部图表 */ }
                <div className='display_charts_middle'>
                    <div className='display_map'>
                        <ParkingMap />
                    </div>
                    <UrbanResources type='user'/>
                    <div className='display_charts_bottom'>
                        {this.parkingArea()}
                        {this.revenueAnalysis()}
                    </div>
                </div>
                {/* 右侧四张图表 */}
                <div className='display_charts_right'>
                    {/* {this.revenueCapability()} */}
                    <ViolationTimes type={'berthThisMonth'}/>
                    {this.turnoverCapacity()}
                    {this.numberOfStop()}
                    {this.trafficFlow()}
                </div>
            </div>
        )
    }
}

export default Statistics




// 统计图背景样式
interface chartCardProps{
    style: string,
    chartName: string,
    children: JSX.Element
}
export function ChartCard(props:chartCardProps){
    return <div className={classNames('chart_bg_img',props.style)}>
        <div className='chart_title'><img src={chartTitleIcon} alt=""/>{props.chartName}</div>
        {props.children}
    </div>
}

export interface MapParking{
    level: 'top' | 'second',
    adcode: string,
    areaName?: string
}
/* 城市资源 */
function UrbanResources(props:{type:'top'|'user'}){
    const [cityProfile,setCityProfile] = useState<CityProfile|null>(null)
    const [userInfo, setUserInfo] = useState<UserInfo|null>(null)
    const [chartName, setChartName] = useState('城市资源')
    let timer:NodeJS.Timeout | number
    useEffect(() => {
        getUrbanResources({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
        getUserInf({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
        timer = setInterval(()=>{
            getUrbanResources({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
            getUserInf({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
        }, 5*1000 )
        PubSub.subscribe('mapParking',(msg: string, info: MapParking)=>{
            if(info.level=='second'){
                setChartName('行政区资源')
            }else{
                setChartName('城市资源')
            }
            if(timer){
                clearInterval(Number(timer))
            }
            getUrbanResources(info)
            getUserInf(info)
            timer = setInterval(()=>{
                getUrbanResources(info)
                getUserInf(info)
            }, 5*1000 )
        })
        return () => {
            clearInterval(Number(timer))
            PubSub.unsubscribe('mapParking');
        }
    }, [])

    // 获取今日城市概况
    const getUrbanResources = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.cityProfile({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setCityProfile(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日城市概况失败')
            }
        })
    }

    // 今日用户概况
    const getUserInf = (info: MapParking) => {
        MapModel[info.level].userInfo({},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setUserInfo(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日用户概况失败')
            }
        })
    }

    const {parkingSpaceTotal,parkingTotalCount,todayParkingTimesTotal,todayParkingIncome,todayArrearageAmount} = cityProfile || {}
    const {newUserCount} = userInfo || {} 

    const data = [
        {field:'车场总数', value: parkingTotalCount&&parkingTotalCount.toLocaleString()},
        {field:'泊位总数', value: parkingSpaceTotal&&parkingSpaceTotal.toLocaleString()},
        // {field:'ETC视频桩总数', value: '0'},
        // {field:'高位视频总数', value: '0'} 
    ]
    const element = (
        <div className='resources_data'>
            {data.map( (item,index) => <div className='chart_data_item' key={index}>
                <div className='item_value'>{item.value}</div>
                <div className='item_field'>{item.field}</div>
            </div> ) }
        </div>
    )
    switch (props.type) {
        case 'top':
            return <ChartCard chartName={chartName} style='urban_resources' children={element}/>
        case 'user':
            return <div className='display_key_indicators'>
            {[
                {field:'停车次数',value:todayParkingTimesTotal||'0',icon:numberOfStopIcon},
                {field:'缴费金额',value:todayParkingIncome||'0',icon:paymentAmountIcon},
                {field:'欠费金额',value:todayArrearageAmount||'0',icon:oweAmountIcon},
                {field:'新增用户',value:newUserCount||'0',icon:newUsers}
            ].map((item,index) => <div key={index}>
                <img src={item.icon} alt=""/>
                <div>
                    <div className='keyIndicators_amount' style={{color:['#966BFF','#36CFC9','#FF7F5B','#32C5FF'][index]}}>{item.value}</div>
                    <div className='keyIndicators_field'>{item.field}</div>
                </div>
            </div>)}
        </div>
        default: return <></>
    }
    
}
