import echarts from "echarts"
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import React, { PureComponent } from 'react'
import CityModel from '../../../model/cityModel'
import { EquipmentTrend, EquipmentWorkingStatus, HourlyOccupancyRate, MonthIncomes, OrderCompletionRate, Traffic, ViolationTimes } from '../../../model/model'
import ParkingModel from '../../../model/parkingModel'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../../model/SystemFunction'
import { MapParking } from '../statistics'
import {useRate} from "../../../model/outRoadParkingDetailModelInterface";
import {use_rate} from "../../../model/outRoadParkingDetailInitialData";
import outRoadParkingDetailModelRequest from "../../../model/outRoadParkingDetailModelRequest";

interface Props{
    type?: 'violation'|'berthToday'|'berthThisMonth'|'trafficFlow'|'orderCompletionRate'|'turnoverAnalysis'|'hourlyOccupancyRate'|'equipmentTrend'|'equipmentWorkingStatus' | "detailUseRatio"
}
interface State{
    trafficFlow?: Array<Traffic>,
    violationTimes?: Array<ViolationTimes>,
    berthThisMonth?: Array<ViolationTimes>,
    orderCompletionRate?: Array<OrderCompletionRate>,
    revenueAnalysis?: {
        curMonthIncomes: Array<MonthIncomes>,
        lastMonthIncomes: Array<MonthIncomes>
    },
    hourlyOccupancyRate?: Array<HourlyOccupancyRate>,
    equipmentDeploymentTrend?: Array<EquipmentTrend>,
    equipmentWorkingStatus?: Array<EquipmentWorkingStatus>,
    detailUseRatio?:Array<useRate>
}
export class NumberOfStop extends PureComponent<Props,State> {
    timer: NodeJS.Timeout | number
    constructor(props:any){
        super(props)
        this.state = {
            trafficFlow: null,
            violationTimes: null,
            berthThisMonth: null,
            orderCompletionRate: null,
            revenueAnalysis: null,
            hourlyOccupancyRate: null,
            equipmentDeploymentTrend: null,
            equipmentWorkingStatus: null,
            detailUseRatio:use_rate
        }
    }

    componentDidMount = () => {
        switch (this.props.type) {
            case 'trafficFlow':
                this.getRevenueData({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                this.timer = setInterval(()=>{
                    this.getRevenueData({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getRevenueData(info)
                    this.timer = setInterval(()=>{
                        this.getRevenueData(info)
                    }, 60*1000 )
                })
                break;
            case 'violation':
            case 'berthToday':
                this.getViolationTimes({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                this.timer = setInterval(()=>{
                    this.getViolationTimes({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getViolationTimes(info)
                    this.timer = setInterval(()=>{
                        this.getViolationTimes(info)
                    }, 60*1000 )
                })
                break;
            case 'berthThisMonth':
                this.getBerthThisMonth({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                this.timer = setInterval(()=>{
                    this.getBerthThisMonth({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getBerthThisMonth(info)
                    this.timer = setInterval(()=>{
                        this.getBerthThisMonth(info)
                    }, 60*1000 )
                })
                break;
            case 'orderCompletionRate':
                this.getOrderCompletionRate({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                this.timer = setInterval(()=>{
                    this.getOrderCompletionRate({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getOrderCompletionRate(info)
                    this.timer = setInterval(()=>{
                        this.getOrderCompletionRate(info)
                    }, 60*1000 )
                })
                break;
            case 'turnoverAnalysis':
                // 停车场周转率分析
                this.getRevenueAnalysis()
                this.timer = setInterval(()=>{
                    this.getRevenueAnalysis()
                }, 60*1000 )
            case 'hourlyOccupancyRate':
                this.getHourlyOccupancyRate()
                this.timer = setInterval(()=>{
                    this.getHourlyOccupancyRate()
                }, 60*1000 )
                break;
            case 'equipmentTrend':
                this.getEquipmentTrend()
                this.timer = setInterval(()=>{
                    this.getEquipmentTrend()
                }, 60*1000 )
                break;
            case 'equipmentWorkingStatus':
                this.getEquipmentWorkingStatus()
                this.timer = setInterval(()=>{
                    this.getEquipmentTrend()
                }, 60*1000 )
                break;
            case 'detailUseRatio':
                this.getUseRate()
                this.timer = setInterval(()=>{
                    this.getUseRate()
                }, 60*1000 )
                break;
            default:
                break;
        }
    }

    componentWillUnmount = () => {
        PubSub.unsubscribe('mapParking');
        clearInterval(Number(this.timer))
    }

    // 获取每小时占用率数据
    getHourlyOccupancyRate = () => {
        const parkingId = localStorage.getItem('parkingId')
        ParkingModel.hourlyOccupancyRate({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({hourlyOccupancyRate:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取每小时占用率数据失败')
            }
        })
    }

    // 获取营收分析数据
    getRevenueAnalysis = () => {
        const parkingId = localStorage.getItem('parkingId')
        ParkingModel.revenueAnalysis({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({revenueAnalysis:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取营收分析数据失败')
            }
        })
    }

    // 获取车流统计数据
    getRevenueData = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.trafficFlow({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({trafficFlow:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取区域营收分析数据失败')
            }
        })
    }

    // 违停次数统计|今日泊位利用率
    getViolationTimes = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.trafficFlow({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({violationTimes:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取区域营收分析数据失败')
            }
        })
    }

    // 本月泊位利用率
    getBerthThisMonth = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.berthThisMonth({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({berthThisMonth:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取区域营收分析数据失败')
            }
        })
    }

    // 本月派单完成率
    getOrderCompletionRate = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.orderCompletionRate({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({orderCompletionRate:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取区域营收分析数据失败')
            }
        })
    }

    // 设备部署趋势（最近一年12个月）
    getEquipmentTrend = () => {
        CityModel.equipmentDeploymentTrend({timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    this.setState({equipmentDeploymentTrend: d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取设备部署趋势失败')
            }
        })
    }

    // 设备状态月统计（过去30天数据）
    getEquipmentWorkingStatus = () => {
        CityModel.equipmentWorkingStatus({timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    this.setState({equipmentWorkingStatus: d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('设备状态月统计（过去30天数据）')
            }
        })
    };



    //右3 路外停车场详情-车位利用率分析
    getUseRate =()=>{
        const parkingId = localStorage.getItem('DETAIL_PARKING_ID');
        outRoadParkingDetailModelRequest.detailParkingUseRate({parkingId:parseInt(parkingId)},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.success && d.data.length > 0){
                    this.setState({detailUseRatio: d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取车位利用率分析失败')
            }
        })
    };



    getEChartsData = () => {
        let xAxisData: string[]=[],yAxisData: any[]=[], name = ['']
        const {trafficFlow,violationTimes,berthThisMonth,orderCompletionRate,revenueAnalysis,hourlyOccupancyRate,equipmentDeploymentTrend,equipmentWorkingStatus,detailUseRatio} = this.state
        switch (this.props.type) {
            case 'trafficFlow':
                if(trafficFlow){
                    name = ['停车次数']
                    xAxisData = trafficFlow.map(item=>moment(item.statisticsTime).format('LT'))
                    yAxisData = [trafficFlow.map(item=>item.outTimes)]
                }
                break;
            case 'detailUseRatio':
                if(detailUseRatio){
                    name = ['车位利用率'];
                    xAxisData = detailUseRatio.map(item=>new Date(item.statisticsTime).getDate()+'日')
                    yAxisData = [detailUseRatio.map(item=>item.rate)]
                }
                break;
            case 'violation':
                if(violationTimes){
                    name = ['违停次数']
                    xAxisData = violationTimes.map(item=>moment(item.statisticsTime).format('LT'))
                    yAxisData = [violationTimes.map(item=>item.parkWarnTimes)]
                }
                break;
            case 'berthToday':
                if(violationTimes){
                    name = ['泊位利用率']
                    xAxisData = violationTimes.map(item=>moment(item.statisticsTime).format('LT'))
                    yAxisData = [violationTimes.map(item=>item.turnover)]
                }
                break;
            case 'berthThisMonth':
                if(berthThisMonth){
                    name = ['泊位利用率']
                    xAxisData = berthThisMonth.map(item=>moment(item.statisticsTime).format('MM-DD'))
                    yAxisData = [berthThisMonth.map(item=>item.turnover)]
                }
                break;
            case 'orderCompletionRate':
                if(orderCompletionRate){
                    name = ['派单完成率']
                    xAxisData = orderCompletionRate.map(item=>new Date(item.statisticsTime).getDate()+'日')
                    yAxisData = [orderCompletionRate.map(item=>item.rate)]
                };
                break;
            case 'turnoverAnalysis':
                if(revenueAnalysis){
                    name = ['周转率']
                    xAxisData = revenueAnalysis.curMonthIncomes.map(item=>new Date(item.statisticsTime).getDate()+'日')
                    yAxisData = [revenueAnalysis.curMonthIncomes.map(item=>item.turnover)]
                }
                break
            case 'hourlyOccupancyRate':
                if(hourlyOccupancyRate){
                    name = ['占用率']
                    xAxisData = hourlyOccupancyRate.map(item=>moment(item.statisticsTime).format('LT'))
                    yAxisData = [hourlyOccupancyRate.map(item=>item.spaceUsageRate)]
                }
                break
            case 'equipmentTrend':
                if(equipmentDeploymentTrend){
                    name = ['设备部署趋势']
                    xAxisData = equipmentDeploymentTrend.map(item=>item.month)
                    yAxisData = [equipmentDeploymentTrend.map(item=>item.quantity)]
                }
                break;
            case 'equipmentWorkingStatus':
                if(equipmentWorkingStatus){
                    name = ['在线数量','离线数量','故障数量','未知数量']
                    xAxisData = equipmentWorkingStatus.map(item=>{for(let key in item){return key.slice(-4,-2)+'-'+key.slice(-2)}})
                    yAxisData = [
                        equipmentWorkingStatus.map(item=>{for(let key in item){return item[key].online}}),
                        equipmentWorkingStatus.map(item=>{for(let key in item){return item[key].offline}}),
                        equipmentWorkingStatus.map(item=>{for(let key in item){return item[key].malfunc}}),
                        equipmentWorkingStatus.map(item=>{for(let key in item){return item[key].unknown}}),
                    ]
                }
                break;
            default:
                break;
        }
        return {
            name,
            xAxisData,
            yAxisData
        }
    }

    getOptions = () => {
        let xAxisData = ["1日","3日","5日","7日","9日","11日","13日"],
            yAxisData = [[0,0,0,0,0,0,0]],
            name = ['时段停车次数'],
            colors = ['#34A9FF','#04C8C1','#966BFF','#4876E7']
        let echartsData = this.getEChartsData()
        xAxisData = echartsData.xAxisData
        yAxisData = echartsData.yAxisData
        name = echartsData.name
        return {
            tooltip: {
                trigger: 'axis' as 'axis',
                axisPointer: {
                    lineStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(0, 255, 233,0)'
                        }, {
                            offset: 0.5,
                            color: 'rgba(255, 255, 255,1)',
                        }, {
                            offset: 1,
                            color: 'rgba(0, 255, 233,0)'
                        }], false),
                    },
                }
            },
            grid: {
                top: '15',
                left: '25',
                right: '15',
                bottom: '15%',
            },
            xAxis: [{
                type: 'category' as 'category',
                axisLine: {
                    show: false,
                    color:'#32C5FF'
                },
                axisLabel: {
                    color: '#35A1F0',
                    // width:100,
                    rotate: this.props.type=='hourlyOccupancyRate'&&-40
                },
                splitLine: {
                    show: false
                },
                boundaryGap: false,
                data: xAxisData//this.$moment(data.times).format("HH-mm") ,
            }],

            yAxis: [{
                type: 'value' as 'value',
                min: 0,
                // max: 140,
                splitNumber: 4,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#35A1F0',
                        opacity:0.23
                    }
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    show: true,
                    margin: 5,
                    textStyle: {
                        color: '#35A1F0',

                    },
                },
                axisTick: {
                    show: false,
                },
            }],
            series: yAxisData.map((item,index) => {
                return {
                    name: name[index],
                    type: 'line' as 'line',
                    symbolSize: 7,
                    symbol: 'circle',
                    // showSymbol: false, // 鼠标移入才显示圆点
                    lineStyle: {
                        normal: {
                            color: colors[index],
                        },
                    },
                    itemStyle: {
                        color: colors[index],
                        borderWidth: 0,
                    },
                    areaStyle: {
                        normal: {
                            color: ['rgba(0,55,177,0.3)','rgba(50,197,255,0)']
                        }
                    },
                    data: item
                }
            })
        }
    }

    render() {
        // @ts-ignore
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default NumberOfStop
