import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import CityModel from '../../../model/cityModel'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { ChargingRate } from '../../../model/outRoadModelInterface'
import { charging_rate} from '../../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../../model/outRoadModelRequest'

interface State{
    chargingRate?: Array<ChargingRate>
}
export class ChargingRateStatistics extends PureComponent<any,State> {

    constructor(props:any){
        super(props)
        this.state = {
            chargingRate: []
        }
    }

    componentDidMount(){
        this.getChargingRate()
        
    }

    // 各街道充电桩车场占比统计
    getChargingRate = (cityId?:string) => {
        OutRoadModelRequest.chargingRate(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({chargingRate:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取各街道充电桩车场占比统计失败')
            }
        })
    }

    getEChartsData = () => {
        const {chargingRate} = this.state
        const xData = chargingRate?chargingRate.map(item => {
            return item.areaName
        }):[]
       //  const colors = ['#E4825B','#43D8A0']
       //  const  series=['非充电桩车场','充电桩车场'].map((item,index) =>{
       //     return {
       //          name:item,
       //          color: colors[index],
       //          data:item=="非充电桩车场"?
       //     }
       // })
      
       //  const series =  chargingRate.map((item,index)=>{
       //      return {
       //          name:item.areaName,
       //          color: colors[index],
       //          data
       //      }
       //  })
        const echartsData = {
            xData,
        }
        return echartsData
    }
   
    getOptions = () => {
        const echartsData = this.getEChartsData()
        const {chargingRate} = this.state
        const colors = ['#43D8A0','#E4825B']
        return {
            color: colors,
            // backgroundColor:'#fff',
            legend: {
                data: [ '充电桩车场','非充电桩车场'],
                icon: 'circle',
                top: 0,
                itemWidth: 8,
                itemHeight: 8,
                textStyle: {
                    color: '#fff'
                },
            },
            tooltip: {
                trigger: 'axis' as 'axis',
                formatter: '{a1}: {c1}%<br />{a0}: {c0}%'
            },
            grid: {
                left: '0',
                right: '0%',
                bottom: '0%',
                top: '20t%',
                containLabel: true
            },
            
            xAxis :{
                type : 'category' as 'category',
                data : echartsData.xData,
                axisLabel:{          //坐标轴字体颜色
                    color: '#FFFFFF'
                },
                axisLine:{
                    lineStyle:{
                        color:"#e5e5e5"
                    }
                }, 
                axisTick:{       //y轴刻度线
                  show:false
                },
                splitLine:{    //网格
                    show: false,
                }
            },
            yAxis :{
                type : 'value' as 'value',
                axisLabel:{        //坐标轴字体颜色
                    color: '#35A1F0',
                    formatter:'{value}%'
                },
                splitNumber: 5,
                max:100,
                axisLine:{
                    show:false,
                },
                axisTick:{       //y轴刻度线
                  show:false
                },
                splitLine:{    //网格
                    show: true,
                    lineStyle:{
                        color:'#35A1F0',
                        // type:"dashed" as "dashed"
                    }
                },
                
            },
            series: ['充电桩车场','非充电桩车场'].map((item,index) => {
                return {
                     name: item,
                     type: 'bar',
                     stack: '总量',
                     data: chargingRate.map((data,i) =>{
                          return {
                              label:{
                                  show:true,
                                  formatter:'{c}%'
                              },
                              value:index==0?data.chargingRate:data.nochargingRate,
                              itemStyle:{
                                  color:colors[index]
                              }
                          }
                      })
                }
            })
        };
        
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default ChargingRateStatistics
