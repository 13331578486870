import { callback, device_status } from './model'
import { HttpClientImmidIot } from "./request/HttpClientImmidIot"

// 市级
class CityModel {

    // 登录
    static login(param: any, callback: callback){
        const url = '/parking-authority/admin/token'
        HttpClientImmidIot.query(url, 'POST', param, callback)
    }
    
    // 今日城市概况
    static cityProfile(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/overview'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日停车概况
    static parkingProfile(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/space/overview'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 停车时长分布
    static parkingTime(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/duration'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 支付方式分布
    static paymentMethods(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/payRource'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 营收分析
    static revenueAnalysis(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/cityIncomeComparison'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 区域营收分析
    static revenueCapability(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/common'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 车流统计
    static trafficFlow(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/intOutTimes'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日用户概况
    static userInfo(param: {}, callback: callback){
        const url = '/parking-report/member/report/getTodayInfo'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 各区泊位占比
    static berthsProportion(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/space/parkingSpaceRateByArea'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月停车时长top5泊位
    static parkingTimeTop5ThisMonth(param: {cityId?: string, limit?:number, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/space/durationMonthTopParking'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日停车时长top5路段
    static parkingTimeTop5Today(param: {cityId?: string, limit?:number, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/space/durationTodayTopParking'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月停车时长top5路段（每天）
    static parkingTimeTop5ThisMonthRoad(param: {cityId?: string, limit?:number, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/space/durationMonthTopParking/date'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 违停次数统计|今日泊位利用率
    static violationTimes(param: {cityId?: string, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/common/today'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月泊位利用率
    static berthThisMonth(param: {cityId?: string, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/common/month'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 巡检监控-资源概况
    static resourceProfile(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/overview'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 片区巡检员占比
    static inspectorsProportion(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/subarea/inspectionMember/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 片区巡检事件占比
    static incidentsProportion(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/subarea/parkWarn/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日完成派单Top5巡检员
    static completedInspectorsTop5(param: {cityId?: string, startTime: string, endTime: string}, callback: callback){
        const url = '/parking-report/data/parkWarn/parkwarnTopDisposer'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月派单完成率
    static orderCompletionRate(param: {cityId?: string, statisticsTime?: Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/parkWarningDispose/date'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 巡检事件类型占比
    static patrolEventTypes(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/parkWarnType/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月巡检员出勤次数Top10
    static attendanceTimesTop10(param: {cityId?: string, statisticsTime?: Date, limit?: number}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/inspectionMember/sign/top'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月巡检事件Top10路段
    static inspectionSectionTop10(param: {cityId?: string, limit?: number}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/parking/top'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 热点停车区域
    static parkingArea(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/parkingHotPointWarning'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 地图-市区级停车场信息
    static cityParkingInf(param: {cityId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/city/area/floatBox'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 地图-每个停车场信息
    static areaParkingInf(param: {areaId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/area/parking/floatBox'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 巡检事件
    static inspectionEvents(param: {cityId: string, limit?:number}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/parkWarningDispose/top'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //片区巡检员分布
    static areaMember(param: {cityId: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/subarea/floatBox'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //点击片区-获取巡检员坐标
    static inspectionMembers(param: {subAreaId: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/subarea/inspectionMember/location'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 搜索框-获取稽查组
    static inspectionGroup(param: {subAreaId: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/subarea/inspectionGroup/list'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 搜索框-获取巡检员
    static inspectionMember(param: {groupId: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/subarea/inspectionGroup/inspectionMember/list'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //点击巡检员-获取巡检员信息
    static memberDetail(param: {inspectionMemberId: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/subarea/inspectionMember/detail'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 搜索框-搜索巡检员
    static searchMemberList(param: {key: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/inspect/inspectionMember/search'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 获取设备厂商列表
    static vendorList(param: {timestamp: number, }, callback: callback, host=true){
        const url = '/vendor/names'
        const args = {service: 'admin.vendor.names', version: '2.0',...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    // 设备状态列表
    static deviceStatus(param: {device_status:device_status, timestamp: number,page_size?: number}, callback: callback, host=true){
        const url = '/device/basic'
        //在线- ONLINE:1、离线-OFFLINE:0、故障- MALFUNC:2、未知- UNKNOWN:-1
        const args = {service: 'admin.device.basic', version: '2.0', ...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    // 激活状态列表
    static deviceActivated(param: {activated: boolean, device_status: device_status, timestamp: number, page_size?: number}, callback: callback, host=true){
        const url = '/device/basic'
        //设备激活状态分为两种activated：已激活- true、未激活-false
        const args = {service: 'admin.device.basic', version: '2.0', ...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    //厂商设备占比统计
    static manufacturerProportion(param: {timestamp: number}, callback: callback, host=true){
        const url = '/device/percentofvendor'
        const args = {service: 'admin.device.percentorvendor', version: '2.0', ...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    // 设备部署趋势（最近一年12个月）
    static equipmentDeploymentTrend(param: {timestamp: number}, callback: callback, host=true){
        const url = '/device/deployeofdata'
        const args = {service: 'admin.device.deployeofdata', version: '2.0', ...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    // 设备分区占比
    static partitionEquipment(param: {timestamp: number}, callback: callback, host=true){
        const url = '/device/percentofarea'
        const args = {service: 'admin.device.percentofarea', version: '2.0', ...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    //设备上报数据月统计（过去30天数据）
    static equipmentReport(param: {timestamp: number}, callback: callback, host=true){
        const url = '/device/monthofdata'
        const args = {service: 'admin.device.monthofdata', version: '2.0', ...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    //设备上报24小时数据
    static equipmentReportDay(param: {timestamp: number}, callback: callback, host=true){
        const url = '/device/dayofdata'
        const args = {service: 'admin.device.monthofdata', version: '2.0', ...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    // 实时设备状态数据统计
    static equipmentOverview(param: {timestamp: number}, callback: callback, host=true){
      const url = '/device/statusofdevice'
      const args = {service: 'admin.device.monthofdata', version: '2.0', ...param}
      HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    // 设备类型占比统计
    static equipmentType(param: {timestamp: number}, callback: callback, host=true){
        const url = '/device/percentoftype'
        const args = {service: 'admin.device.monthofdata', version: '2.0', ...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    // 设备状态月统计（过去30天数据）
    static equipmentWorkingStatus(param: {timestamp: number}, callback: callback, host=true){
        const url = '/device/monthofstatus'
        const args = {service: 'admin.device.monthofstatus', version: '2.0', ...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    //设备列表
    static equipmentList(param: {timestamp: number}, callback: callback, host=true){
        const url = '/device/basic'
        const args = {service: 'admin.device.basic', version: '2.0', ...param, page_size: 100000}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    // 设备详情
    static equipmentDetail(param: {id:number,timestamp: number}, callback: callback, host=true){
        const url = '/device/basic/' + param.id
        const args = {service: 'admin.device.basic', version: '2.0', timestamp: param.timestamp}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }

    // 实时设备上报数据统计
    static deviceIndicators(param: {timestamp: number}, callback: callback, host=true){
        const url = '/device/countofdata'
        const args = {service: 'admin.device.basic', version: '2.0',...param}
        HttpClientImmidIot.query(url, 'GET', args, callback, host)
    }
}

export default CityModel