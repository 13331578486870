import { HttpClientImmidIot } from "./request/HttpClientImmidIot"
import { callback } from './model'

// 路外停车
class OutRoadModel {
    //地图+地图下 区域停车场列表统计信息
    static parkingSummary(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/parkings/summary'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
    //地图上   区域今日收入/停车次数
    static timesAmount(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/parkings/times/amount'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
    // 左1 *街道/乡镇互联互通车场统计
    static areaRate(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/parkings/area/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //左2 车场类型统计
    static classifyRate(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/parkings/classify/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //左3 *车辆类型统计
    static plateNumberRate(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/plateNumber/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //左4 各街道充电桩车场占比统计
    static chargingRate(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/parkings/charging/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    //中底1 热点停车区域  中底2 周转能力分析
    static areaHotPoints(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/hotPoints'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
    //2.7 热点停车区域
   static areaHotPointsParkings(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/hotPoints/parkings'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
    //2.8 周转能力分析
    static areaTurnover(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/turnover'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }


    //右4  支付方式统计
    static payTypeRate(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/payType/rate'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
    //右3   营收统计
    static incomeComparison(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/income/comparison'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
    //右2   车流统计
    static inOutTimes(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/inOut/times'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
    //右1   实时车辆通行信息
    static areaTopRecord(param: {}, callback: callback) {
        const url = '/parking-city-parking-network-admin/admin/statistics/dataVisualizations/area/top/record'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

}

export default OutRoadModel