import echarts from 'echarts'
import ReactEcharts from 'echarts-for-react'
import PubSub from 'pubsub-js'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import { parking_profile } from '../../../model/modelData'
import CityModel from '../../../model/cityModel'
import { EquipmentOverview, ParkingProfile } from '../../../model/model'
import ParkingModel from '../../../model/parkingModel'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../../model/SystemFunction'
import { MapParking } from '../statistics'
import pointIcon from './../../../static/images/statistics/白色圆点.png'

interface Props{
    level?:'park',
    type?: 'berthSurvey'|'equipmentOverview'
}
interface State{
    parkingProfile:ParkingProfile,
    equipmentOverview?: EquipmentOverview
}
export class BerthSurvey extends PureComponent<Props,State> {
    timer: NodeJS.Timeout | number
    
    constructor(props:any){
        super(props)
        this.state = {
            parkingProfile: parking_profile,
            equipmentOverview: {
              online: 0,   //在线数量
              offline: 0,  //离线数量
              malfunc: 0,  //故障数量
              unknown: 0   //未知数量
            }
        }
    }

    componentDidMount = () => {
        switch (this.props.type) {
            case 'berthSurvey':
                this.getBerthSurvey({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                this.timer = setInterval(()=>{
                    this.getBerthSurvey({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})   
                }, 5*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getBerthSurvey(info)
                    this.timer = setInterval(()=>{
                        this.getBerthSurvey(info)
                    }, 5*1000 )
                })
                break;
            case 'equipmentOverview':
                this.getEquipmentOverview()
                this.timer = setInterval(()=>{
                    this.getEquipmentOverview()
                }, 5*1000 )
                break;
            default:
                break;
        }
    }

    componentWillUnmount = () => {
        clearInterval(Number(this.timer))
        PubSub.unsubscribe('mapParking');
    }

    // 获取今日停车概况
    getBerthSurvey = (info: MapParking) => {
        if(this.props.level&&(this.props.level == 'park')){
            const parkingId = localStorage.getItem('parkingId')
            ParkingModel.parkingProfile({parkingId},(d, type)=>{
                if (type === HttpClientImmidIot.requestSuccess) {
                    //成功-------在这里做你的数据处理，需要提示的自己加
                    if (d.success) {
                        this.setState({parkingProfile:d.data})
                    }
                } else {
                    //失败----做除了报错之外的操作
                    console.error('获取今日停车概况失败')
                }
            })
        }else{
            const model = MapModel[info.level]
            const parm = MapModelParm[info.level]
            model.parkingProfile({[parm]:info.adcode},(d, type)=>{
                if (type === HttpClientImmidIot.requestSuccess) {
                    //成功-------在这里做你的数据处理，需要提示的自己加
                    if (d.success) {
                        this.setState({parkingProfile:d.data})
                    }
                } else {
                    //失败----做除了报错之外的操作
                    console.error('获取今日停车概况失败')
                }
            })
            
        }
    }

    getEquipmentOverview = () => {
      CityModel.equipmentOverview({ timestamp: new Date().getTime() },(d, type)=>{
        if (type === HttpClientImmidIot.requestSuccess) {
            //成功-------在这里做你的数据处理，需要提示的自己加
            if(d.ack_code=='ok'){
                this.setState({equipmentOverview:d})
            }
        } else {
            //失败----做除了报错之外的操作
            console.error('设备状态列表失败')
        }
    })
    }

    getEChartsData = () => {
        let yField=[],yData=[],bgData=[]
        switch (this.props.type) {
            case 'berthSurvey':
                const { totalSpaceCount, usedSpaceCount, freeSpaceCount } = this.state.parkingProfile || {}
                yField = ['占用', '空闲', '停用']
                yData = [usedSpaceCount||0,freeSpaceCount||0,(totalSpaceCount-usedSpaceCount-freeSpaceCount)||0]
                bgData = [totalSpaceCount,totalSpaceCount,totalSpaceCount]
                break;
            case 'equipmentOverview':
                const {online,malfunc,unknown,offline} = this.state.equipmentOverview
                yField = ['在线', '异常', '未知','离线']
                yData = [online,malfunc,unknown,offline]
                bgData = new Array(4).fill(online+malfunc+unknown+offline)
                break;
            default:
                break;
        }
        return {
            yField,
            yData,
            bgData
        }
    }
    
    getOptions = () => {
        const {yField,yData,bgData} = this.getEChartsData()
        return {
            grid: {
                top: '2%',
                bottom: -15,
                right: 16,
                left: 16,
                containLabel: true
            },
            tooltip: {
                trigger: 'axis' as 'axis',
                axisPointer: {
                    type: 'none' as 'none'
                },
                formatter: function(params:any) {
                    return params[0].name + '<br/>' +
                        "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
                        params[0].seriesName + ' : ' + params[0].value + ' 个<br/>'
                }
            },
            // backgroundColor: 'rgb(8,28,52)',
            xAxis: {
                show: false,
                type: 'value' as 'value'
            },
            yAxis: [{
                type: 'category' as 'category',
                inverse: true,
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#fff'
                    },
                },
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                data: yField
            }, {
                type: 'category' as 'category',
                inverse: true,
                axisTick: 'none',
                axisLine: {
                    show: false
                },
                show: true,
                axisLabel: {
                    textStyle: {
                        color: '#ffffff',
                        fontSize: '12'
                    },
                    formatter: function(value:number) {
                        return value.toLocaleString();
                    },
                },
                data: yData
            }],
            series: [
                {
                    name: this.props.type&&this.props.type=='equipmentOverview'?'设备':'泊位',
                    type: 'pictorialBar',
                    symbol: `image://${pointIcon}`,
                    symbolSize: [15, 15],
                    symbolOffset: [0, 0],
                    zlevel: 14,
                    itemStyle: {
                        normal: {
                            color: '#14b1eb'
                        }
                    },
                    data: yData.map(item => {
                        return {
                            value: item,
                            symbolPosition: 'end'
                        }
                    })
                },
                {
                    name: '金额',
                    type: 'bar',
                    zlevel: 1,
                    itemStyle: {
                        normal: {
                            barBorderRadius: 30,
                            // @ts-ignore
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                offset: 0,
                                color: '#4372FF'
                            }, {
                                offset: 1,
                                color: '#71D2FF'
                            }]),
                        },
                    },
                    barWidth: 8,
                    data: yData
                },
                {
                    name: '背景',
                    type: 'bar',
                    barWidth: 8,
                    barGap: '-100%',
                    data: bgData,
                    itemStyle: {
                        normal: {
                            color: 'rgba(0,119,226,0.4)',
                            barBorderRadius: 30,
                        }
                    },
                },
                
            ]
        };
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default withRouter(BerthSurvey)
