import React, { PureComponent, useEffect, useState } from 'react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay  } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
import 'swiper/swiper-bundle.css';
import {Carousel} from "antd";
import { HttpClientImmidIot } from '../../model/request/HttpClientImmidIot'
import { ChartCard } from '../statistics/statistics'
import './outRoadParkingDetail.css';
import chartTitleIcon from "../../static/images/statistics/标题icon@2x.png";
import TrafficFlow from "../statistics/charts/trafficFlow";
import PaymentMethod from "../statistics/charts/paymentMethod";
import CountUp from "react-countup";
import parkingTotal from "../../static/images/parkingDetail/总车位数.png";
import todayEntrance from "../../static/images/parkingDetail/今日入场车辆.png";
import todayParking from "../../static/images/parkingDetail/今日停车次数.png";
import spareParking from "../../static/images/parkingDetail/空余车位数.png";
import parkingPlace from "../../static/images/parkingDetail/车位利用率.png";
import outRoadParkingDetailModelRequest from "../../model/outRoadParkingDetailModelRequest";
import {parkingCount, parkingDetailInfo, parkingTopRecord} from "../../model/outRoadParkingDetailModelInterface";
import {
    detail_top_record,
    parking_count,
    parking_Detail_Info,
    parking_Top_Record
} from "../../model/outRoadParkingDetailInitialData";
import RevenueAnalysis from "../statistics/charts/revenueAnalysis";
import NumberOfStop from "../statistics/charts/numberOfStop";
import moment from "moment";
import {Modal} from "antd";
export class outRoadParkingDetail extends PureComponent<any,any> {
    timer: NodeJS.Timeout | number;
    constructor(props:any){
        super(props);
    }
    componentDidMount = () => {

    };

    // 车流：统计
     trafficFlow = ()=>{
        const element = <div className='traffic_flow_data'>
            <TrafficFlow type={"outRoadParkingDetail"} />
        </div>;
        return <ChartCard chartName='车流统计' style='traffic_flow' children={element}/>
    };

    //营收分析
     revenueAnalyse = ()=>{
        const element = <div className='traffic_flow_data'>
            <RevenueAnalysis type={"detailIncomeComparison"} />
        </div>;
        return <ChartCard chartName='营收分析' style='traffic_flow' children={element}/>
    };

    //车位利用率
    parkingPlaceRate = ()=>{
        const element = <div className='traffic_flow_data'>
            <NumberOfStop type={"detailUseRatio"}/>
        </div>;
        return <ChartCard chartName='车位利用率分析' style='traffic_flow' children={element}/>
    };

    /* 支付方式分布  */
    paymentMethod = ()=>{
        const element = <div className='payment_method_data'>
            <PaymentMethod type='parkingDetailPaymentType'/>
        </div>;
        return <ChartCard chartName='支付方式分布' style='traffic_flow' children={element}/>
    };


    render() {
        return (
            <div className='parking_statistics_main'>
                {/* 左侧统计图 */}
                <DetailLeftCard/>
                {/* 中间统计图 */}
                <ParkingDetailMiddle/>
                {/* 右边统计图 */}
                <div className={'parking_detail_right_content'}>
                    {this.trafficFlow()}
                    {this.revenueAnalyse()}
                    {this.parkingPlaceRate()}
                    {this.paymentMethod()}
                </div>
            </div>
        )
    }
}
export default outRoadParkingDetail
function DetailLeftCard(){
    const [datasource,setDatasource] = useState<parkingDetailInfo|null>(parking_Detail_Info);
    let  parkingType = ["商业","住宅","医院","园区","交通枢纽","行政机关","学校","其他"];
    let timer:NodeJS.Timeout | number;
    useEffect(() => {
        requestDatasource();
        timer = setInterval(()=>{
            requestDatasource();
        }, 30*1000);
        return () => {
            clearInterval(Number(timer))
        }
    },[]);
    const requestDatasource = () => {
        let parkingId = localStorage.getItem("DETAIL_PARKING_ID");
        outRoadParkingDetailModelRequest.parkingDetailInfo({parkingId:parseInt(parkingId)},(d,type)=>{
            if(type === HttpClientImmidIot.requestSuccess){
                if(d.success){
                    setDatasource(d.data)
                }
            }else{
                console.error('获取停车场信息失败')
            }
        });
    };
    return (
        <div className={'chart_bg_img detail_berths_proportion'}>
            <div className='chart_title' style={{paddingTop:"0.16rem"}}><img src={chartTitleIcon} alt=""/>{"停车场基础信息"}</div>
            <div className={"parking_detail_left_content"}>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>车位名称</div>
                    <div className={"parking_detail_left_value"}>{datasource.parkingName || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>所属街道</div>
                    <div className={"parking_detail_left_value"}>{datasource.streetName || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>地址</div>
                    <div className={"parking_detail_left_value"}>{datasource.address || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>联系电话</div>
                    <div className={"parking_detail_left_value"}>{datasource.parkingPhone || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>服务时间</div>
                    <div className={"parking_detail_left_value"}>{datasource.workTimeStart + "-" + datasource.workTimeEnd}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>收费规则</div>
                    <div className={"parking_detail_left_value"}>{datasource.priceDescription || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_dashes"}/>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>产权方</div>
                    <div className={"parking_detail_left_value"}>{datasource.owner || "暂无信息" }</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>运营方</div>
                    <div className={"parking_detail_left_value"}>{datasource.maintenance || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>服务属性</div>
                    <div className={"parking_detail_left_value"}>{parkingType[datasource.classify - 1]}</div>
                </div>
                <div className={"parking_detail_left_dashes"}/>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>所属派出所</div>
                    <div className={"parking_detail_left_value"}>{datasource.ownStation || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>区域负责民警</div>
                    <div className={"parking_detail_left_value"}>{datasource.chargePolice || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>业委会信息</div>
                    <div className={"parking_detail_left_value"}>{datasource.industAuthorInfo || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>纳税主体</div>
                    <div className={"parking_detail_left_value"}>{datasource.taxMain || "暂无信息"}</div>
                </div>
                <div className={"parking_detail_left_item"}>
                    <div className={"parking_detail_left_title"}>纳税人识别号</div>
                    <div className={"parking_detail_left_value"}>{datasource.taxId || "暂无信息"}</div>
                </div>
            </div>
        </div>
    )
}

function ParkingDetailMiddle() {
    const [datasource,setDatasource] = useState<parkingDetailInfo|null>(parking_Detail_Info);
    const [parkingCount,setParkingCount] = useState<parkingCount|null>(parking_count);
    const [parkingTopRecord,setParkingTopRecord] = useState(detail_top_record);
    const [duration,setDuration] = useState(2);
    const [visible,setVisible] = useState(false);
    const [visibleImg,setVisibleImg] = useState("");
    let timer:NodeJS.Timeout | number;
    let timer2:NodeJS.Timeout | number;
    let countUp1:any;
    let countUp2:any;
    let countUp3:any;
    let countUp4:any;
    let countUp5:any;
    useEffect(() => {
        requestDatasource();
        requestParkingCount();
        requestParkingTopRecord();
        timer = setInterval(()=>{
            requestDatasource();
            requestParkingCount();
        }, 30*1000);

        timer2 = setInterval(()=>{
            requestParkingTopRecord()
        }, 10*1000);
        return () => {
            clearInterval(Number(timer));
            clearInterval(Number(timer2));
        }
    },[]);
    const requestDatasource = () => {
        console.log("requestDatasource");
        let parkingId = localStorage.getItem("DETAIL_PARKING_ID");
        outRoadParkingDetailModelRequest.parkingDetailInfo({parkingId:parseInt(parkingId)},(d,type)=>{
            if(type === HttpClientImmidIot.requestSuccess){
                if(d.success){
                    setDatasource(d.data);
                    // setDuration(duration+Math.random())
                    if(countUp1){
                        countUp1.update();
                        countUp2.update();
                        countUp3.update();
                    }
                }
            }else{
                console.error('获取停车场信息失败')
            }
        });
    };
    const requestParkingCount = () => {
        let parkingId = localStorage.getItem("DETAIL_PARKING_ID");
        outRoadParkingDetailModelRequest.parkingCount({parkingId:parseInt(parkingId)},(d,type)=>{
            if(type === HttpClientImmidIot.requestSuccess){
                if(d.success){
                    setParkingCount(d.data);
                    setDuration(duration+Math.random())
                    if(countUp1){
                        countUp4.update();
                        countUp5.update();
                    }
                }
            }else{
                console.error('获取停车次数失败')
            }
        });
    };

    const requestParkingTopRecord = () => {
        let parkingId = localStorage.getItem("DETAIL_PARKING_ID");
        outRoadParkingDetailModelRequest.detailParkingTopRecord({parkingId:parseInt(parkingId)},(d,type)=>{
            if(type === HttpClientImmidIot.requestSuccess){
                if(d.success && d.data.length > 0){
                    setParkingTopRecord(d.data)
                }
            }else{
                console.error('获取停车次数失败')
            }
        });
    };

    let tempArea = [];
    for (let i = 0; i < 2; i++){
        tempArea.push(
            <div key={i} className={"detail_header_body_box"}>
                {
                    parkingTopRecord.map((item,index)=>{
                        return (
                            <div key={index} className={index % 2 === 0  ? "detail_header_body_item_box_t" : "detail_header_body_item_box"}>
                                <div className={"detail_header_body_item"}>{item.plateNumber || "-"}</div>
                                <div className={"detail_header_body_item_t"}>{item.inTime ? moment(item.inTime).format('LTS') : "-"}</div>
                                <div className={"detail_header_body_item_t"}>{item.inChannel ? item.inChannel.length > 5  ? item.inChannel.substring(0,5)+ "..." : item.inChannel : "-"}</div>
                                {
                                    item.inImageUrl ?
                                        <img className={"detail_header_body_item_img"} onClick={()=>{
                                            setVisibleImg(item.inImageUrl);
                                            setVisible(true)}}
                                             src={item.inImageUrl}/>
                                             :
                                        <div className={"detail_header_body_item_t"}>-</div>
                                }
                                <div className={"detail_header_body_item_t"}>{item.outTime? moment(item.outTime).format('LTS') : "-"}</div>
                                <div className={"detail_header_body_item_t"}>{item.outChannel ? item.outChannel.length > 5  ? item.outChannel.substring(0,5)+ "..." : item.outChannel : "-"}</div>
                                {
                                    item.outImageUrl ?
                                        <img className={"detail_header_body_item_img"}
                                             onClick={()=>{
                                                 setVisibleImg(item.outImageUrl);
                                                 setVisible(true)}}
                                             src={item.outImageUrl}/>
                                             :
                                        <div className={"detail_header_body_item_t"}>-</div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    return (
        <div className={'parking_detail_middle_content'}>
            <div className='display_key_indicators parking_detail_display_key_indicators'>
                <div>
                    <img src={parkingTotal} alt=""/>
                    <div>
                        <div className='keyIndicators_amount' style={{color:"#DFB025"}}>
                            <CountUp
                                onEnd={(countUp)=>{
                                    countUp1 = countUp
                                }}
                                duration={duration}
                                redraw={true}
                                start={0}
                                end={datasource.parkingSpaceTotal}
                                // decimals={2}
                            />
                        </div>
                        <div className='keyIndicators_field'>{"总车位数"}</div>
                    </div>
                </div>
                <div>
                    <img src={todayEntrance} alt=""/>
                    <div>
                        <div className='keyIndicators_amount' style={{color:"#82E4FF"}}>
                            <CountUp
                                onEnd={(countUp)=>{
                                    countUp2 = countUp
                                }}
                                duration={duration}
                                redraw={true}
                                start={0}
                                end={parkingCount.inTimes}
                                // decimals={2}
                            />
                        </div>
                        <div className='keyIndicators_field'>{"今日入场车辆"}</div>
                    </div>
                </div>
                <div>
                    <img src={todayParking} alt=""/>
                    <div>
                        <div className='keyIndicators_amount' style={{color:"#E9FF7A"}}>
                            <CountUp
                                onEnd={(countUp)=>{
                                    countUp3 = countUp
                                }}
                                duration={duration}
                                redraw={true}
                                start={0}
                                end={parkingCount.outTimes}
                                // decimals={2}
                            />
                        </div>
                        <div className='keyIndicators_field'>{"今日停车次数"}</div>
                    </div>
                </div>
                <div>
                    <img src={spareParking} alt=""/>
                    <div>
                        <div className='keyIndicators_amount' style={{color:"#56CFA7"}}>
                            <CountUp
                                onEnd={(countUp)=>{
                                    countUp4 = countUp
                                }}
                                duration={duration}
                                redraw={true}
                                start={0}
                                end={datasource.parkingSpaceResidual}
                                // decimals={2}
                            />
                        </div>
                        <div className='keyIndicators_field'>{"空余车位数"}</div>
                    </div>
                </div>
                <div>
                    <img src={parkingPlace} alt=""/>
                    <div>
                        <div className='keyIndicators_amount' style={{color:"#A38EFF"}}>
                            <CountUp
                                onEnd={(countUp)=>{
                                    countUp5 = countUp
                                }}
                                duration={duration}
                                suffix={"%"}
                                redraw={true}
                                start={0}
                                end={datasource.spaceUsageRate}
                                // decimals={2}
                            />
                        </div>
                        <div className='keyIndicators_field'>{"车位利用率"}</div>
                    </div>
                </div>
            </div>
            <div className={'chart_bg_img real_time_table_content'}>
                <div className='chart_title' style={{paddingTop:"0.16rem"}}><img src={chartTitleIcon} alt=""/>{"实时车辆通行信息"}</div>
                <div className={"detail_table_header_box"}>
                    <div className={"detail_table_header_title"}>车牌号</div>
                    <div className={"detail_table_header_title"}>入场时间</div>
                    <div className={"detail_table_header_title"}>入口</div>
                    <div className={"detail_table_header_title"}>入场照片</div>
                    <div className={"detail_table_header_title"}>出场时间</div>
                    <div className={"detail_table_header_title"}>出口</div>
                    <div className={"detail_table_header_title"}>出场照片</div>
                </div>
                <Carousel
                    speed={400}
                    autoplaySpeed={5000}
                    dotPosition={"left"}
                    autoplay={true}
                    dots={false}>
                    {tempArea}
                </Carousel>
            </div>
            <Modal
                footer={null}
                visible={visible}
                onCancel={()=>{
                    setVisible(false)
            }}>
                <img className={"visible_img"} src={visibleImg}/>
            </Modal>
        </div>
    )
}
