import moment from 'moment';
import React, { PureComponent, useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import boxRightBgSelected from '../../static/images/statistics/右标题选中框.png';
import icon4 from '../../static/images/statistics/巡检监控.png';
import boxLeftBg from '../../static/images/statistics/标题框@2x.png';
import icon3 from '../../static/images/statistics/泊位监控.png';
import boxRightBg from '../../static/images/statistics/矩形.png';
// import icon1 from '../../static/images/statistics/综合统计ICON @2x.png';
import icon1 from '../../static/images/statistics/路内停车.png';
import icon2 from '../../static/images/statistics/设备监控.png';
import { MapParking } from '../statistics/statistics';
import locationIcon from './../../static/images/statistics/城市icon@2x.png';
import boxLeftBgSelected from './../../static/images/statistics/标题选中框@2x.png';
import dropDownIcon from './../../static/images/statistics/路径@2x.png';
import returnIcon from './../../static/images/statistics/返回@2x.png';
import './navigationBar.css';
import parkingNavIcon from './../../static/images/navBar/路外停车.png';

export class NavigationBar extends PureComponent<any,any> {
    timer:NodeJS.Timeout | number
    constructor(props:any){
        super(props)
        this.state = {
            menu: ['/outRoadParking','/statistics','/equipmentMonitoring','/inspectionMonitoring',],
            selectedIndex: 0,
            mapDivision: 'top',
            areaName: localStorage.getItem('cityName')||'深圳市'
        }
    }

    componentDidMount = () => {
        const { menu } = this.state
        const index = menu.findIndex((element: string) => window.location.hash.includes(element))
        this.setState({ selectedIndex:(index>=0?index:0) })
        PubSub.subscribe('mapParking',(msg: string, info: MapParking)=>{
            this.setState({
                mapDivision: info.level,
                areaName: info.areaName||localStorage.getItem('cityName')
            })
        })
        this.timer = setInterval(()=>{
            const level = localStorage.getItem('level')
            const areaName = localStorage.getItem('areaName')
            if(level&&areaName){
                this.setState({
                    mapDivision: level,
                    areaName: areaName
                })
            }
        },1000)
    }

    componentWillUnmount = () => {
        PubSub.unsubscribe('mapParking')
        clearInterval(Number(this.timer))
    }

    goBack = () => {
        localStorage.setItem('level','top')
        localStorage.setItem('areaName',localStorage.getItem('cityName') || '深圳市')
        this.props.history.push({ pathname: '/statistics' })
    }

    render() {
        const { menu, selectedIndex, mapDivision, areaName } = this.state
        const menuLeft = [
            { icon:parkingNavIcon,  title:'路外停车' , path: menu[0] },
            { icon:icon1,  title:'路内停车' , path: menu[1] },
            
            
        ]
        const menuRight = [
            { icon:icon2,  title:'设备监控' , path: menu[2] },
            // { icon:icon3,  title:'泊位监控' , path: menu[2] },
            { icon:icon4,  title:'巡检监控' , path: menu[3] },
            // { icon:icon5,  title:'订单管理' , path: menu[4] },
            // { icon:icon6,  title:'辅助决策' , path: menu[5] },
        ]
        return <div className='display_head'>
            <div className='display_head_left'>
                <Time />
                <div className='display_head_menu display_head_menu_left'>
                    {
                        menuLeft.map((item,index)=><Link to={item.path} 
                            className='display_item_left' key={index} 
                            style={{backgroundImage:`url(${selectedIndex==index?boxLeftBgSelected:boxLeftBg}`,color:`${selectedIndex==index?'#32C5FF':''}`}}
                            onMouseDown={()=>{
                                this.setState({selectedIndex:index})
                            }}
                            >
                            <img src={item.icon} alt=""/>{item.title}
                        </Link>)
                    }
                </div>
            </div>
            {/*<div className='display_head_title'>颍州区智慧停车大数据平台</div>*/}
            <div className='display_head_right'>
                <div className='display_head_menu'>
                    {
                        menuRight.map((item,index)=><Link to={item.path} 
                            className='display_item_right' key={index} 
                            style={{backgroundImage:`url(${selectedIndex==index+2?boxRightBgSelected:boxRightBg}`,color:`${selectedIndex==index+2?'#32C5FF':''}`}}
                            onMouseDown={()=>{
                                this.setState({selectedIndex:index+2})
                            }}
                            >
                            <img src={item.icon} alt=""/>{item.title}
                        </Link>)
                    }
                </div>
                <div className='display_location'>
                    <img className='display_locationIcon' src={locationIcon} alt=''/>
                    <span>
                        {/* 当前{areaName&&areaName.slice(-1)=='市'?'城市':'行政区'}：{areaName} */}
                        当前行政区：颍州区
                    </span>
                    <img className='display_dropDownIcon' src={dropDownIcon} alt=''/>
                </div>
                <div onClick={()=>{this.goBack()}}>
                    <img className='display_returnIcon' src={returnIcon} alt=''/>
                </div>
            </div>
        </div>
    }
}

export default withRouter(NavigationBar)


// 日期

export function Time(){
    let dateTick: NodeJS.Timeout | number
    const [time, setTime] = useState(moment().format('LTS'))
    const [date, setDate] = useState(moment().format('LL'))
    const [day, setDay] = useState(moment().format('dddd'))
    useEffect(() => {
        dateTick = setInterval(()=>{
            setTime(moment().format('LTS'))
            setDate(moment().format('LL'))
            setDay(moment().format('dddd'))
        },1000);
        return ()=>{
            dateTick&&clearInterval(Number(dateTick))
        }
    })

    return <div className='menu_date'>
        <div className='display_time'>{time}</div> 
        <div className='time_date_line'/>
        <div className='display_date'>
            <div>{date}</div>
            <div>{day}</div>
        </div>
    </div>
}
