import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import React, { PureComponent, useEffect, useState } from 'react'
import { Map, Markers } from 'react-amap'
import CityModel from '../../../model/cityModel'
import { AreaMember, InspectionGroup, InspectionMember, MemberInfo } from '../../../model/model'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import memberIcon from '../../../static/images/inspectionMonitoring/巡检员@2x.png'
import selectMemberIcon from '../../../static/images/inspectionMonitoring/巡检员绿色@2x.png'
import { location, mapProps, showCityMap } from './amap'
import './inspectionMap.css'

interface State {
    areaMember?: Array<AreaMember>,
    memberInfo?: Array<MemberInfo>,
    mapDivision?: 'top' | 'member',
    zoom?: {
        area: number,
        parking: number
    },
    marker?: any,
    memberDetail?: MemberInfo,
}
export class InspectionMap extends PureComponent<any, State> {
    infoWindow: any
    map: any

    constructor(props: any) {
        super(props)
        this.state = {
            areaMember: [],
            memberInfo: [],
            mapDivision: 'top',
            marker: null,
            memberDetail: null,
            zoom: {
                area: 0,
                parking: 0
            }
        }
    }

    componentDidMount = () => {
        this.getInspectionMember()
        PubSub.subscribe('mapParking',(msg: string, info:any)=>{
            this.getInspectionMember(info.adcode)
        })
    }

    //获取片区巡检员分布数据
    getInspectionMember = (code?:string) => {
        const cityId = code || localStorage.getItem('cityCode') || '440300'
        CityModel.areaMember({cityId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success && d.data.length>0) {
                    this.setState({areaMember:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取片区巡检员分布数据失败')
            }
        })
    }

    //点击片区-获取巡检员坐标
    getInspectionMembers = (subAreaId) => {
        CityModel.inspectionMembers({subAreaId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success && d.data.length>0) {
                    this.setState({memberInfo:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('点击片区-获取巡检员坐标数据失败')
            }
        })
    }

    getMarkers = () => { 
        const {areaMember,memberInfo,mapDivision} = this.state
        switch (mapDivision) {
            case 'top':
                return (areaMember||[]).map((item,index) => {
                    return {
                        position: {
                            longitude: item.longitude,
                            latitude: item.latitude,
                        },
                        ...item,
                        level: 'top'
                    }
                })
            case 'member':
                return (memberInfo||[]).map((item,index) => {
                    return {
                        position: {
                            longitude: item.longitude,
                            latitude: item.latitude,
                        },
                        ...item,
                        level: 'member'
                    }
                })
            default:
                return []
        }
    }

    renderMarkerLayout = (extData:any) => {
        const {mapDivision,memberDetail} = this.state
        switch (mapDivision) {
            case 'top':
                return <div className='parkingMap_float_box'>
                    <div className='float_box_num'>{(extData as AreaMember).inspectorMembers}</div>
                    <div>{extData.subAreaName}</div>
                </div>
            case 'member':
                return <img className='parkingMap_parkingIcon' src={memberDetail && (memberDetail.userId== extData.userId)? selectMemberIcon:memberIcon} alt="" />
            default:
                return null
        }
    }

    mapOptions = () => ({
        mapStyle: 'amap://styles/fb153177faab9a59cbf2f6b7f78ca63e',
        useAMapUI: true,
        // zoom:10,
        events: {
            created: (mapInstance) => {
                this.map = mapInstance
                // window.AMap.plugin('AMap.CitySearch', () => {
                //     const citySearch = new window.AMap.CitySearch()
                //     citySearch.getLocalCity((status, result) => {
                //         if (status === 'complete' && result.info === 'OK') {
                //             // 查询成功，result即为当前所在城市信息
                //             localStorage.setItem('cityName',result.city)
                //             localStorage.setItem('cityCode',result.adcode)
                //             if(this.state.mapDivision == 'top'){
                //                 PubSub.publish('mapParking',{level:'top',adcode: result.adcode, areaName:result.city})
                //             }
                //         }
                //     })
                // })
                // 指定颍州区
                location(mapInstance)
                showCityMap(mapInstance)

                this.setState({zoom:{area: mapInstance.getZoom(),parking: mapInstance.getZoom()+2}},()=>{
                    mapInstance.on('zoomend',()=>{
                        this.infoWindow.close()
                        const current = mapInstance.getZoom()
                        const {area,parking} = this.state.zoom
                        const aver = (area+parking)/2
                        if(current<aver){
                            if(this.state.mapDivision != 'top'){
                                this.setState({mapDivision:'top'})
                            }
                        }else{
                            if(this.state.mapDivision != 'member'){
                                // this.setState({mapDivision:'member'})
                            }
                        }
                    })
                })
                this.infoWindow = new window.AMap.InfoWindow({
                    anchor: 'bottom-center',
                    isCustom: true,
                    content: '',
                });
            }
        }
    })

    markerEvents = () => {
        return {
            click: (e, marker) => {
                const map = marker.getMap(), position = marker.getPosition( ), extData = marker.getExtData()
                if(extData.level == 'top'){
                    this.getInspectionMembers(extData.subAreaId)
                    this.setState({mapDivision: 'member'})
                    map.setZoomAndCenter(map.getZoom()+2,position)
                }
                if(extData.level == 'member'){
                    const callback = (data) => {
                        this.infoWindow.setContent(this.getInfoWindowContent(data))
                        this.infoWindow.open(map,position)
                    }
                    this.getMemberDetail(extData.userId,callback)
                }
            }
        }
    }

    getInfoWindowContent = (data) => {
        const classes = data.workType.map((item,index) => ['早','中','晚','','休'][index])
        return `<div class='infoWindow_member'>
            <p class='infoWindow_title'>${data&&data.groupName}</p>
            <div><div>所属片区</div><div>${data&&data.subAreaName}</div></div>
            <div><div>所属中队</div><div>${data&&data.groupName}</div></div>
            <div><div>当前班次</div><div>${classes&&classes.lenght>0?classes.toString()+'班':'无'}</div></div>
            <div><div>联系方式</div><div>${data&&data.mobile}</div></div>
            <div><div>指派单数</div><div>${data&&data.parkWarnTotal}</div></div>
            <div><div>完成单数</div><div>${data&&data.parkWarnDisposedTotal}</div></div>
            <div class='member_trace'><div>查看巡检轨迹</div></div>
        </div>`
    }

    //点击巡检员-获取巡检员信息
    getMemberDetail = (inspectionMemberId:string,callback?) => {
        CityModel.memberDetail({inspectionMemberId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({memberDetail:d.data},callback&&callback(d.data))
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取巡检员信息失败')
            }
        })
    }

    selectedMember = (subAreaId:string,userId:string) => {
        this.getInspectionMembers(subAreaId)
        this.setState({mapDivision: 'member'})
        this.map.setZoom(this.map.getZoom()+2)
        this.getMemberDetail(userId)
        // this.map.setFitView()
    }

    render() {
        return <Map {...mapProps}  {...this.mapOptions()}>
            <SearchBox 
                areaMember={this.state.areaMember} selectedMember={(subAreaId:string,userId:string)=>{this.selectedMember(subAreaId,userId)}}/>
            <Markers 
                events={this.markerEvents()}
                markers={this.getMarkers()}
                render={this.renderMarkerLayout}/>
        </Map>
    }
}

export default InspectionMap
const { Option } = Select;
function SearchBox(props:{areaMember:Array<AreaMember>,selectedMember:any}){
    const [form] = Form.useForm();
    // 片区列表
    const [areaMember,setAreaMember] = useState<Array<AreaMember>|null>(props.areaMember)
    // 中队列表
    const [group,setGroup] = useState<Array<InspectionGroup>|null>(null)
    // 巡检员列表
    const [inspectionMember,setInspectionMember] = useState<Array<InspectionMember>|null>(null)

    // 巡检员关键字
    const [member,setMember] = useState<string>('')

    // 展示查询出的巡检员列表
    const [showMemberList,setShowMemberList] = useState<boolean>(false)
    const [memberList,setMemberList] = useState<Array<MemberInfo>>(null)

    useEffect(()=>{
        if(props.areaMember&&props.areaMember.length>0){
            setAreaMember(props.areaMember)
        }
    },[props.areaMember])

    // 搜索框-获取稽查组
    const getGroup = (subAreaId) => {
        setShowMemberList(false)
        CityModel.inspectionGroup({subAreaId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setGroup(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('搜索框-获取稽查组数据失败')
            }
        })
    }
    // 搜索框-获取巡检员
    const getMember = (groupId) => {
        setShowMemberList(false)
        CityModel.inspectionMember({groupId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setInspectionMember(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('搜索框-获取稽查组数据失败')
            }
        })
    }
    // 搜索框-搜索巡检员
    const getMemberList = () => {
        setShowMemberList(false)
        if(!member){
            return
        }
        CityModel.searchMemberList({key: member},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setShowMemberList(true)
                    setMemberList(d.data)
                }else{
                    message.info('搜索框-未搜索到巡检员数据')
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('搜索框-搜索巡检员数据失败')
            }
        })
    }

    return <div className='inspection_SearchBox'>
        <div className='searchBox_title'>巡检员搜索</div>
        <Form form={form}>
            <Row gutter={10}>
                <Col span={8}>
                    <Form.Item name="area" >
                        <Select 
                            placeholder="片区" 
                            className='searchBox_select' 
                            value={areaMember&&areaMember[0]&&areaMember[0].subAreaName || ''}
                            onChange={(value)=>{getGroup(value)}}>
                            {(areaMember||[]).map((item,index) => <Option 
                                key={index} 
                                value={item.subAreaId}
                                >{item.subAreaName}</Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="group" >
                        <Select 
                            placeholder="中队" 
                            className='searchBox_select' 
                            value={group&&group[0]&&group[0].groupName || ''}
                            onChange={(value)=>{getMember(value)}}>
                            {(group||[]).map((item,index) => <Option 
                                key={index} 
                                value={item.id}
                                >{item.groupName}</Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="member" rules={[{ required: false }]}>
                        <Select 
                            placeholder="巡检员" 
                            className='searchBox_select' 
                            value={inspectionMember&&inspectionMember[0]&&inspectionMember[0].userName || ''}
                            onChange={(value)=>{setMember(value.toString())}}>
                            {(inspectionMember||[]).map((item,index) => 
                                <Option 
                                    key={index} 
                                    value={item.userName}
                                    >{'a' || item.userName}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col span={16}>
                    <Form.Item>
                        <Input placeholder="请输入巡检员名字或账号" 
                            style={{backgroundColor:' rgba(50, 197, 255, 0.4)',border:0,color:'white'}} 
                            value={member}
                            onChange={(e)=>{
                                setShowMemberList(false)
                                setMember(e.target.value)
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" 
                            className="login-form-button"
                            onClick={()=>{getMemberList()}}
                        >查询</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        {showMemberList && <div className='search_memberList'>
            {(memberList||[]).map((item,index) => 
                <div key={index} onClick={()=>{props.selectedMember(item.subAreaId,item.userId)}}>{item.mobile}</div>
            )}
        </div>}
    </div>
}
