import { HttpClientImmidIot } from "./request/HttpClientImmidIot"
import { callback } from './model'

// 停车场
class ParkingModel {
    
    // 今日城市概况
    static cityProfile(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/overview'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日停车概况
    static parkingProfile(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/space/overview'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日违停概况
    static violationProfile(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/parkWarnType/times'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 停车时长分布
    static parkingTime(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/duration'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 支付方式分布
    static paymentMethods(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/payRource'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 营收分析
    static revenueAnalysis(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/incomeAnalysis'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 车流统计
    static trafficFlow(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/intOutTimes'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 今日用户概况
    static userInfo(param: {}, callback: callback){
        const url = '/parking-report/member/report/getTodayInfo'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 各区泊位占比
    static berthsProportion(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/space/parkingSpaceRateByArea'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }


    // 违停次数统计|今日泊位利用率
    static violationTimes(param: {parkingId?: string, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/common/today'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 本月泊位利用率
    static berthThisMonth(param: {parkingId?: string, statisticsTime?:Date}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/common/month'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 同车牌月停车次数
    static monthlyParkingTimes(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/parkTime'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    // 每小时占用率
    static hourlyOccupancyRate(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/common'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    static realtimeTrafficFlow(param: {parkingId?: string}, callback: callback){
        const url = '/parking-report/dataVisualizations/parking/verkehrsfluss'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }

    static parkingMap(param: {parkingId?: string}, callback: callback){
        const url = '/parking-resource/admin/resource/parking/space/dataVisualizations/list'
        HttpClientImmidIot.query(url, 'GET', param, callback)
    }
}

export default ParkingModel