
export const mapProps = {
    amapkey:'b71d45d9d85389a755ed26039230bfda',
    version:'1.4.15'
}

const areaInf = { level:'top',adcode: '341200', areaName:'颍州区' }
const {adcode,areaName} = areaInf

// 定位到指定地点
export const location = (mapInstance) => {
    mapInstance.setCity(areaName)     
    PubSub.publish('mapParking', areaInf)
    localStorage.setItem('cityCode',adcode)
}

// 只显示城市地图
export const showCityMap = (map) => {
    window.AMap.plugin(['AMap.DistrictSearch'],()=>{
        new window.AMap.DistrictSearch({
            extensions:'all',
            subdistrict:0
        }).search('阜阳市',function(status,result){
            // 外多边形坐标数组和内多边形坐标数组
            const outer = [
                new AMap.LngLat(-360,90,true),
                new AMap.LngLat(-360,-90,true),
                new AMap.LngLat(360,-90,true),
                new AMap.LngLat(360,90,true),
            ];
            const holes = result.districtList[0].boundaries
     
            const pathArray = [
                outer
            ];
            pathArray.push.apply(pathArray,holes)
            const polygon = new window.AMap.Polygon( {
                pathL: pathArray,
                //线条颜色，使用16进制颜色代码赋值。默认值为#006600
                strokeColor: 'rgb(20,164,173)',
                strokeWeight: 4,
                //轮廓线透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
                strokeOpacity:0.5,
                //多边形填充颜色，使用16进制颜色代码赋值，如：#FFAA00
                fillColor: '#052281',
                //多边形填充透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
                fillOpacity: 1,
                //轮廓线样式，实线:solid，虚线:dashed
                strokeStyle:'dashed',
                /*勾勒形状轮廓的虚线和间隙的样式，此属性在strokeStyle 为dashed 时有效， 此属性在    
                  ie9+浏览器有效 取值： 
                  实线：[0,0,0] 
                  虚线：[10,10] ，[10,10] 表示10个像素的实线和10个像素的空白（如此反复）组成的虚线
                  点画线：[10,2,10]， [10,2,10] 表示10个像素的实线和2个像素的空白 + 10个像素的实 
                  线和10个像素的空白 （如此反复）组成的虚线*/
                strokeDasharray:[10,2,10,null]
            });
            polygon.setPath(pathArray);
            map.add(polygon);
           /*   const polyline = new AMap.Polyline({
            strokeColor: '#3366FF',   // 线颜色
            strokeOpacity: 1,         // 线透明度
            strokeWeight: 2,          // 线宽
            strokeStyle: 'solid',     // 线样式
            strokeDasharray: [10, 5], // 补充线样式
            geodesic: false            // 绘制大地线
        });
           polyline.setPath(pathArray);
           map.add(polyline);*/  
        })
    
    })
    
}