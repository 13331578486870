import echarts from "echarts"
import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import { MonthIncomes } from '../../../model/model'
import ParkingModel from '../../../model/parkingModel'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../../model/SystemFunction'
import { MapParking } from '../statistics'
import {  IncomeComparison,MonthData } from '../../../model/outRoadModelInterface'
import { income_comparison } from '../../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../../model/outRoadModelRequest'
import outRoadParkingDetailModelRequest from "../../../model/outRoadParkingDetailModelRequest";

interface Props{
    type?: 'parking'|'incomeComparison'|'detailIncomeComparison'
}
interface State{
    revenueAnalysis?: {
        curMonthIncomes: Array<MonthIncomes>,
        lastMonthIncomes: Array<MonthIncomes>,
    } ,
    incomeComparison?: {
        thisMonth: Array<MonthData>,
        lastMonth: Array<MonthData>,
    } ,
    detailIncomeComparison?: {
        thisMonth: Array<MonthData>,
        lastMonth: Array<MonthData>,
    } ,
}
export class RevenueAnalysis extends PureComponent<Props,State> {
    timer: NodeJS.Timeout | number;
    constructor(props:any){
        super(props);
        this.state = {
            revenueAnalysis: null,
            incomeComparison: null,
            detailIncomeComparison:null
        }
    }

    componentDidMount = () => {
        switch (this.props.type) {
            case 'parking':
                this.getRevenueAnalysis()
                this.timer = setInterval(()=>{
                    this.getRevenueAnalysis()
                }, 60*1000)
                break;
            case 'incomeComparison':
                this.getIncomeComparison()
                this.timer = setInterval(()=>{
                    this.getIncomeComparison()
                }, 60*1000)
                break;
            case 'incomeComparison':
                this.getIncomeComparison()
                this.timer = setInterval(()=>{
                    this.getIncomeComparison()
                }, 60*1000 )
                break;
            case 'detailIncomeComparison':
                this.getDetailIncomeComparison();
                this.timer = setInterval(()=>{
                    this.getDetailIncomeComparison();
                }, 60*1000);
                break;

            default:
                this.getRevenueAnalysis({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                this.timer = setInterval(()=>{
                    this.getRevenueAnalysis({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getRevenueAnalysis(info)
                    this.timer = setInterval(()=>{
                        this.getRevenueAnalysis(info)
                    }, 60*1000 )
                })
                break;
        }
    }

    componentWillUnmount = () => {
        clearInterval(Number(this.timer))
        PubSub.unsubscribe('mapParking');
    }

    // 获取营收分析数据
    getRevenueAnalysis = (info?: MapParking) => {
        switch (this.props.type) {
            case 'parking':
                const parkingId = localStorage.getItem('parkingId')
                ParkingModel.revenueAnalysis({parkingId},(d, type)=>{
                    if (type === HttpClientImmidIot.requestSuccess) {
                        //成功-------在这里做你的数据处理，需要提示的自己加
                        if (d.success) {
                            this.setState({revenueAnalysis:d.data})
                        }
                    } else {
                        //失败----做除了报错之外的操作
                        console.error('获取营收分析数据失败')
                    }
                })
                break;
            default:
                const model = MapModel[info.level]
                const parm = MapModelParm[info.level]
                model.revenueAnalysis({[parm]:info.adcode},(d, type)=>{
                    if (type === HttpClientImmidIot.requestSuccess) {
                        //成功-------在这里做你的数据处理，需要提示的自己加
                        if (d.success) {
                            this.setState({revenueAnalysis:d.data})
                        }
                    } else {
                        //失败----做除了报错之外的操作
                        console.error('获取营收分析数据失败')
                    }
                })
                break;
        }

    }
    //右3   营收统计
    getIncomeComparison=() => {
        OutRoadModelRequest.incomeComparison(null, (d, type) => {
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({ incomeComparison: d.data })
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取营收分析数据失败')
            }
        })
    }


    //右2  单个停车场--停车详情---营收
    getDetailIncomeComparison(){
        const parkingId = localStorage.getItem('DETAIL_PARKING_ID');
        outRoadParkingDetailModelRequest.detailIncomeComparison({parkingId:parseInt(parkingId)}, (d, type) => {
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({detailIncomeComparison:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取营收分析数据失败')
            }
        })

    }

    getEChartsData= ()=>{
        let xdata = ["1日","3日","5日","7日","9日","11日","13日"],
            curData = [0,0,0,0,0,0,0],
            lastData = [0,0,0,0,0,0,0];
        let max = 9

         switch (this.props.type) {
             case 'incomeComparison':
                const {incomeComparison} = this.state
                if(incomeComparison){
                    const {thisMonth,lastMonth} = incomeComparison
                    xdata = (thisMonth.length>0&&thisMonth || lastMonth.length>0&&lastMonth || []).map((item,index)=>new Date(item.statisticsTime).getDate()+'日')
                    curData = thisMonth.map((item,index)=>item.payAmount)
                    lastData = lastMonth.map((item,index)=>item.payAmount)
                    max = Math.max(...curData,...lastData)
                }
                break;
             case 'detailIncomeComparison':
                 const {detailIncomeComparison} = this.state
                 if(detailIncomeComparison){
                     const {thisMonth,lastMonth} = detailIncomeComparison
                     xdata = (thisMonth.length>0&&thisMonth || lastMonth.length>0&&lastMonth || []).map((item,index)=>new Date(item.statisticsTime).getDate()+'日')
                     curData = thisMonth.map((item,index)=>item.payAmount)
                     lastData = lastMonth.map((item,index)=>item.payAmount)
                     max = Math.max(...curData,...lastData)
                 }
                 break;
            default:
                const {revenueAnalysis} = this.state
                if(revenueAnalysis){
                    const {curMonthIncomes,lastMonthIncomes} = revenueAnalysis
                    xdata = (curMonthIncomes.length>0&&curMonthIncomes || lastMonthIncomes.length>0&&lastMonthIncomes || []).map((item,index)=>new Date(item.statisticsTime).getDate()+'日')
                    curData = curMonthIncomes.map((item,index)=>item.inCome)
                    lastData = lastMonthIncomes.map((item,index)=>item.inCome)
                    max = Math.max(...curData,...lastData)
                }
                break;
         }
          let echartsData={
              max:max,
              xdata:xdata,
              curData:curData,
              lastData:lastData
          }
          return echartsData
    }

    getOptions = () => {
         const echartsData = this.getEChartsData()
        return {
            tooltip: {
                trigger: 'axis' as 'axis',
                axisPointer: {
                    lineStyle: {
                        color: {
                            type: 'linear' as 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(0, 255, 233,0)'
                            }, {
                                offset: 0.5,
                                color: 'rgba(255, 255, 255,1)',
                            }, {
                                offset: 1,
                                color: 'rgba(0, 255, 233,0)'
                            }],
                            global: false
                        }
                    },
                },
            },
            grid: {
                top: '15%',
                left: `${echartsData.max.toString().split('.')[0].length*3+3}%`,
                right: '5%',
                bottom: '15%',
            },
            xAxis: [{
                type: 'category' as 'category',
                axisLine: {
                    show: false,
                    color:'#32C5FF'
                },
                axisLabel: {
                    color: '#35A1F0',
                    width:100
                },
                splitLine: {
                    show: false
                },
                boundaryGap: false,
                data: echartsData.xdata//this.$moment(data.times).format("HH-mm") ,
            }],
            yAxis: [{
                type: 'value' as 'value',
                min: 0,
                // max: 140,
                splitNumber: 4,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#35A1F0',
                        opacity:0.23
                    }
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    show: true,
                    margin: 5,
                    textStyle: {
                        color: '#35A1F0',

                    },
                },
                axisTick: {
                    show: false,
                },
            }],
            series: [
                {
                    name:'当月营收',
                    type: 'line',
                    showSymbol: false, // 鼠标移入才显示圆点
                    lineStyle: {
                        normal: {
                            color: "#36C3FA",
                        },
                    },
                    itemStyle: {
                        color: "#36C3FA",
                        borderWidth: 0,
                    },
                    areaStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#64CAFA' // 0% 处的颜色
                            }, {
                                offset: 0.5,
                                color: '#64CAFA' // 100% 处的颜色
                            }, {
                                offset: 1,
                                color: '#0078D7' // 100% 处的颜色
                            }], false),
                        }
                    },
                    data: echartsData.curData//data.values
                },
                {
                    name:'上月营收',
                    type: 'line' as 'line',
                    showSymbol: false, // 鼠标移入才显示圆点

                    lineStyle: {
                        normal: {
                            color: "#966BFF",
                        },
                    },
                    itemStyle: {
                        color: "#966BFF",
                        borderWidth: 0,
                    },
                    areaStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#966BFF'
                                },
                                {
                                    offset: 1,
                                    color: '#4346D8'
                                }
                            ], false),
                        }
                    },
                    data: echartsData.lastData//data.values
                },
            ]
        }
    }

    render() {
        // @ts-ignore
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default RevenueAnalysis
