import { message } from "antd";
import "babel-polyfill";
import _ from 'lodash';
import moment from 'moment';
import React from "react";
import { render } from 'react-dom';
import { getUserIP,login } from '../src/model/SystemFunction';
import CityModel from "./model/cityModel";
import { HttpClientImmidIot } from "./model/request/HttpClientImmidIot";
import { customCookie } from "./model/request/Util";
import Routes from './route';
// web端屏幕自适应
function setHtmlFontSize () {
    const deviceWidth = document.documentElement.clientWidth > 1920 ? 1920 : document.documentElement.clientWidth
    document.documentElement.style.fontSize = deviceWidth / 19.20 + 'px'
}
setHtmlFontSize();
window.onresize = setHtmlFontSize;

// moment多语言支持
moment.locale('zh-cn'); 

// login()
  
render(
    <Routes />,
    document.getElementById('app')
);