import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import { parking_timeData } from '../../../model/modelData'
import CityModel from '../../../model/cityModel'
import { BerthsProportion, InspectorsProportion, Manufacturer, ParkingTimeData, PartitionEquipment } from '../../../model/model'
import ParkingModel from '../../../model/parkingModel'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../../model/SystemFunction'
import { MapParking } from '../statistics'
import {  ClassifyRate } from '../../../model/outRoadModelInterface'
import { classify_rate } from '../../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../../model/outRoadModelRequest'

interface Props{
    seriesName?:string
    type: 'parkingTimeDistribution' | 'manufacturer' | 'partitionEquipment' | 'monthlyParkingTimes' | 'incidentsProportion' | 'inspectorsProportion' | 'berthsProportion' | 'parkingTimeDistributionOfPark'|'classfyRate'
}
interface State{
    showLegend: boolean,
    parkingTimeData?: Array<ParkingTimeData>,
    berthsProportion?: Array<BerthsProportion>,
    inspectorsProportion?: Array<InspectorsProportion>,
    incidentsProportion?: Array<InspectorsProportion>,
    manufacturerProportion?: Array<Manufacturer>,
    partitionEquipment?: Array<PartitionEquipment>
    classfyRate?: Array<ClassifyRate>
}
export class ParkingTime extends PureComponent<Props,State> {
    timer: NodeJS.Timeout | number

    constructor(props:Props){
        super(props)
        this.state = {
            showLegend: false,
            parkingTimeData: parking_timeData,
            berthsProportion: [],
            inspectorsProportion: [
                {subAreaId: 0, subAreaName: '', total: 0  }
            ],
            incidentsProportion: [
                {subAreaId: 0, subAreaName: '0', total: 0  }
            ],
            manufacturerProportion: [],
            partitionEquipment: [],
            classfyRate:[],
        }
    }

    componentDidMount(){
        switch(this.props.type){
            case 'parkingTimeDistribution': 
                this.setState({showLegend: true});
                this.getParkingTime({level:'top',adcode:localStorage.getItem('cityCode') || '440300'}); 
                this.timer = setInterval(()=>{
                    this.getParkingTime({level:'top',adcode:localStorage.getItem('cityCode') || '440300'});
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getParkingTime(info)
                    this.timer = setInterval(()=>{
                        this.getParkingTime(info)
                    }, 60*1000 )
                })
                break;
            case 'parkingTimeDistributionOfPark':
                this.setState({showLegend: false});
                this.getParkingTimeOfPark(); 
                this.timer = setInterval(()=>{
                    this.getParkingTimeOfPark();
                }, 60*1000 )
                break;
            case 'berthsProportion': 
                this.getBerthsProportion({level:'top',adcode:localStorage.getItem('cityCode') || '440300'}); 
                this.timer = setInterval(()=>{
                    this.getBerthsProportion({level:'top',adcode:localStorage.getItem('cityCode') || '440300'});
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getBerthsProportion(info)
                    this.timer = setInterval(()=>{
                        this.getBerthsProportion(info)
                    }, 60*1000 )
                })
                break;
            case 'inspectorsProportion':
                this.getInspectorsProportion({level:'top',adcode:localStorage.getItem('cityCode') || '440300'}); 
                this.timer = setInterval(()=>{
                    this.getInspectorsProportion({level:'top',adcode:localStorage.getItem('cityCode') || '440300'});
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getInspectorsProportion(info)
                    this.timer = setInterval(()=>{
                        this.getInspectorsProportion(info)
                    }, 60*1000 )
                })
                break;
            case 'incidentsProportion':
                this.getIncidentsProportion({level:'top',adcode:localStorage.getItem('cityCode') || '440300'}); 
                this.timer = setInterval(()=>{
                    this.getIncidentsProportion({level:'top',adcode:localStorage.getItem('cityCode') || '440300'});
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getIncidentsProportion(info)
                    this.timer = setInterval(()=>{
                        this.getIncidentsProportion(info)
                    }, 60*1000 )
                })
                break;
            case 'manufacturer':
                this.getManufacturerProportion()
                this.timer = setInterval(()=>{
                    this.getManufacturerProportion()
                }, 60*1000 )
                break;
            case 'partitionEquipment':
                this.getPartitionEquipment()
                this.timer = setInterval(()=>{
                    this.getPartitionEquipment()
                }, 60*1000 )
                break;
            case 'classfyRate':
                this.setState({showLegend: false});
                this.getClassfyRate(); 
                this.timer = setInterval(()=>{
                    this.getClassfyRate();
                }, 60*1000)
                break;
            default : ;
        }
    }

    componentWillUnmount(){
        clearInterval(Number(this.timer))
        PubSub.unsubscribe('mapParking');
    }

    // 获取停车时长分布数据
    getParkingTime = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.parkingTime({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({parkingTimeData:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取停车时长分布数据失败')
            }
        })
    }

    // 获取停车时长分布数据
    getParkingTimeOfPark = () => {
        const parkingId = localStorage.getItem('parkingId')
        ParkingModel.parkingTime({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({parkingTimeData:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取停车时长分布数据失败')
            }
        })
    }

    // 获取各区泊位占比数据
    getBerthsProportion = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.berthsProportion({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({berthsProportion:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取各区泊位占比数据失败')
            }
        })
    }

    // 获取片区巡检员占比数据
    getInspectorsProportion = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.inspectorsProportion({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({inspectorsProportion:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取片区巡检员占比数据失败')
            }
        })
    }

    // 获取片区巡检事件占比数据
    getIncidentsProportion = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.incidentsProportion({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({incidentsProportion:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取片区巡检事件占比数据失败')
            }
        })
    }

    //厂商设备占比统计
    getManufacturerProportion = () => {
        CityModel.manufacturerProportion({timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    this.setState({manufacturerProportion:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取厂商设备占比统计数据失败')
            }
        })
    }

    // 设备分区占比
    getPartitionEquipment = () => {
        CityModel.partitionEquipment({timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    this.setState({partitionEquipment:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取厂商设备占比统计数据失败')
            }
        })
    }    
    // 车场类型统计
    getClassfyRate = () => {
        OutRoadModelRequest.classifyRate(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
               if (d.success&&d.data.length>0) {
                    this.setState({classfyRate:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取车场类型统计失败')
            }
        })
    }

    getEChartsData = () => {
        let echartsData: { value: number; name: string; itemStyle: { color: string } }[] = []
        const colors = ['#F7B34C','#2BCDFF','#5E5BFB','#2BCDFF','#E45374']
        const names = ['0-1小时','1-2小时','2-4小时','4-8小时','8小时以上']
        const {parkingTimeData,berthsProportion,inspectorsProportion,incidentsProportion,manufacturerProportion,partitionEquipment} = this.state
        switch(this.props.type){
            case 'parkingTimeDistributionOfPark':
            case 'parkingTimeDistribution': 
                echartsData = names.map((item,index)=>{
                    return {
                        value: parkingTimeData[index]&&parkingTimeData[index].time || 0,
                        name: item,
                        itemStyle: {
                            color: colors[index],
                        }
                    }
                }); break;
            case 'berthsProportion': 
                echartsData = berthsProportion.map((item,index)=>{
                    return {
                        value: item.count,
                        name: item.areaName,
                        itemStyle: {
                            color: colors[index],
                        }
                    }
                }); break;
            case 'inspectorsProportion':
                echartsData = inspectorsProportion.map((item,index)=>{
                    return {
                        value: item.total ,
                        name: item.subAreaName ,
                        itemStyle: {
                            color: colors[index],
                        }
                    }
                }); break;
            case 'incidentsProportion':
                echartsData = incidentsProportion.map((item,index)=>{
                    return {
                        value: item.total ,
                        name: item.subAreaName ,
                        itemStyle: {
                            color: colors[index],
                        }
                    }
                }); break;
            case 'manufacturer':
                echartsData = manufacturerProportion.map((item,index)=>{
                    return {
                        value: item.quantity ,
                        name: item.vendor_name ,
                        itemStyle: {
                            color: colors[index],
                        }
                    }
                });
                break;
            case 'partitionEquipment':
                echartsData = partitionEquipment.map((item,index)=>{
                    return {
                        value: item.quantity ,
                        name: item.area_name ,
                        itemStyle: {
                            color: colors[index],
                        }
                    }
                });
                break;            
            case 'classfyRate':
                const {classfyRate} = this.state
                const parkingType= {1:"商业", 2:"住宅", 3:"医院", 4:"园区", 5:"交通枢纽", 6:"行政机关", 7:"学校", 8:"其它"}
                echartsData = classfyRate.map((item,index)=>{
                    return {
                        value: item.rate ,
                        name: parkingType[item.classify],
                        itemStyle: {
                            color: colors[index],
                        }
                    }
                });
                break;
            default : ;
        }
        return echartsData
    }
    getToolTip = () => {
        let tooltip = {}
        switch (this.props.type) {
            case 'classfyRate':
                tooltip = {
                    trigger: 'item'as 'item',
                    formatter: '{a} <br/>{b} : {c}%'
                  }
              break;
            default:
                tooltip = {
                    trigger: 'item'as 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                }
        }
        return tooltip
    }
    getOptions = () => {
        const echartsData = this.getEChartsData()

        return {
            tooltip: this.getToolTip(),
            legend: {
                show: this.state.showLegend,
                icon: "circle",
                itemHeight: 7,
                itemWidth: 5,
                orient: 'horizontal' as 'horizontal',
                x: 'center',
                textStyle: {
                    color: "#fff",
                    fontSize: 12,
                },
                itemGap: 10,
                bottom: 0,
                data: echartsData.map(item=>item.name)
            },
            series: [
                {
                    name: this.props.seriesName || '停车时长分布',
                    type: 'pie',
                    radius: this.props.type != 'parkingTimeDistribution' ? '75%' : '60%',
                    center: ['50%', '50%'],
                    selectedMode: 'single',
                    data: echartsData,
                    labelLine: {
                        normal: {
                            length: 10,
                            length2: 30,
                            lineStyle: {
                                color: '#35A1F0'
                            }
                        }
                    },
                    label: {
                        normal: {
                            formatter: (params: { name: string; percent: number; value: any }) => {
                                return (
                                    '{icon|●}{name|' + params.name + '}'
                                );
                            },
                            padding: [0, -30, 20, -30],
                            rich: {
                                color: '#333',
                                icon: {
                                    fontSize: 12
                                },
                                name: {
                                    fontSize: 12,
                                    padding: [0, 5, 0, 5],
                                    color: '#FFFFFF'
                                }
                            }
                        }
                    },
                }
            ]
        }
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default ParkingTime
