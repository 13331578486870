
// 今日城市概况
export const city_profile = {
    cityId: '', //城市编码
    areaId: '',
    cityName: '',   //城市名称
    areaName: '',
    parkingId: '',     //路段id
    parkingName: '',
    streetName: '',    //街道
    parkingDesc: '',   //路段地址范围描述
    curMonthParkingIncome: '',  //当月停车总收入 （两位小数）
    curMonthParkingTimesTotal: 0,  //当月停车总次数
    curYearParkingIncome: '',   //今年停车总收入（两位小数）
    curYearParkingTimesTotal: 0,   //今年停车总次数
    parkingSpaceTotal: 0,  //泊位总数
    parkingTotalCount: 0,  //停车场总数
    spaceUsageRate: 0, //泊位使用率（停车中泊位/总泊位）
    todayParkingIncome: '',   //今天停车收入（两位小数）
    todayParkingTimesTotal: '', //今天停车总次数
    todayArrearageAmount: 0, //今年欠费（两位小数）
    turnover: 0,    //周转率
    parkWarnTimes: 0   //今日违停次数
}

// 今日停车概况
export const parking_profile={
    totalSpaceCount: 41,    //泊位总数
    usedSpaceCount: 2,     //泊位当前占用数
    usedParkingCount: 9,
    freeSpaceCount: 39,     //泊位当前空闲数
    parkTimes: 0,          //今日停车次数
    averageParkDuration: 0,       //平均停车时长（单位分钟）
    usageRate: 4,          //泊位占用率 （usedSpaceCount/ totalSpaceCount）
    totalParkingCount: 9   //停车场总数
}

// 停车时长分布
export const parking_timeData=[
    {
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1605036600000,
        "duration":1,
        "id":216,
        "statisticsTime":1604160000000,
        "time":2,
        "updateTime":1605036600000
    },
    {
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1605036600000,
        "duration":2,
        "id":217,
        "statisticsTime":1604160000000,
        "time":0,
        "updateTime":1605036600000
    },
    {
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1605036600000,
        "duration":3,
        "id":218,
        "statisticsTime":1604160000000,
        "time":0,
        "updateTime":1605036600000
    },
    {
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1605036600000,
        "duration":4,
        "id":219,
        "statisticsTime":1604160000000,
        "time":1,
        "updateTime":1605036600000
    },
    {
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1605036600000,
        "duration":5,
        "id":220,
        "statisticsTime":1604160000000,
        "time":3,
        "updateTime":1605036600000
    }
]

// 支付方式分布
export const payment_methodsData={
    cityId: '',     //城市编码
    cityName: '',       //城市名称
    areaId: '',         //城区编码
    areaName: '',       //城区名称
    partnerCompanyId: '',  //合作公司id
    partnerCompanyName: '',    //合作公司名称
    createTime: 0,     //记录创建时间
    payType: 0,        //支付类型 0:钱包1:微信公众号2:人工支付宝3:人工微信
    id: 0,             //记录id
    statisticsTime: 0,     //统计的时间
    payTimes: 0,               //出现次数
    updateTime: 0          //统计更新时间
}

// 营收分析
export const month_incomes=[
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":1,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-18T03:00:00",
        "id":1,
        "inCome":7.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":0,
        "parkingTotalCount":0,
        "payTimes":1,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-17T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-18T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-19T03:00:00",
        "id":2,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":0,
        "parkingTotalCount":0,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-18T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-19T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-20T03:00:00",
        "id":3,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":0,
        "parkingTotalCount":0,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-19T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-20T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-21T03:00:00",
        "id":4,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":0,
        "parkingTotalCount":0,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-20T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-21T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-22T03:00:00",
        "id":5,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":0,
        "parkingTotalCount":0,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-21T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-22T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-23T03:00:00",
        "id":6,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":0,
        "parkingTotalCount":0,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-22T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-23T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-24T03:00:00",
        "id":7,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":24,
        "parkingTotalCount":4,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-23T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-24T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-25T03:00:00",
        "id":8,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":24,
        "parkingTotalCount":4,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-24T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-25T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-26T03:00:00",
        "id":9,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":24,
        "parkingTotalCount":4,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-25T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-26T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-27T03:00:00",
        "id":10,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":24,
        "parkingTotalCount":4,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-26T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-27T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-28T03:00:00",
        "id":11,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":24,
        "parkingTotalCount":4,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-27T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-28T03:00:00"
    },
    {
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "averageUsageRate":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":"2020-09-29T03:00:00",
        "id":12,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkTimesCount":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":24,
        "parkingTotalCount":4,
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":"2020-09-28T00:00:00",
        "turnover":0,
        "updateTime":"2020-09-29T03:00:00"
    }
]

// 区域|停车场营收分析
export const revenue_data=[
    {
        "areaId":440305,
        "areaName":"南山区",
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1602205320000,
        "id":836,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkWarnTimes":0,
        "parkingSpaceTotal":24,
        "parkingTotalCount":4,
        "partnerCompanyId":1,
        "partnerCompanyName":"深圳锦沃科技有限公司",
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":1,
        "statisticsTime":1602201600000,
        "turnover":0,
        "updateTime":1602205320000
    }
]

// 车流统计
export const traffic_data={
    arrearageAmount: 0,
    arrearageTimes: 0,
    cityId: 0,
    cityName: '',
    areaId: 0,     //区域id
    areaName: '',   //区域名称
    createTime: '',     //记录创建时间
    id: 0,             //记录id
    inCome: 0,
    inTimes: 0,        //入场次数
    outTimes: 0,       //离场次数
    parkDuration: 0,
    parkWarnTimes: 0,
    parkingSpaceTotal: 0,
    parkingTotalCount: 0,
    payTimes: 0,
    refundAmount: 0,
    refundTimes: 0,
    spaceUsageRate: 0,
    statisticsTime: '',     //统计的时间
    turnover: 0,
    updateTime: ''          //统计更新时间
}

// 今日用户概况
export const user_info={
    activeUserCount: 2,    //活跃用户数（0:00-24:00有登陆操作）
    newUserCount: 3,       //新增用户数（0:00-24:00注册）
    parkUserCount: 2,      //正在停车用户数（0:00-24:00车未离场）
    totalCount: 7          //总用户数
}

// 各区泊位占比
export const berths_proportion={
    areaId: 0,
    areaName: '',
    count: 0       //泊位占比（两位小数）
}

// 本月停车时长top5泊位 // 今日停车时长top5路段
export const parkingTime_top5_thisMonth={
    parkDuration: 0,       //停车时长
    parkingId: 0,          //路段id
    parkingName: ''         //路段名称
}

// 本月停车时长top5路段（每天）
export const Parkingtime_top5_thisMonthRoad={
    parkingTopDurations: [parkingTime_top5_thisMonth],
    statisticsTime: new Date()
}

// 车流统计  // 本月泊位利用率
export const violation_times={
    cityId: 0,
    cityName: '',
    createTime: 0,
    id: 0,
    statisticsTime: new Date(),   //统计的时间
    parkWarnTimes: 0,  //违停次数
    updateTime: new Date(),        //统计更新时间
    turnover: 0        //泊位利用率
}

// 实时车流
export const realTime_traffic={
    areaId: '',         //区域编码
    areaName: '',       //区域名称
    cityId: '',         //城市编码
    cityName: '',       //城市名称
    parkingId: 0,      //路段id
    parkingName: '',    //路段名称
    parkingSpaceNo: 0, //泊位号
    parkingSpaceStatus: 0,     //出入场，0 出场，1 ,3入场
    updateTime: new Date()        //出入场时间
}

 // 今日巡检概况
export const resource_profile={
    inspectionGroupMemberTotal: 0,     //巡检员总数
    inspectionGroupTotal: 0,           //巡查组总数
    inspectionMemberActiveTotal: 0,    //巡检员在线人数
    parkWarnDisposedTotal: 0,          //派单完成总数
    parkWarnTotal: 0,                  //派单总数
    pdaTotal: 0,                       //PDA总数
    subAreaTotal: 0                    //片区总数
}

// 片区巡检员占比  // 片区巡检事件占比
export const inspectors_proportion={
    subAreaId: 0,      //片区id
    subAreaName: '',    //片区名称
    total: 0           //人数占比
}

// 今日完成派单Top5巡检员
export const completed_inspectors={
    times: 0,      //派单完成次数
    userId: 0,     //巡检员id
    userName: ''    //巡检员名称
}

// 本月派单完成率
export const order_completionRate={
    statisticsTime: new Date(),       //日期
    rate: 0                //完成率
}

// 巡检事件类型占比
export const patrol_eventTypes={
    parkWarnType: 3 | 4 | 6 | 15 | 16,   //巡检事件类型:3:违停处理;4:未交费拍照;6:设备维保;15:辅助入场;16:辅助离场
    rate: 0            //占比
}

export const attendance_times={
    userId: 0,     //巡检员id
    userName: '',   //巡检员名称
    total: 0       //出勤次数
}

// 热点停车区域
export const parking_area=[
    {
        "areaId":440305,
        "areaName":"南山区",
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1601276400000,
        "id":1457,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkWarnTimes":0,
        "parkingId":1,
        "parkingName":"高新南十道",
        "parkingSpaceTotal":0,
        "partnerCompanyId":1,
        "partnerCompanyName":"深圳锦沃科技有限公司",
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":1601272800000,
        "subAreaId":1,
        "subAreaName":"科苑片区",
        "turnover":0,
        "updateTime":1601276400000
    },
    {
        "areaId":440305,
        "areaName":"南山区",
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1601276400000,
        "id":1458,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkWarnTimes":0,
        "parkingId":6,
        "parkingName":"科技南一路",
        "parkingSpaceTotal":8,
        "partnerCompanyId":1,
        "partnerCompanyName":"深圳锦沃科技有限公司",
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":1601272800000,
        "subAreaId":2,
        "subAreaName":"粤海片区",
        "turnover":0,
        "updateTime":1601276400000
    },
    {
        "areaId":440305,
        "areaName":"南山区",
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1601276400000,
        "id":1459,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkWarnTimes":0,
        "parkingId":7,
        "parkingName":"科技南二路",
        "parkingSpaceTotal":8,
        "partnerCompanyId":1,
        "partnerCompanyName":"深圳锦沃科技有限公司",
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":1601272800000,
        "subAreaId":2,
        "subAreaName":"粤海片区",
        "turnover":0,
        "updateTime":1601276400000
    },
    {
        "areaId":440305,
        "areaName":"南山区",
        "arrearageAmount":0.00,
        "arrearageTimes":0,
        "cityId":440300,
        "cityName":"深圳市",
        "createTime":1601276400000,
        "id":1460,
        "inCome":0.00,
        "inTimes":0,
        "outTimes":0,
        "parkDuration":0,
        "parkWarnTimes":0,
        "parkingId":8,
        "parkingName":"科技南四路",
        "parkingSpaceTotal":8,
        "partnerCompanyId":1,
        "partnerCompanyName":"深圳锦沃科技有限公司",
        "payTimes":0,
        "refundAmount":0.00,
        "refundTimes":0,
        "spaceUsageRate":0,
        "statisticsTime":1601272800000,
        "subAreaId":2,
        "subAreaName":"粤海片区",
        "turnover":0,
        "updateTime":1601276400000
    }
]

export const city_parkingInf=[
    {
        "areaId":440309,
        "areaName":"龙华区",
        "parkingLatitude":"22.666454",
        "parkingLongitude":"114.037018",
        "parkingSpaceTotal":0,
        "parkingTotalCount":1,
        "spaceUsageRate":0,
        "topSpaceUsageRate":0
    },
    {
        "areaId":440306,
        "areaName":"宝安区",
        "parkingLatitude":"22.571083",
        "parkingLongitude":"113.872266",
        "parkingSpaceTotal":0,
        "parkingTotalCount":1,
        "spaceUsageRate":0,
        "topSpaceUsageRate":0
    },
    {
        "areaId":440307,
        "areaName":"龙岗区",
        "parkingLatitude":"22.698507",
        "parkingLongitude":"114.213194",
        "parkingSpaceTotal":0,
        "parkingTotalCount":1,
        "spaceUsageRate":0,
        "topSpaceUsageRate":0
    },
    {
        "areaId":440304,
        "areaName":"颍州区",
        "parkingLatitude":"22.535421",
        "parkingLongitude":"114.061102",
        "parkingSpaceTotal":9,
        "parkingTotalCount":1,
        "spaceUsageRate":0,
        "topSpaceUsageRate":0
    },
    {
        "areaId":440305,
        "areaName":"南山区",
        "parkingLatitude":"22.52534",
        "parkingLongitude":"113.943113",
        "parkingSpaceTotal":24,
        "parkingTotalCount":4,
        "spaceUsageRate":3,
        "topSpaceUsageRate":12
    },
    {
        "areaId":440303,
        "areaName":"罗湖区",
        "parkingLatitude":"22.546201",
        "parkingLongitude":"114.117655",
        "parkingSpaceTotal":8,
        "parkingTotalCount":1,
        "spaceUsageRate":0,
        "topSpaceUsageRate":0
    }
]

export const area_parkingInf={
    areaId: 0,             //区域编码
    areaName: '',           //区域名称
    freeSpaceCount: 0,     //泊位空闲数
    parkingId: 0,          //停车场id
    parkingLatitude: 0,    //纬度
    parkingLongitude: 0,   //经度
    parkingName: '',        //停车场名称
    spaceUsageRate: 0,     //饱和度
    totalSpaceCount: 0,    //泊位总数
    usedSpaceCount: 0      //泊位使用总数
}

export const violation_profile={
    parkWarnType: 0,       //告警类型3:禁停时段停车4:未付费停车6:黑名单15:逆向停车16:跨泊位停车
    rate: 0                //告警数
}

export const monthly_parkingTimes={
    areaId: 0,         //区域编码  
    areaName: '',       //区域名称
    cityId: 0,         //城市编码
    cityName: '',       //城市名称
    createTime: new Date(),
    id: 0,
    parkingId: 0,      //路段id
    parkingName: '',    //路段名称
    partnerCompanyId: '',
    partnerCompanyName: '',
    statisticsTime: new Date(),   //统计时间
    subAreaId: 0,
    subAreaName: '',
    time: 0,           //这个次数有多少个车牌
    type: 0,       //同一个车牌这个月在这个停车场停车次数类型: 1 1次;2 2次;3 3次;4 4次;5 5次;6 6次以上（含六次）
    updateTime: new Date()
}
export const hourly_occupancyRate={
    cityId: 0,
    cityName: '',
    createTime: new Date(),
    id: 0,
    inTimes: 0,
    income: 0,
    outTimes: 0,
    parkDuration: 0,
    parkWarnTimes: 0,
    parkingSpaceTotal: 0,
    parkingTotalCount: 0,
    partnerCompanyId: 0,
    partnerCompanyName: '',
    payTimes: 0,
    refundAmount: 0,
    refundTimes: 0,
    spaceUsageRate: 0,
    statisticsTime: 0,
    turnover: 0,
    updateTime: 0

}
export const parking_spaceList={
    parkingSpaceId: 0,     //泊位id
    parkingSpaceNo: 0,     //泊位号
    isOccupy: true,          //是否有车true：有false：无
    hasDetector: true,       //是否有车检器。true：有false：无
    plateNumber: 0,        //车牌号
    hasWarning: true,        //是否有告警true：有false：无
    parkWarningList: [],    //告警集合
    parkingSpaceStatus: 0,     //泊位状态0：空闲，1：占用， 2：不可用， 3：无车牌车占用。
    parkOrderId: 0,            //订单号
    parkOrderStatus: 0,        //订单状态0:停车中1:行程结束2:退款中3:欠费中8:预付费十分钟后要超时9:预付费已经超时10:停车进去超过免费时长11:用户缴费后未超出给定的驶离时长（后付费，PDA订单）12:户缴费后超出给定的驶离时长（后付费，PDA订单）
    orderType: 0,              //订单类型0:入场1:预付费2:后付费3:PDA订单（超过免费时长用户不缴费，巡检员拍照的订单）
    warningId: 0,              //当前未拍照告警id
    warningType: 0,            //当前未拍照告警类型0:正常停车 1:到购买时长结束十分钟2:预付费超时3:禁停时段停车4:未付费停车5:长时间停放告警6:黑名单7:缴费后未驶离告警
    warningDisposeStatus: 0,   //当前未拍照告警状态0:未处理1:已处理
    hasPhoto: true,              //是否有未拍照的告警true：有false：无
    parkingTime: 0             //停车时长 单位分钟
}
export const ParkingMap_list={
    parkingId: 0,      //路段id
    parkingName: '',    //路段名称
    parkingState: 0,   //路段状态0：停用1：启用
    hasDetector: 0,    //是否有车检器。弃用true：有false：无
    parkingSpaceList: [parking_spaceList]  //泊位集合
}

export const inspection_events={
    warningType: 0,        //巡检事件类型3:违停处理4:未交费拍照6:设备维保15:辅助入场16:辅助离场
    adminUserId: 0,        //巡检员id
    adminUserName: '',      //巡检员名称
    disposeType: 0,
    id: 0,
    parkingName: '',        //停车场名称
    parkingSpaceNo: 0,     //泊位号
}
export const area_member={
    inspectorMembers: 0,   //巡检员总数
    latitude: 0,           //经度
    longitude: 0,          //纬度
    parkingSpaceTotal: 0,  //泊位总数
    parkingTotalCount: 0,  //停车场总数
    spaceUsageRate: 0,     //平均饱和度
    subAreaId: 0,          //片区id
    subAreaName: '',        //片区名称
    topSpaceUsageRate: 0   //最高饱和度
}
export const inspection_group={
    subAreaId: 0,          //片区id
    subAreaName: '',        //片区名称
    id: 0,                 //稽查组Id
    groupName: ''           //稽查组名称
}
export const inspection_member={
    subAreaId: 0,          //片区id
    subAreaName: '',        //片区名称
    inspectionGroupId: 0,  //稽查组Id
    groupName: '',          //稽查组名称
    parkingId: 0,          //路段Id
    parkingName: '',        //路段名称
    userId: 0,             //巡检员Id
    userName: '',           //巡检员名称
    mobile: ''              //巡检员手机号
}
export const member_info={
    subAreaId: 0,          //片区id
    subAreaName: '',        //片区名称
    inspectionGroupId: 0,  //稽查组Id
    groupName: '',          //稽查组名称
    parkingId: 0,          //路段Id
    parkingName: '',        //路段名称
    userId: 0,             //巡检员Id
    userName: '',           //巡检员名称
    mobile: '',             //巡检员手机号
    parkWarnTotal: 0,      //派单总数
    parkWarnDisposedTotal: 0,      //派单完成数
    workType: 0, //如果为空,则未排班.如果出现4,不会出现123,123可能会同时出现.班次0:早;1：中;2:晚;4：休
    longitude: 0,         //经度
    latitude: 0           //经度
}
export const vendor={
    vendor_code: '',
    vendor_name: ''
}
export const device_inf={
    id: '',
    0: 0,
    hardware_id: '',
    type: '',
    sub_type: '',
    model: '',
    vendor: '',
    connection_type: '',
    gateway_hardware_id: '',
    batch_no: '',
    hardware_version: '',
    firmware_version: '',
    software_version: '',
    time_created: 0,
    activated: true,
    device_status: 0,  
    enabled: true,
    geo_longlat:[0,0]
}
export const equipment_detail={
    id: '',
    hardware_id: 0,
    mac: '',
    type: '',
    sub_type: '',
    model: '',
    vendor: '',
    connection_type: '',
    gateway_hardware_id: '',
    sim_id: '',
    batch_no: '',
    hardware_version: '',
    firmware_version: '',
    software_version: '',
    time_created: '',
    activated: true,
    device_status: 0,
    enabled: true,
    deployment_status: '',
    ip: '',
    time_deployed: '',
    geo_longlat:[
        0,
        0
    ],
    deployment_address: '',
    bound: true,
}
// export type device_status = 'ONLINE' | 'OFFLINE' | 'MALFUNC' | 'UNKNOWN'
export const manufacturer={
    vendor_code: '',
    vendor_name: '',
    quantity: 0
}
export const equipment_trend={
    month: '',
    quantity: 0
}
export const partition_equipment={
    area_name: '',
    quantity: 0
}
export const equipment_reportTimes={
    '1':{
        enter: 0,
        exit: 0,
        heartbeat: 0
    }
}
export const equipment_overview={
    online: 0,   //在线数量
    offline: 0,  //离线数量
    malfunc: 0,  //故障数量
    unknown: 0   //未知数量
}

export const equipmentType={
    sub_type: '',       //设备类型
    sub_type_name: '',  //设备类型名称
    quantity: 0        //设备数量
}

export const equipment_workingStatus={
    '1':equipment_overview
}
export const device_indicators={
    enter: 0,
    exit: 0,
    heartbeat: 0
}