import { message } from 'antd';
import $ from 'jquery';
import { login } from '../SystemFunction';
import { customCookie, getQueryString } from "./Util";

export const HttpClientImmidIot = (function () {
    //请求类型【truth,mock】
    // const REQUEST = "mock";
    const REQUEST = "truth";

    const requestSuccess = 0;
    const requestDataError = 1;
    const requestServiceError = 2;

    //获取请求host
    let httpClientHost = '',baseUrl='';
    switch (process.env.NODE_ENV) {
        case "development":
            //开发环境
            // httpClientHost = 'https://tripark.triplego.com.cn/api'; //开发环境
            // baseUrl = 'https://iotdev.triplego.com.cn/admin'; //开发环境
            // httpClientHost = 'https://tripark.genvo.cn/api'; //演示环境
            // baseUrl = 'https://iot.genvo.cn/admin'; //演示环境
            httpClientHost = 'https://fytripark.genvo.cn/api'; //演示环境
            baseUrl = 'https://fyiot.genvo.cn/admin'; //演示环境
            // httpClientHost = 'https://127.0.0.1/api'
            break;
        case "production":
            //大树 生产环境
            // httpClientHost = 'https://tripark.triplego.com.cn/api'; //开发环境
            // baseUrl = 'https://iotdev.triplego.com.cn/admin'; //开发环境
            // httpClientHost = 'https://tripark.genvo.cn/api'; //演示环境
            // baseUrl = 'https://iot.genvo.cn/admin'; //演示环境
            httpClientHost = 'https://fytripark.genvo.cn/api'; //演示环境
            baseUrl = 'https://fyiot.genvo.cn/admin'; //演示环境
            // httpClientHost = 'https://127.0.0.1/api'
            break;
        default:
            break;
    }

    function query(url:string, rtype:string, data: any, 
        callback: (arg0: JQuery.jqXHR<any>, arg1: number) => void, 
        host?:boolean,
        contentType = 'application/json;charset=UTF-8', 
        processData = true,) {

        let headers:any = null;
        let header_token = null;
        const isLogin = (url.indexOf('/parking-authority/admin/token') > -1) ||
            (url.indexOf('/parking-authority/configureInfo/getLogoConfig') > -1) 
        if (isLogin) { // 判断是否是登录接口
            header_token = "Basic Y29uc29sZTpjb25zb2xl";
        } else {
            let access_token =(customCookie.get('access_token') || '');
            const str = new URLSearchParams('?' + window.location.href.split('?')[1]);
            const token = str.get('access_token')
            if(token&&window.customCookie){
                access_token = token
                window.customCookie.set('access_token',token)
            }
            header_token = "Bearer " + access_token;
            // header_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJzdXBlcjoxIiwid3hPcGVuSWQiOm51bGwsIm5pY2tOYW1lIjoic3VwZXIiLCJwYXJ0bmVyQ29tcGFueUlkIjowLCJtb2JpbGUiOiIxMjIyMjIyMjIyMyIsInVzZXJJZCI6MSwiYXV0aG9yaXRpZXMiOlsiMSIsInVzZXJJZCA6MSJdLCJjbGllbnRfaWQiOiJjb25zb2xlIiwic2NvcGUiOlsiY29uc29sZSJdLCJuYW1lIjoic3VwZXI6MSIsImV4cCI6MTYwMjU1MjU4OSwib3BlcmF0b3JJZCI6MSwiZW1haWwiOiIxMzI5MzMzOTg4M0AxNjMuY29tIiwianRpIjoiMTRhODEwYWYtMjJkYy00MWE4LWEyODktY2Q3ZDA3MzYyZGZiIn0.MtXSRnT1wwJiWNBxh5ZHUitLKWCLK73nuHlnozqFrXk';
        }
        headers = { "Authorization": header_token };
        if ((url.indexOf('/parking-authority' + '/admin/address/getVerificationCode') > -1)) {
            headers = null
        }
        //console.log(REQUEST + "--" + url + "--Params:", data);
        let httpUrl = '';
        if (url.indexOf('http') > -1) {
            httpUrl = url;
            // 中间件接口，增加几个公共参数
        } else if (url.indexOf('easy-mock') > -1) {
            httpUrl = url.replace(/easy-mock/, 'https://www.easy-mock.com/mock/5cd0f2f3682f200251f31dd3/immidiot');
        } else if(host){
            httpUrl = baseUrl + url
        } else{
            httpUrl = httpClientHost + url
        }
            $(function () {
                $.ajax({
                    "url": httpUrl,
                    "async": true,
                    "cache": false,
                    "method": rtype,
                    "data": data,
                    "processData": processData,
                    "dataType": 'json',
                    "contentType": contentType,
                    "xhrFields": {
                        "withCredentials": true,
                    },
                    "headers": headers,
                    timeout: 40000,
                    "crossDomain": true,
                    success: function (d:any) {
                        callback(d, requestSuccess);
                        if (d.success || d.ack_code=='ok') {
                            //成功
                            //  console.log(REQUEST + "--" + url + "--Success:", d);
                        } else {
                            //可以处理d.error.code
                            if ( (d && d.error && (d.error.code == 10014 || d.error.code == 10015)) || d&&(d.code == 10014 || d.code == 10015)) {// 登录失效 || 用户不存在
                                // location.reload();
                                message.warn('token过期，请重新登录')
                                if (d&&d.error&&(d.error.code === 10014) || d&&(d.code == 10014)) {
                                    window.location.hash = '/Login';
                                    window.location.pathname = '/';
                                } else {
                                    callback(d, requestSuccess);
                                    const hash = window.location.hash;
                                    if (!hash.match('Login')) {
                                        window.location.hash = '/Login';
                                        window.location.pathname = '/';
                                    }
                                }
                            } else {
                                // console.error(d&&d.error&&d.error.message);
                                callback(d, requestDataError);
                            }

                        }
                    },
                    error: function (e) {
                        //服务异常
                        console.error(REQUEST + "--" + url + "--Error:", e);
                        console.error("服务器异常！");
                        callback(e, requestServiceError);
                    }
                });
            })
    }

    return {
        ClientHost: httpClientHost,
        GET: 'GET',
        POST: "POST",
        PUT: "PUT",
        DELETE: "DELETE",
        requestSuccess: requestSuccess,
        requestDataError: requestDataError,
        requestServiceError: requestServiceError,
        query: query,
        REQUEST: REQUEST
    }


}());
