import { Button, Drawer, Form, message, Select } from 'antd'
import React, { PureComponent, useEffect, useState } from 'react'
import CityModel from '../../model/cityModel'
import { DeviceIndicators, DeviceInf, device_status, Vendor } from '../../model/model'
import { HttpClientImmidIot } from '../../model/request/HttpClientImmidIot'
import BerthSurvey from '../statistics/charts/berthSurvey'
import NumberOfStop from '../statistics/charts/numberOfStop'
import ParkingTime from '../statistics/charts/parkingTime'
import PaymentMethod from '../statistics/charts/paymentMethod'
import EquipmentMap from '../statistics/map/equipmentMap'
import { ChartCard } from '../statistics/statistics'
import searchMapBg from './../../static/images/monitoring/设备搜索框@2x.png'
import EquipmentReport from './charts/equipmentReport'
import './equipmentMonitoring.css'
import dataIcon1 from '../../static/images/monitoring/停车icon@2x.png'
import dataIcon2 from '../../static/images/monitoring/今日出车上报icon@2x.png'
import dataIcon3 from '../../static/images/monitoring/心跳上报icon@2x.png'
import arrowIcon from '../../static/images/monitoring/收起@2x.png'
import arrowImgRight from '../../static/images/monitoring/open.png'
const { Option } = Select;
export class EquipmentMonitoring extends PureComponent<any,any> {

    constructor(props:any){
        super(props)
        this.state = {
            visible: true,
        }
    }

    onClose = () => {
        this.setState({visible:false})
    };

    // 厂商设备占比
    manufacturer = () => {
        const element = <div className='manufacturer_data'>
            <ParkingTime type='manufacturer' seriesName='厂商设备占比'/>
        </div>
        return <ChartCard chartName='厂商设备占比' style='manufacturer' children={element}/>
    }

    // 设备工作状态
    equipmentWorkingStatus = () => {
        const element = <div className='equipment_status_data'>
            <NumberOfStop type='equipmentWorkingStatus'/>
        </div>
        return <ChartCard chartName='设备工作状态' style='equipmentStatus' children={element}/>
    }

    // 设备部署趋势
    equipmentDeploymentTrend = () => {
        const element = <div className='equipment_trend_data'>
            <NumberOfStop type='equipmentTrend'/>
        </div>
        return <ChartCard chartName='设备部署趋势' style='equipmentDeploymentTrend' children={element}/>
    }

    // 设备概况
    equipmentOverview = () => {
        const element = <div className='equipment_overview_data'>
            <BerthSurvey type='equipmentOverview'/>
        </div>
        return <ChartCard chartName='设备概况' style='equipmentOverview' children={element}/>
    }

    // 设备分区占比
    partitionEquipment = () => {
        const element = <div className='partition_equipment_data'>
            <ParkingTime type='partitionEquipment' seriesName='设备分区占比'/>
        </div>
        return <ChartCard chartName='设备分区占比' style='partitionEquipment' children={element}/>
    }

    // 设备上报次数
    equipmentReport = () => {
        const element = <div className='equipment_report_data'>
            <EquipmentReport />
        </div>
        return <ChartCard chartName='设备上报次数' style='equipmentReport' children={element}/>
    }

    // 设备类型占比
    equipmentType = () => {
        const element = <div className='equipment_type_data'>
            <PaymentMethod type='equipmentType'/>
        </div>
        return <ChartCard chartName='设备类型占比' style='equipmentType' children={element}/>
    }

    render() {
        const { visible } = this.state
        const style = {
            position: 'relative' as 'relative',
            width: '3.5rem', 
            height: '59vh',
            color: '#FFFFFF',
            overflow:'hidden'
            // backgroundImage:`url(${searchMapBg})`,
            // backgroundSize: '100% 100%',
        }
        const drawerStyle = {
            overflow:'hidden'
        }
        const bodyStyle = {
            width: '3.1rem', 
            height: '59vh',
            backgroundImage: `url(${searchMapBg})`,
            backgroundSize: '100% 100%',
            overflow:'hidden',
            paddingTop:'0.37rem',
        }
        const closeIcon = <div className='monitoring_closeIcon'
            style={{right:visible?'0.31rem':''}} 
            onClick={()=>{
                this.setState({visible:!visible})
            }}>
                <img src={visible?arrowIcon:arrowImgRight} alt=""/>
        </div>
        return (
            <div className='display_monitoring'>
                {/* 左侧图表 */}
                <div className='device_data_left'>
                    <div className='device_data_search_and_map'>
                        {/* 设备搜索框 */}
                        <div className='device_search' style={{}}>
                            <Drawer
                                onClose={this.onClose}
                                visible={visible}
                                placement={'left'}
                                mask={false}
                                className='device_search_drawer'
                                style={style}
                                drawerStyle={drawerStyle}
                                bodyStyle={bodyStyle}
                                closable={false}
                                getContainer={false}
                                // closeIcon={closeIcon}
                            >
                                <div className='device_search_title'>
                                    <p className='search_title'>设备搜索</p>
                                    <p className='search_subtitle'>请选择一个或多个条件进行搜索</p>
                                </div>
                                <SearchMapForm />
                                {closeIcon}
                            </Drawer>
                            {/* 收缩按钮 */}
                            {!this.state.visible&&closeIcon}
                        </div>
                        {/* 地图 */}
                        <div className='device_search_map'>
                            <EquipmentMap />
                        </div>
                        <DeviceIndicators />
                    </div>
                    {/* 底部图表 */}
                    <div className='device_data_charts'>
                        {this.manufacturer()}
                        {this.equipmentWorkingStatus()}
                        {this.equipmentDeploymentTrend()}
                    </div>
                </div>
                {/* 右侧四张图表 */}
                <div className='device_data_right'>
                    {this.equipmentOverview()}
                    {this.partitionEquipment()}
                    {this.equipmentReport()}
                    {this.equipmentType()}
                </div>
            </div>
        )
    }
}

interface DeviceSearch{
    vendor:string,
    device_status:device_status,
    activated:boolean | null,
    id:string
}
function SearchMapForm(){
    const [form] = Form.useForm();
    const [vendorList, setVendorList] = useState<Array<Vendor>>(null)
    const [allDeviceList,setAllDeviceList] = useState<Array<DeviceInf>>([])
    const [deviceList,setDeviceList] = useState<Array<DeviceInf>>([])
    const [selectedValue, setSelectedValue] = useState<DeviceSearch>({vendor:null,device_status:null,activated:null,id:null})
    
    useEffect(()=>{
        getVendorList()
        PubSub.subscribe('EquipmentList',(msg: string, info: Array<DeviceInf>)=>{
            setAllDeviceList(info)
            setDeviceList(info);
        })
    },[])

    // 获取设备厂商列表
    const getVendorList = () => {
        CityModel.vendorList({timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    setVendorList(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取设备厂商列表失败')
            }
        })
    }

    const {vendor,device_status,activated,id} = selectedValue
    const formList:any = [
        { label:'设备厂商', name:'vendor', placeholder:'全部', value: vendor,
            options:(vendorList||[]).map(item => ({value:item.vendor_code,name:item.vendor_name})) },
        { label:'设备状态', name:'device_status', placeholder:'全部', value: device_status,
            options:[{value:1,name:'在线'},
                    {value:0,name:'离线'},
                    {value:2,name:'故障'},
                    {value:-1,name:'未知'}] },
        { label:'激活状态', name:'activated', placeholder:'全部', value: activated,
            options:[{value:true,name:'已激活'},
                    {value:false,name:'未激活'}] },
        { label:'硬件ID', name:'id', placeholder:'请输入', value: id,
            options:deviceList.map(item => ({value:item.id,name:item.id})) },
    ]
    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 2, span: 30 },
    };
    return (
        <Form layout='horizontal' form={form}>
            {formList.map((item,index) => <Form.Item label={item.label} name={item.name} {...formItemLayout} key={index}>
                <Select
                    placeholder={item.placeholder}
                    // value={item.value&&String(item.value)}
                    onSelect={(value)=>{
                        selectedValue[item.name] = value;
                        setSelectedValue(selectedValue);
                        if(item.name !== 'id'){
                            const {vendor,device_status,activated,id} = selectedValue
                            const list = allDeviceList.filter(item => 
                                (!vendor||item.vendor==vendor)&&(!device_status&&(device_status!=0)||item.device_status==device_status)&&(!activated||item.activated==activated)&&(!id||item.id==id)
                            )
                            setDeviceList(list)
                        }
                    }}
                    allowClear
                    showSearch={item.name=='id'?true:false}
                >
                    {item.options.map((option,index)=><Option key={index} value={option.value}>{option.name}</Option>)}
                </Select>
            </Form.Item>)}
            <Form.Item {...tailLayout}>
                <Button htmlType="button" ghost={true} className='device_search_reset'
                    onClick={()=>{
                        form.resetFields();
                        setDeviceList(allDeviceList);
                        setSelectedValue({vendor:null,device_status:null,activated:null,id:null});
                        PubSub.publish('searchDevice',null)
                    }} >
                重置
                </Button>
                <Button type="primary" htmlType="submit" className='device_search_submit'
                    onClick={()=>{
                        if(selectedValue.id){
                            PubSub.publish('searchDevice',[selectedValue.id])
                        }else{
                            // message.warn('请输入硬件id')
                            PubSub.publish('searchDevice',deviceList.map(item=>item.id))
                        }
                    }} >
                搜索
                </Button>
            </Form.Item>
        </Form>
    )
    
}

function DeviceIndicators(){
    const [indicators,setIndicators] = useState<DeviceIndicators>({enter: 7,exit: 3,heartbeat: 1})
    let timer:NodeJS.Timeout | number
    useEffect(()=>{
        getDeviceIndicators()
        timer = setInterval(()=>{
            getDeviceIndicators()
        }, 5*1000 )
        return ()=>{
            clearInterval(Number(timer))
        }
    },[])
    const getDeviceIndicators = () => {
        CityModel.deviceIndicators({timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    setIndicators(d)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取设备列表失败')
            }
        })
    }
    const data = [
        {field:'今日入车上报',value: indicators.enter, icon:dataIcon1},
        {field:'今日出车上报',value: indicators.exit, icon:dataIcon2},
        {field:'今日心跳上报',value: indicators.heartbeat, icon:dataIcon3},
    ]
    return <div className='parkingLot_indicators device_indicators'>
        {data.map((item,index)=><div key={index}>
            <img src={item.icon} alt=""/>
            <div>
                <div className='parkingLotIndicators_amount' style={{color:['#966BFF','#36CFC9','#FF7F5B'][index]}}>{item.value}</div>
                <div className='parkingLotIndicators_field'>{item.field}</div>
            </div>
        </div>)}
    </div>
}

export default EquipmentMonitoring
