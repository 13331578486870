import PubSub from 'pubsub-js';
import "@amap/amap-jsapi-types";
import React, { PureComponent } from 'react';
import { Map, Markers } from 'react-amap';
import Heatmap from 'react-amap-plugin-heatmap';
import { withRouter } from 'react-router';
import CityModel from '../../../model/cityModel';
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot';
import parkingIcon1 from '../../../static/images/outRoadParking/停车场icon.png';
import { location, mapProps, showCityMap } from './amap';
import './parkingMap.css';
import './outRoadMap.css';
import { MapParking } from '../statistics';
import {  ParkingSummary ,ParkingInfos} from '../../../model/outRoadModelInterface'
import { parking_infos,classify_list } from '../../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../../model/outRoadModelRequest'

interface State {
    parkingInfos?: Array<ParkingInfos>,
    zoom?: {
        area: number,
        parking: number
    },
    marker?: any,
}

export class OutRoadMap extends PureComponent<any, State>{
    infoWindow: any;
    geocoder: any;
    timer: NodeJS.Timeout | number;
    constructor(props: any) {
        super(props)
        this.state = {
            parkingInfos: [],
            marker: null,
        }
    }

    componentDidMount = () => {
        this.getParkingSummary()
        this.timer = setInterval(()=>{
            this.getParkingSummary()
        }, 60*1000)
       
    }
     componentWillUnmount = () => {
        clearInterval()
    }

    //区域停车场列表统计信息
    getParkingSummary= () => {
        OutRoadModelRequest.parkingSummary({}, (d, type) => {
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({ parkingInfos: d.data.parkingInfos })
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取区域停车场列表失败')
            }
        })
    }

    getMarkers = () => {
       const {parkingInfos} = this.state
       return parkingInfos.map(item => {
                    return {
                        position: {
                            longitude: item.parkingLongitude,
                            latitude: item.parkingLatitude,
                        },
                        ...item,
                        level: 'second'
                    }
                })
        
    }

    renderMarkerLayout = (extData:ParkingInfos) => {
        return <img className='parkingMap_parkingIcon' src={parkingIcon1} alt="" />
    }

    markerEvents = () => {
        return {
            created: (markers) => {
                
            },
            click: (e, marker) => {
                const map = marker.getMap(), position = marker.getPosition( ), extData = marker.getExtData()
                // 保存停车场id
                 localStorage.setItem('DETAIL_PARKING_ID',extData.parkingId)
                 // 跳转至停车场页面
                 this.props.history.push({ pathname: '/outRoadParkingDetail', query: {parkingId: extData.parkingId} })
                
            },
            mouseover: (e, marker) => {
                const extData = marker.getExtData(), map = marker.getMap(), position = marker.getPosition()
                this.infoWindow.setContent(this.getInfoWindowContent(extData))
                this.infoWindow.open(map,position)
            },
            mouseout: (e, marker) => {
                this.infoWindow.close()
            },
           
        }
    }

    mapOptions = () => ({
        mapStyle: 'amap://styles/fb153177faab9a59cbf2f6b7f78ca63e',
        useAMapUI: true,
        zoom:13,
        zooms:[9,18] as [9,18],
        events: {
            created: (mapInstance) => {
                var trafficlayer = new AMap.TileLayer.Traffic();
                mapInstance.add([trafficlayer]);
                const zoomInit = mapInstance.getZoom()
                mapInstance.on('zoomend',() =>{
                    if(this.infoWindow){
                        this.infoWindow.close()
                    }
                })
                // 指定颍州区
                location(mapInstance)
                showCityMap(mapInstance)

                //限定地图移动范围
                // setBounds(bound:Bounds)
                 var mybounds = new window.AMap.Bounds([114.54, 29.41], [119.37,34.38]);
                 mapInstance.setBounds(mybounds);
                 mapInstance.setLimitBounds(mybounds);

                this.infoWindow = new window.AMap.InfoWindow({
                    anchor: 'bottom-center',
                    isCustom: true,
                    content: '',
                });
                window.AMap.plugin(['AMap.Geocoder'],() =>{
                    this.geocoder = new window.AMap.Geocoder({});
                })
            }
        }
    })

    getInfoWindowContent = (extData) => {
        let infoWindowContent  = `<div class='out_road_map_pop_container'>
                <div class='pop_container_title'>${extData.parkingName?extData.parkingName:'--'}</div>
                <div class='pop_item_line'>
                    <div class='pop_item_line_lable'>车位类型</div>
                    <div class='pop_item_line_value'>${classify_list[extData.classify]?classify_list[extData.classify]:'--'}</div>
                </div>
                <div class='pop_item_line'>
                    <div class='pop_item_line_lable'>地址</div>
                    <div class='pop_item_line_value'>${extData.address?extData.address:'--'}</div>
                </div>
                 <div class='pop_item_line'>
                    <div class='pop_item_line_lable'>总车位数</div>
                    <div class='pop_item_line_value'>${extData.parkingSpaceTotal!=undefined?extData.parkingSpaceTotal.toLocaleString():'--'}个</div>
                </div> 
                <div class='pop_item_line'>
                    <div class='pop_item_line_lable'>在场车辆</div>
                    <div class='pop_item_line_value'>${extData.parkingSpaceUse!=undefined?extData.parkingSpaceUse.toLocaleString():'--'}辆</div>
                </div> 
                <div class='pop_item_line'>
                    <div class='pop_item_line_lable'>剩余车位数</div>
                    <div class='pop_item_line_value'>${extData.parkingSpaceResidual!=undefined?extData.parkingSpaceResidual.toLocaleString():'--'}个</div>
                </div>
                    
                </div>`
        return infoWindowContent
    }

    render() {
        const {parkingInfos,marker} = this.state

        const pluginProps = {
            radius: 50,
            blur:10,
            opacity: [0, 1],
            zooms: [3, 18],
            gradient:{
                0.3: '#00FF00',
                0.5: '#FEF935',
                0.7: 'red',
            },
            dataSet: {
                data: parkingInfos.map(item => {
                    return {"lng": item.parkingLongitude,"lat": item.parkingLatitude,"count": item.spaceUsageRate!==undefined?item.spaceUsageRate:0}
                }),
                max: 70
            }
        }
        return <Map {...mapProps}  {...this.mapOptions()}>
                <Heatmap {...pluginProps} />
            <Markers 
                events={this.markerEvents()} 
                markers={this.getMarkers()} 
                render={this.renderMarkerLayout} />
           
        </Map>
    }
}


export default withRouter(OutRoadMap)
