import React, { PureComponent, useEffect, useState } from 'react'
import { city_profile } from '../../model/modelData'
import CityModel from '../../model/cityModel'
import { CityProfile } from '../../model/model'
import { HttpClientImmidIot } from '../../model/request/HttpClientImmidIot'
import startParking from '../../static/images/berthMonitoring/启用泊位icon@2x.png'
import startBoardIcon from '../../static/images/berthMonitoring/启用路段icon@2x.png'
import violationIcon from '../../static/images/berthMonitoring/违停icon@2x.png'
import NumberOfStop from '../statistics/charts/numberOfStop'
import ParkingTime from '../statistics/charts/parkingTime'
import ParkingMap from '../statistics/map/parkingMap'
import { ChartCard } from '../statistics/statistics'
import numberOfStopIcon from './../../static/images/statistics/停车次数icon@2x.png'
import './../statistics/Statistics.css'
import './berthMonitoring.css'
import ParkingTimeTop5 from './charts/parkingTimeTop5'
import ParkingTimeTop5ThisMonthRoad from './charts/parkingTimeTop5ThisMonthRoad'
import PubSub from 'pubsub-js'

export class BerthMonitoring extends PureComponent {

    // 各区泊位占比
    berthsProportion = () => {
        const element = <div className='berths_proportion_data'>
            <ParkingTime type='berthsProportion' seriesName='各区泊位占比'/>
        </div>
        return <ChartCard chartName='各区泊位占比' style='berths_proportion' children={element}/>
    }

    // 本月停车时长top5泊位
    parkingTimeTop5ThisMonth = () => {
        const element = <div className='parkingTime_Top5_data'>
            <ParkingTimeTop5 type='parkingTimeTop5ThisMonth'/>
        </div>
        return <ChartCard chartName='本月停车时长top5泊位' style='parkingTime_Top5' children={element}/>
    }

    // 今日停车时长top5路段
    parkingTimeTop5Today = () => {
        const element = <div className='parkingTime_Top5_data'>
            <ParkingTimeTop5 type='parkingTimeTop5Today'/>
        </div>
        return <ChartCard chartName='今日停车时长top5路段' style='parkingTime_Top5' children={element}/>
    }

    

    // 本月停车时长top5路段（每天）
    parkingTimeTop5ThisMonthRoad = () => {
        const element = <div className='parkingTimeTop5ThisMonthRoad_data'>
            <ParkingTimeTop5ThisMonthRoad />
        </div>
        return <ChartCard chartName='本月停车时长top5路段（每天）' style='parkingTimeTop5ThisMonthRoad' children={element}/>
    }

    render() {
        return (
            <div className='berthMonitoring'>
                <div className='berthMonitoring_main'>
                    {/* 左侧三张统计图 */}
                    <div className='berthMonitoring_left'>
                        {this.berthsProportion()}
                        {this.parkingTimeTop5ThisMonth()}
                        {this.parkingTimeTop5Today()}
                    </div>
                    {/* 地图 */}
                    <div className='berth_monitoring_map'>
                        <ParkingMap type='berthMonitoring'/>
                        <Indicators />
                    </div>
                    {/* 右侧三张统计图 */}
                    <div className='berthMonitoring_right'>
                        <ViolationTimes type={'violation'}/>
                        <ViolationTimes type={'berthToday'}/>
                        <ViolationTimes type={'berthThisMonth'}/>
                    </div>
                </div>
                {/* 底部柱状图 */}
                <div>
                    {this.parkingTimeTop5ThisMonthRoad()}
                </div>
            </div>
        )
    }
}

export default BerthMonitoring

// 违停次数统计
export function ViolationTimes(props:{type:'violation'|'berthToday'|'berthThisMonth'}){
    const {type} = props
    const chartNames = {violation:'违停次数统计', berthToday:'今日泊位利用率', berthThisMonth:'本月泊位利用率'}
    const element = <div className='violation_times_data'>
        <NumberOfStop type={type}/>
    </div>
    return <ChartCard chartName={chartNames[type]} style='violation_times' children={element}/>
}

function Indicators(){

    const [cityProfile,setCityProfile] = useState<CityProfile|null>(city_profile)
    let timer:NodeJS.Timeout | number
    useEffect(() => {
        getUrbanResources()
        timer = setInterval(()=>{
            getUrbanResources()
        }, 5*1000 )
        PubSub.subscribe('mapParking',(msg: string, info:any)=>{
            getUrbanResources(info.adcode)
        })
        return () => {
            clearInterval(Number(timer))
        }
    },[])

    // 获取今日城市概况
    const getUrbanResources = (cityId?:string) => {
        CityModel.cityProfile({cityId: cityId || localStorage.getItem('cityCode') || '440300'},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    setCityProfile(d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取今日城市概况失败')
            }
        })
    }

    const {todayParkingTimesTotal,parkingTotalCount,parkWarnTimes,parkingSpaceTotal} = cityProfile || {}
    return <div className='display_key_indicators berth_monitoring_indicators'>
        {[
            {field:'今日停车次数',value:todayParkingTimesTotal||'28',icon:numberOfStopIcon},
            {field:'启用路段',value:parkingTotalCount||'2852',icon:startBoardIcon},
            {field:'今日违停次数',value:parkWarnTimes||'3852',icon:violationIcon},
            {field:'启用泊位',value:parkingSpaceTotal||'100',icon:startParking}
        ].map((item,index) => <div key={index}>
            <img src={item.icon} alt=""/>
            <div>
                <div className='keyIndicators_amount' style={{color:['#966BFF','#36CFC9','#FF7F5B','#32C5FF'][index]}}>{item.value}</div>
                <div className='keyIndicators_field'>{item.field}</div>
            </div>
        </div>)}
    </div>
}
