import React, {Component, useEffect, useState} from 'react';

import {Button, Card, Form, Input, message} from 'antd';
import { HttpClientImmidIot } from '../../model/request/HttpClientImmidIot';
import CryptoJS from 'crypto-js';
import FormItem from 'antd/lib/form/FormItem';
import './resetPasswordCard.css'

let globalCountDown = null;

function ResetPasswordCard (props) {
    const [form] = Form.useForm();
    const [countDown, setcountDown] = useState(60)
    const [getVerifyText, setgetVerifyText] = useState('获取验证码')
    const [getVerifyButtonDisabled, setgetVerifyButtonDisabled] = useState(false)

    useEffect(() => {
        globalCountDown = null;
    })    

    // 获取验证码
    const getVerifyCode = () => {
        const params = {
            userName: form.getFieldValue('userName'),
            operatorId: window.OPERATOR_ID,
        };
        setgetVerifyButtonDisabled(true)
        setcountDown(60)
        let url = '/parking-authority';
        url += props.context === '修改密码' ? '/admin/getVerificationCode/byname' : '/admin/forgetPassWord/getVerificationCode/byname';
        HttpClientImmidIot.query(url, 'POST', params, verifyCodeCallback, false,'application/x-www-form-urlencoded')

    }

    const verifyCodeCallback = (d, type) => {
        if (type === HttpClientImmidIot.requestSuccess) {
            if(d&&d.success){
                message.success('验证码发送成功', 1.5);
                // 倒计时
                globalCountDown = setInterval(() => {
                    cuontDown()
                }, 1000);
            }
        } else {
            // 用户不存在时
            setgetVerifyButtonDisabled(false)
        }
    }

    // 倒计时
    const cuontDown = () => {
        if (countDown === 0) {
            setgetVerifyText('获取验证码')
            setgetVerifyButtonDisabled(false)
            clearInterval(globalCountDown);
        } else {
            setcountDown(countDown-1)
            setgetVerifyText(`重新发送 ${countDown}`)
        }
    }

    // 提交
    const resetSubmit = (e) => {
        e.preventDefault();
        form.validateFields()
        .then(values => {
            const encrypt = CryptoJS.SHA1(values.password).toString();
            const params = {
                password: encrypt,
                mobileNumber: values.userName,
                verificationCode: values.verifyCode,
                operatorId: window.OPERATOR_ID,
            };
            if (props.context === '忘记密码') {
                HttpClientImmidIot.query(`${'/parking-authority'}/admin/ForgetPassWord/updatePassWord`, 'POST', params, queryCallback, false,'application/x-www-form-urlencoded')
            } else if (props.context === '修改密码') {
                HttpClientImmidIot.query(`${'/parking-authority'}/admin/updatePassword`, 'POST', params, queryCallback, false,'application/x-www-form-urlencoded')
            }
        }).catch(err => {

        })
    }

    // 请求回调
    const queryCallback = (d, type) => {
        if (type === HttpClientImmidIot.requestSuccess) {
            //成功-------在这里做你的数据处理
            sessionStorage.clear();
            localStorage.clear();
            window.setPermission({});
            window.setManagePartnerList([]);
            window.currentIsSystemAdmin = false;
            clearInterval(globalCountDown);
            message.success('密码修改成功，两秒后返回登录页面', 2);
            setTimeout(() => {
                window.location.hash = '/Login'
            }, 2000)
        } else {
            //失败----做除了报错之外的操作
        }
    }

   
    const { context, style } = props; 
    return (
        <Card className='reset-card' style={style} bordered={false} hoverable={false}>
            <div className='top'>
                <div className='header'>
                    {/* <img style={{ width: 60, height: 50 }} src={window.LOGO_SRC}/> */}
                    <span style={{
                        color: "#1890FF",
                        fontSize: "34px",
                        lineHeight: "42px",
                        margin: "0 20px"
                    }}>
                        {window.OPERATOR_NAME}平台
                    </span>
                </div>
            </div>
            <div className='form-title'>
                <span>{context}</span>
                {
                    context === '忘记密码' && (
                        <span className='back-login' onClick={() => {
                            window.location.hash = '/Login';
                        }}>返回登录</span>
                    )
                }
            </div>
            <Form onFinish={resetSubmit} className="login-form" form={form}>
                {
                    context === '忘记密码' && (
                        <FormItem name={'userName'} rules={[{ required: true, message: '请输入用户名!' }]}> 
                            <Input className='login-form-input' placeholder="请输入用户名"/>
                        </FormItem>
                    )
                }
                <FormItem name={'password'} 
                    rules={[{
                        required: true,
                        message: '只能输入6-16位',
                    }, {
                        validator: (rule, value, callback) => {
                            let reg = /^[0-9a-zA-Z]{6,16}$/;
                            if (value) {
                                if (!(value.length > 5 && value.length < 17)) {
                                    callback('只能输入6-16位');
                                } else {
                                    if (!reg.test(value)) {
                                        callback('支持数字、英文，不支持特殊字符');
                                    }
                                }
                            }
                            callback();
                        },
                    }]}>
                    <Input className='login-form-input' type="password" placeholder="6-16 位密码，区分大小写"/>
                </FormItem>
                <FormItem name={'configPassword'} 
                    rules={[{
                        required: true,
                        message: '请输入确认密码!'
                    }, {
                        validator: (rule, value, callback) => {
                            const { getFieldValue } = this.props.form;
                            if (value && value !== getFieldValue('password')) {
                                callback('两次输入不一致！')
                            }
                            callback();
                        },
                    }]}>
                    <Input className='login-form-input' type="password" placeholder="确认密码"/>
                </FormItem>
                <FormItem name={'verifyCode'} rules={[{ required: true, message: '请输入验证码!' }]}>
                    <Input className='login-form-input' placeholder="请输入验证码" style={{ width: '72%' }}/>
                    <Button style={{ width: '25%', height: '40px', float: 'right' }}
                            onClick={getVerifyCode}
                            disabled={getVerifyButtonDisabled}>{getVerifyText}</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        提交
                    </Button>
                </FormItem>
            </Form>
        </Card>
    );
    
}

export default ResetPasswordCard;
