import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Statistics from './containers/statistics/statistics';
import NavigationBar from './containers/navigationBar/navigationBar';
import EquipmentMonitoring from './containers/equipmentMonitoring/equipmentMonitoring';
import BerthMonitoring from './containers/berthMonitoring/berthMonitoring'
import InspectionMonitoring from './containers/inspectionMonitoring/inspectionMonitoring';
import ParkingStatistics from './containers/parkingStatistics/parkingStatistics';
import outRoadParkingDetail from "./containers/outRoadParking/outRoadParkingDetail";
import OutRoadParking from './containers/outRoadParking/outRoadParking';
import LoginContainer from './containers/login/loginContainer';
import ResetPassword from './containers/login/ResetPassword';

//指定路由
export default function Routes(){

    return (
        <Router>
            <Switch>
                <Route exact path='/login' component={LoginContainer}/>
                <Route exact path='/ResetPassword' component={ResetPassword}/>
                <Route path='/'>
                    <div className='display_bg'>
                        <NavigationBar />
                        <Route exact path='/' component={OutRoadParking} />
                        <Route exact path="/statistics" component={Statistics} />
                        <Route exact path="/equipmentMonitoring" component={EquipmentMonitoring} />
                        <Route exact path='/berthMonitoring' component={BerthMonitoring} />
                        <Route exact path='/inspectionMonitoring' component={InspectionMonitoring} />
                        <Route exact path='/parkingStatistics' component={ParkingStatistics} />
                        <Route exact path='/outRoadParking' component={OutRoadParking} />
                        <Route exact path='/outRoadParkingDetail' component={outRoadParkingDetail} />
                    </div>
                </Route>
            </Switch>
        </Router>
    );
}
