
//停车场信息
export const parking_Detail_Info =  {
    "statisticsTime": new Date(),  //统计时间
    "parkingSpaceTotal":0, //泊位总数
    "parkingSpaceUse":0, //泊位占用数量
    "parkingSpaceResidual":0, // 泊位空余数
    "spaceUsageRate":0, //占用率
    "parkingId":0, // 停车场id
    "parkingRecordNo":'-', // 停车场编码
    "parkingName":'-', //停车场名称
    "streetName":'-', //街道
    "address":'-', //停车场地址
    "workTimeStart":'-', // 服务开始时间
    "workTimeEnd":'-', //服务结束时间
    "parkingLongitude":'-', //停车场经度
    "parkingLatitude":'-', // 停车场维度
    "owner":'-', //停车场产权方
    "maintenance":'-', //停车场维护方
    "classify":8, //停车场类型1-商业； 2-住宅； 3-医院； 4-园区； 5-交通枢纽； 6-行政机关； 7-学校； 8-其它
    "ownStation":'-', //所属派出所
    "chargePolice":'-', //区域负责民警
    "industAuthorInfo":'-', //业委会信息
    "taxMain":'-', //缴税主体
    "taxId":'-', //纳税人识别号
    "parkingPhone":"-",
    "priceDescription":"-"
};

export const  parking_count = {
    statisticsTime:new Date(),//统计时间
    inTimes:0, // 入场次数
    outTimes:0, //停车次数
};

export const parking_Top_Record = {
    plateNumber:"-", //车牌号码
    inTime:new Date(),//入场时间
    outTime:new Date(),//出厂时间
    inChannel:"-", //入口
    outChannel:"-", //出口
    inImageUrl:"-", //入场图片
    outImageUrl:"-", //出厂图片
};

//实时车辆通行信息
export const detail_top_record = [{
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
},
    {
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
    },
    {
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
    },
    {
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
    },
    {
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
    },
    {
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
    },
    {
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
    },
    {
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
    },
    {
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
    },{
        "plateNumber": "-",
        "inTime":"",
        "outTime": "",
        "inChannel":"-",
        "outChannel":"-",
        "inImageUrl":"",
        "outImageUrl":""
    }
];
export const use_rate = [

    {
        statisticsTime:"2021-01-01T15:15:15.000+0800",
        rate:40,
    },
    {
        statisticsTime:"2021-01-02T15:15:15.000+0800",
        rate:20,
    },
    {
        statisticsTime:"2021-01-03T15:15:15.000+0800",
        rate:60,
    },
    {
        statisticsTime:"2021-01-04T15:15:15.000+0800",
        rate:90,
    }

];
