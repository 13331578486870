import React, {Component} from 'react';

import ResetPasswordCard from './resetPasswordCard';

export default class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    // 组件挂载之前
    componentWillMount() {
    }

    // 组件挂载后
    componentDidMount() {
    }

    // 组件卸载之前
    componentWillUnmount() {
    }

    render() {
        return (
            <div className='resetPasswordContainer'>
                <div className='content'>
                    <ResetPasswordCard context='忘记密码' />
                </div>
                {/* <footer className='reset-footer'>Copyright © 2018 {window.OPERATOR_NAME}有限公司</footer> */}
            </div>
        );
    }
}
