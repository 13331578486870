import React, { PureComponent } from 'react'
import { Map, Markers } from 'react-amap'
import CityModel from '../../../model/cityModel'
import { DeviceInf, EquipmentDetail } from '../../../model/model'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { location, mapProps, showCityMap } from './amap'
import './equipmentMap.css'
import videoImg from '../../../static/images/monitoring/高位视频@2x.png'
import nbImg from '../../../static/images/monitoring/nb地磁@2x.png'
import uwbImg from '../../../static/images/monitoring/UWB@2x.png'
import etcImg from '../../../static/images/monitoring/收费桩@2x.png'
import videoIcon from '../../../static/images/monitoring/高位视频@2x(1).png'
import nbIcon from '../../../static/images/monitoring/NB地磁小@2x.png'
import uwbIcon from '../../../static/images/monitoring/uwb小@2x.png'
import etcIcon from '../../../static/images/monitoring/小收费桩icon@2x.png'
import { Checkbox, message } from 'antd'

interface State {
    allEquipmentList?: Array<DeviceInf>,
    equipmentList?: Array<DeviceInf>,
    filterList?: Array<DeviceInf>,
    selectEquipment?: {
        video: boolean,
        nb: boolean,
        uwb: boolean,
        etc: boolean
    }
}
export default class EquipmentMap extends PureComponent<any, State> {
    infoWindow: any

    constructor(props: any) {
        super(props)
        this.state = {
            allEquipmentList: null,     //全部设备
            equipmentList: null,        //设备列表
            filterList: null,           //过滤列表
            selectEquipment: {
                video: true,
                nb: true,
                uwb: true,
                etc: true
            }
        }
    }

    componentDidMount = () => {
        this.getEquipmentList()
        PubSub.subscribe('searchDevice',(msg: string, info: Array<string>|null)=>{
            const {allEquipmentList} = this.state
            const list = info?allEquipmentList.filter(item => info.includes(item.id)):[]
            if(info && list.length == 0){
                message.warn('未搜索到符合条件的设备')
            }
            this.setState({
                equipmentList:info?list:allEquipmentList,
                filterList:info?list:allEquipmentList,
                selectEquipment:{video:true,nb:true,uwb:true,etc:true},
            })
        })
    }

    componentWillUnmount = () => {
        PubSub.unsubscribe('searchDevice')
    }

    //设备列表
    getEquipmentList = () => {
        CityModel.equipmentList({timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    this.setState({allEquipmentList:d.data,equipmentList:d.data,filterList:d.data})
                    PubSub.publish('EquipmentList',d.data)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取设备列表失败')
            }
        })
    }

    // 设备详情
    getEquipmentDetail = (id,callback) => {
        CityModel.equipmentDetail({id,timestamp: new Date().getTime()},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if(d.ack_code=='ok'){
                    callback(d)
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取设备详情失败')
            }
        })
    }

    markerEvents = () => {
        return {
            created: (markers) => {
                if(markers[0]){
                    const map = markers[0].getMap()
                    map.setFitView(
                        markers,  // 覆盖物数组
                        false,  // 动画过渡到制定位置
                        [60, 60, 60, 60],  // 周围边距，上、下、左、右
                        18,  // 最大 zoom 级别
                    );
                }
            },
            click: (e, marker) => {
                
            },
            mouseover: (e, marker) => {
                const extData = marker.getExtData(), map = marker.getMap(), position = marker.getPosition()
                const openInfoWindow = (data) => {
                    this.infoWindow.setContent(this.getInfoWindowContent(data))
                    this.infoWindow.open(map,position)
                }
                this.getEquipmentDetail(extData.id,openInfoWindow)
            },
            mouseout: (e, marker) => {
                if(this.infoWindow){
                    this.infoWindow.close()
                }
            }
        }
    }

    getInfoWindowContent = (data:EquipmentDetail) => {
        const address = data.deployment_address
        const date = data.time_created.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/g, '$1.$2.$3 $4:$5:$6');
        const status = { '-1':'未知', '0':'离线', '1':'在线', '2':'故障' }
        const {device_status} = data
        return `<div class='infoWindow_equipment'>
            <div class='infoWindow_equipment_title'>部署信息</div>
            <div><div>部署地址</div><div>${address.slice(address.indexOf('区')+1)}</div></div>
            <div><div>设备坐标</div><div>${data.geo_longlat[0].toFixed(5)} ${data.geo_longlat[1].toFixed(5)}</div></div>
            <div><div>激活时间</div><div>${date}</div></div>
            <div><div>绑定状态</div><div>${data.bound?'已绑定':'未绑定'}</div></div>
            <div><div>路段名称</div><div>${address.slice(address.indexOf('区')+1,address.indexOf('街道')+2)}</div></div>
            <div><div>设备状态</div><div>${status[device_status]}</div></div>
        </div>`
    }

    getMarkers = () => {
        const {filterList} = this.state
        return (filterList||[]).map(item => {
            return {
                position: {
                    longitude: item.geo_longlat[0],
                    latitude: item.geo_longlat[1],
                },
                ...item
            }
        })
    }

    renderMarkerLayout = (extData) => {
        let equipmentImg = ''
        switch (extData.sub_type) {
            case '高位视频':equipmentImg = videoImg;break;
            case 'NB地磁':equipmentImg = nbImg;break;
            // case 'Uwb定位基站':equipmentImg = uwbImg;break;
            case 'ETC智能收费桩':equipmentImg = etcImg;break;
            default:break;
        }
        return <img className='equipment_img' src={equipmentImg} alt=""/>
    }

    mapOptions = () => ({
        mapStyle: 'amap://styles/fb153177faab9a59cbf2f6b7f78ca63e',
        useAMapUI: true,
        zooms:[3,20] as [3,20],
        events: {
            created: (mapInstance) => {
                mapInstance.on('zoomend',()=>{
                    if(this.infoWindow){
                        this.infoWindow.close()
                    }
                })
                // window.AMap.plugin('AMap.CitySearch', () => {
                //     const citySearch = new window.AMap.CitySearch()
                //     citySearch.getLocalCity((status, result) => {
                //         if (status === 'complete' && result.info === 'OK') {
                //             // 查询成功，result即为当前所在城市信息
                //             localStorage.setItem('cityName',result.city)
                //             localStorage.setItem('cityCode',result.adcode)
                //             PubSub.publish('mapParking',{level:'top',adcode: result.adcode, areaName:result.city})
                //         }
                //     })
                // })
                // 指定颍州区
                location(mapInstance)
                showCityMap(mapInstance)

                this.infoWindow = new window.AMap.InfoWindow({
                    anchor: 'bottom-center',
                    isCustom: true,
                    content: '',
                });
            },
            mouseout: () => {
                if(this.infoWindow){
                    this.infoWindow.close()
                }
            }
        }
    })

    render() {
        const {selectEquipment,equipmentList,filterList,allEquipmentList} = this.state
        const selectOptions = [
            {type:'etc',name:'智能收费桩',icon:etcIcon},
            {type:'nb',name:'NB地磁',icon:nbIcon},
            {type:'video',name:'高位视频',icon:videoIcon},
            // {type:'uwb',name:'UWB',icon:uwbIcon},
        ]
        return <Map {...mapProps}  {...this.mapOptions()}>
            <Markers 
                events={this.markerEvents()}
                markers={this.getMarkers()}
                render={this.renderMarkerLayout}/>
            <div className='equipmentMap_checkbox'>
                {selectOptions.map((item,index)=>{
                    return <div key={index}>
                        <Checkbox checked={selectEquipment[item.type]} onChange={()=>{
                            selectEquipment[item.type] = !selectEquipment[item.type];
                            const { video,nb,uwb,etc } = selectEquipment
                            this.setState({selectEquipment})
                            this.setState({filterList:equipmentList.filter(item => (etc||item.sub_type!='ETC智能收费桩')&&(nb||item.sub_type!='NB地磁')&&(video||item.sub_type!='高位视频')&&(uwb||item.sub_type!='Uwb定位基站'))})
                        }}>{item.name}</Checkbox>
                        <img src={item.icon} alt=""/>
                    </div>
                })}
            </div>
        </Map>
    }
}
