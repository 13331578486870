import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import CityModel from '../../../model/cityModel'
import { ParkingtimeTop5ThisMonthRoad } from '../../../model/model'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import PubSub from 'pubsub-js'

interface State{
    parkingTimeTop5ThisMonthRoad?: Array<ParkingtimeTop5ThisMonthRoad>
}
export class ParkingTimeTop5ThisMonthRoad extends PureComponent<any,State> {

    constructor(props:any){
        super(props)
        this.state = {
            parkingTimeTop5ThisMonthRoad: [
                {
                    parkingTopDurations: [{parkDuration: 0, parkingId: 0, parkingName: '' }],
                    statisticsTime: new Date()
                }
            ]
        }
    }

    componentDidMount(){
        this.getParkingTimeTop5ThisMonthRoad()
        PubSub.subscribe('mapParking',(msg: string, info:any)=>{
            this.getParkingTimeTop5ThisMonthRoad(info.adcode)
        })
    }

    // 获取本月停车时长top5路段（每天）数据
    getParkingTimeTop5ThisMonthRoad = (cityId?:string) => {
        CityModel.parkingTimeTop5ThisMonthRoad({cityId: cityId || localStorage.getItem('cityCode') || '440300',limit:5},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({parkingTimeTop5ThisMonthRoad:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取本月停车时长top5路段（每天）数据失败')
            }
        })
    }

    getEChartsData = () => {
        const {parkingTimeTop5ThisMonthRoad} = this.state
        const xData = parkingTimeTop5ThisMonthRoad?parkingTimeTop5ThisMonthRoad.map(item => {
            return new Date(item.statisticsTime).getDate()
        }):[]
        const names = parkingTimeTop5ThisMonthRoad?parkingTimeTop5ThisMonthRoad[0].parkingTopDurations.map(item => item.parkingName):[]
        const colors = ['#5450F5','#02BDC3','#32C5FF','#F3AA49','#E45374']
        const series =  names.map((name,index)=>{
            const data = parkingTimeTop5ThisMonthRoad.map(item => {
                const park = item.parkingTopDurations.find(e => e.parkingName==name)
                return park&&park.parkDuration 
            })
            return {
                name,
                color: colors[index],
                data
            }
        })
        const echartsData = {
            xData,
            series
        }
        return echartsData
    }
   
    getOptions = () => {
        const echartsData = this.getEChartsData()
        return {
            // backgroundColor:'#fff',
            
            tooltip: {
                trigger: 'axis' as 'axis',
            },
            grid: {
                left: '0',
                right: '0%',
                bottom: '0%',
                top: '5%',
                containLabel: true
            },
            
            xAxis :{
                type : 'category' as 'category',
                data : echartsData.xData,
                axisLabel:{          //坐标轴字体颜色
                    color: '#9eaaba'
                },
                axisLine:{
                    lineStyle:{
                        color:"#e5e5e5"
                    }
                }, 
                axisTick:{       //y轴刻度线
                  show:false
                },
                splitLine:{    //网格
                    show: false,
                }
            },
            yAxis :{
                type : 'value' as 'value',
                axisLabel:{        //坐标轴字体颜色
                    color: '#35A1F0'
                },
                axisLine:{
                    show:false,
                },
                axisTick:{       //y轴刻度线
                  show:false
                },
                splitLine:{    //网格
                    show: true,
                    lineStyle:{
                        color:'#35A1F0',
                        // type:"dashed" as "dashed"
                    }
                }
            },
            series: echartsData.series.map((item,index) => {
                return {
                    name: item.name,
                    type:'bar' as 'bar',
                    stack:'停车时长',
                    barWidth : '40%',  //柱子宽度
                    itemStyle:{  //柱子颜色
                        normal:{
                            color:item.color,
                            borderColor: "#fff",
                            borderWidth: 1,
                        }
                    },
                    data: item.data
                }
            })
        };
        
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default ParkingTimeTop5ThisMonthRoad
