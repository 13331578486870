import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { RealTimeTraffic, Traffic } from '../../../model/model'
import ParkingModel from '../../../model/parkingModel'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { MapModel, MapModelParm } from '../../../model/SystemFunction'
import { MapParking } from '../statistics'
import {  InOutTimes } from '../../../model/outRoadModelInterface'
import { in_out_times } from '../../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../../model/outRoadModelRequest'
import outRoadParkingDetailModelRequest from "../../../model/outRoadParkingDetailModelRequest";

interface Props{
    type?: 'parking'|'inOutTimes' | "outRoadParkingDetail"
}
interface State{
    trafficFlow?: Array<Traffic>,
    realTimeTraffic?: Array<RealTimeTraffic>
    inOutTimes?: Array<InOutTimes>,
    detailInOutTimes?:Array<InOutTimes>,
}
export class TrafficFlow extends PureComponent<Props,State> {
    timer: NodeJS.Timeout | number;
    constructor(props:any){
        super(props);
        this.state = {
            trafficFlow: null,
            realTimeTraffic: null,
            inOutTimes:null,
            detailInOutTimes:null,
        }
    }

    componentDidMount = () => {
        switch (this.props.type) {
            case 'parking':
                this.getRealtimeTrafficFlow()
                this.timer = setInterval(()=>{
                    this.getRealtimeTrafficFlow()
                }, 60*1000 )
                break;
            case 'inOutTimes':
                this.getInOutTimes()
                this.timer = setInterval(()=>{
                    this.getInOutTimes()
                }, 60*1000)
                break;
            case 'outRoadParkingDetail':
                this.getOutRoadParkingDetailInOutTimes();
                this.timer = setInterval(()=>{
                    this.getOutRoadParkingDetailInOutTimes();
                }, 60*1000);
                break;
            default:
                this.getRevenueData({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                this.timer = setInterval(()=>{
                    this.getRevenueData({level:'top',adcode:localStorage.getItem('cityCode') || '440300'})
                }, 60*1000 )
                PubSub.subscribe('mapParking',(msg: string,info: MapParking)=>{
                    if(this.timer){
                        clearInterval(Number(this.timer))
                    }
                    this.getRevenueData(info)
                    this.timer = setInterval(()=>{
                        this.getRevenueData(info)
                    }, 60*1000 )
                })
                break;
        }
    }

    componentWillUnmount = () => {
        clearInterval(Number(this.timer))
        PubSub.unsubscribe('mapParking');
    };

    // 获取车流统计数据
    getRevenueData = (info: MapParking) => {
        const model = MapModel[info.level]
        const parm = MapModelParm[info.level]
        model.trafficFlow({[parm]:info.adcode},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({trafficFlow:d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取区域营收分析数据失败')
            }
        })
    }

    // 车流：实时信息
    getRealtimeTrafficFlow = () => {
        const parkingId = localStorage.getItem('parkingId')
        ParkingModel.realtimeTrafficFlow({parkingId},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({realTimeTraffic: d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取车流：实时信息失败')
            }
        })
    };

     //右2   车流统计
    getInOutTimes = () => {
        const parkingId = localStorage.getItem('parkingId')
        OutRoadModelRequest.inOutTimes(null,(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length>0) {
                    this.setState({inOutTimes: d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取车流统计失败')
            }
        })
    };


    //右1  --路外停车-单个停车场---车流统计
    getOutRoadParkingDetailInOutTimes = () =>{
        const parkingId = localStorage.getItem('DETAIL_PARKING_ID');
        outRoadParkingDetailModelRequest.parkingFlow({parkingId:parseInt(parkingId)},(d, type)=>{
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success&&d.data.length > 0) {
                    this.setState({detailInOutTimes: d.data})
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取车流统计失败')
            }
        })
    };

    getChartData = () => {
        let data = {
            xAxisData: ["0", "0", "0", "0", "0", "0", "0", "0"],
            yAxisData1: [0, 0, 0, 0, 0, 0, 0, 0],
            yAxisData2: [0, 0, 0, 0, 0, 0, 0, 0]
        };
        switch (this.props.type) {
            case 'parking':
                const {realTimeTraffic} = this.state
                if(realTimeTraffic){
                    data = {
                        xAxisData: realTimeTraffic.map(item=>moment(item.updateTime).format('LT')),
                        yAxisData1: realTimeTraffic.map(item=>1),
                        yAxisData2: realTimeTraffic.map(item=>2)
                    }
                }
                break;
            case 'inOutTimes':
                const {inOutTimes} = this.state
                if(inOutTimes){
                    data = {
                        xAxisData: inOutTimes.map(item=>moment(item.statisticsTime).format('LT')),
                        yAxisData1: inOutTimes.map(item=>item.inTimes),
                        yAxisData2: inOutTimes.map(item=>item.outTimes)
                    }
                }
                break;
            case 'outRoadParkingDetail':
                const {detailInOutTimes} = this.state;
                if(detailInOutTimes){
                    data = {
                        xAxisData: detailInOutTimes.map(item=>moment(item.statisticsTime).format('LT')),
                        yAxisData1: detailInOutTimes.map(item=>item.inTimes),
                        yAxisData2: detailInOutTimes.map(item=>item.outTimes)
                    }
                }
                break;
            default:
                const {trafficFlow} = this.state
                if(trafficFlow){
                    data = {
                        xAxisData: trafficFlow.map(item=>moment(item.statisticsTime).format('LT')),
                        yAxisData1: trafficFlow.map(item=>item.inTimes),
                        yAxisData2: trafficFlow.map(item=>item.outTimes)
                    }
                }
                break;
        }
        return data
    };

    getOptions = () => {
        let color = ["#966BFF","#32C5FF"];
        const {xAxisData,yAxisData1,yAxisData2} = this.getChartData()
        return {
            color: color,
            legend: {
                bottom: 0,
                icon: 'rect',
                itemWidth:15,
                itemHeight:2,
                textStyle:{
                    color:'#F5F5F5',
                    fontSize:10
                },
            },
            tooltip: {
                trigger: "axis" as "axis",
            },
            grid: {
                top:10,
                left:0,
                bottom:30,
                containLabel: true
            },
            xAxis: [{
                type: "category" as "category",
                boundaryGap: false,
                axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                        color: "#35A1F0"
                    },
                    rotate:-30
                },
                axisLine: {
                    lineStyle: {
                        color: "#35A1F0"
                    }
                },
                data: xAxisData,
            }],
            yAxis: [{
                type: "value" as "value",
                // name: '单位（万/亿KWh）',
                // axisLabel: {
                    textStyle: {
                        color: "#35A1F0"
                    },
                // },
                nameTextStyle: {
                    color: "#35A1F0",
                    fontSize: 12,
                    lineHeight: 40
                },
                // 分割线
                splitLine: {
                    lineStyle: {
                        // type: "dashed" as "dashed",
                        color: "#35A1F0",
                        opacity:0.23
                    }
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: "#35A1F0"
                },
            }],
            series: [{
                // name: "2018",
                name: "入场次数",
                type: "line" as "line",
                smooth: true,
                symbolSize: 8,
                zlevel: 3,
                lineStyle: {

                },
                symbol: 'circle',//数据交叉点样式
                data: yAxisData1
            }, {
                name: "离场次数",
                type: "line" as "line",
                smooth: true,
                symbolSize: 8,
                zlevel: 3,
                lineStyle: {

                },
                symbol: 'circle',//数据交叉点样式 (实心点)
                data: yAxisData2
            }]
        };
    }

    render() {
        return <ReactEcharts option={this.getOptions()} style={{width:'100%',height:'100%'}}/>
    }
}

export default TrafficFlow
