import ReactEcharts from 'echarts-for-react'
import React, { PureComponent } from 'react'
import CityModel from '../../../model/cityModel'
import { HttpClientImmidIot } from '../../../model/request/HttpClientImmidIot'
import { AreaTopRecord } from '../../../model/outRoadModelInterface'
import { area_top_record } from '../../../model/outRoadModelInitialData'
import OutRoadModelRequest from '../../../model/outRoadModelRequest'
import {Carousel} from "antd"
import './CommonTable.css'
import moment from "moment"
interface State {
    areaTopRecord ? : Array < AreaTopRecord >
}
export class CommonTable extends PureComponent < any, State > {
    timer: NodeJS.Timeout | number
    constructor(props: any) {
        super(props)
        this.state = {
            areaTopRecord: []
        }
    }

    componentDidMount() {
        this.getTableData()
        this.timer = setInterval(() => {
            this.getTableData();
        }, 60 * 1000)

    }
    componentWillUnmount() {
        clearInterval(Number(this.timer))
    }
   //右1   实时车辆通行信息
    getTableData = () => {
        OutRoadModelRequest.areaTopRecord(null, (d, type) => {
            if (type === HttpClientImmidIot.requestSuccess) {
                //成功-------在这里做你的数据处理，需要提示的自己加
                if (d.success) {
                    this.setState({ areaTopRecord: d.data })
                }
            } else {
                //失败----做除了报错之外的操作
                console.error('获取实时车辆通行信息失败')
            }
        })
    }



    render() {
      const {areaTopRecord} = this.state
      //只显示9条
      // if(areaTopRecord.length==10){
      //   areaTopRecord.splice(-1)
      // }
      const recordTypeList={1:'入',2:'出'}
        let tempArea = [];
        for (let i = 0; i < 2; i++) {
            tempArea.push(
                <div className={"common_table_body_box"} key={i}>
                {
                        areaTopRecord.map((item,index)=>{
                        return (
                            <div key={index} className={index % 2 === 0  ? "common_table_body_item_box box_color_light" : "common_table_body_item_box box_color_dark"}>
                                <div className={"common_table_body_item common_table_body_item_column1"} >{item.parkName}</div>
                                <div className={"common_table_body_item common_table_body_item_column2"} >{item.plateNumber}</div>
                                <div className={"common_table_body_item common_table_body_item_column3"} >{recordTypeList[item.recordType]}</div>
                                <div className={"common_table_body_item common_table_body_item_column4"} >{item.inOutTime?moment(item.inOutTime).format('HH:mm:ss'):'--'}</div>
                            </div>
                        )
                    })
                }
            </div>
            )
        }

        return (
          <div>
            <div className={"common_table_header_box"}>
                    <div className={"common_table_header_title common_table_body_item_column1"} >停车场</div>
                    <div className={"common_table_header_title common_table_body_item_column2"} >车牌号</div>
                    <div className={"common_table_header_title common_table_body_item_column3"} >出入</div>
                    <div className={"common_table_header_title "} >时间</div>
                </div> 
                 <Carousel
                    speed={400}
                    autoplaySpeed={5000}
                    dotPosition={"left"}
                    autoplay={true}
                    dots={false}>
                    {tempArea}
                </Carousel>
                </div>
        )
    }
}

export default CommonTable